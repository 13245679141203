import SvgIcon, {SvgIconProps} from "@material-ui/core/SvgIcon";
import React from "react";

export default (props: SvgIconProps) => (
    <SvgIcon {...props} viewBox="0 0 201 200">
        <path fill="#74b74d" d="M184.3 129.2l-32-7-47.7-79.3 26.2-23.4 49.3 94.3 4.2 15.4z"/>
        <path fill="#ececec" d="M121.8 38.4s31.7 36 46.5 70.4c3 36.5-6.6 77-6.6 77-11.7 5.7-46.3 13-77 10.2C40.6 176.5 47 161.6 47 161.6l-14.7-41.8 75-83.3z"/>
        <path fill="#d9d9d9" d="M85.5 196.1h-.7c-45-14.7-54.4-26.1-54.4-26.1s-8.7-26.2-7.3-52.6c.2-3.5 54.5-41.3 54.5-41.3-11.5 23.4-6.4 76.4 8 120z"/>
        <path fill="#c6c6c5" d="M123.2 33.6s30.3 40.8 45.1 75.2a163.6 163.6 0 01.4 16.9c-1-1.4-33.1-56.4-42.7-71.3-19.5 23.4-53.8 77.9-53.8 77.9l-38.7 1.5-1.3-14.1 75-83.3z"/>
        <path fill="#b1b1b1" d="M23.6 121c.4-3.4 49.1-45.7 49.1-45.7-4.5 14.2-3.8 34-.5 57l-48.6 4a67.8 67.8 0 010-15.3z"/>
        <path fill="#74b74d" d="M128.2 4S67.5 34.3 44.6 56.1A323.9 323.9 0 003.4 123l5.8 2.2 40.8-7z"/>
        <path fill="#547c39" d="M198 114.6s-8.8 9.9-13.7 14.6C167.7 84.8 129.5 32 129.5 32s-52 70.2-64.8 93.8A401.5 401.5 0 0148 112.1C113.5 9.4 117 12.5 128.2 4 156.5 30.2 198 114.6 198 114.6z"/>
        <path fill="#547c39" d="M64.5 125.8l-53.7 7-7.4-9.3L47.8 112l16.8 13.8z"/>
    </SvgIcon>
);

import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import ChevronDownIcon from "component/icon/ChevronDown";
import React from "react";
import {useField} from "react-final-form";
import FilterField from "types/FilterField";
import {Trans} from "react-i18next";
import FormHelperText from "@material-ui/core/FormHelperText";

export type SelectProps = {
    name: string;
    placeholder?: string;
    options: FilterField[];
    readOnly?: boolean;
}

export default ({readOnly, name, placeholder, options}: SelectProps) => {
    const {input, meta} = useField<string>(name);
    const helperText = meta.touched ? (<Trans>{meta.error}</Trans> || meta.submitError) : "";

    return (
        <FormControl fullWidth error={meta.touched && meta.invalid} margin="dense" size="small" variant="outlined">
            <Select
                displayEmpty {...input} IconComponent={ChevronDownIcon}
                disabled={readOnly}
            >
                {placeholder && (
                    <MenuItem value="" disabled>{placeholder}</MenuItem>
                )}

                {options.map(option => (
                    <MenuItem value={option.id} key={option.id}>
                        {option.name}
                    </MenuItem>
                ))}
            </Select>

            {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
    );
}

import TextField, {TextFieldProps} from "@material-ui/core/TextField";
import React from "react";
import {useField, UseFieldConfig} from "react-final-form";
import {Trans} from "react-i18next";

type Props = Partial<TextFieldProps> & {
    name: string;
    fieldProps?: UseFieldConfig<string>;
    readOnly?: boolean;
}

export default ({name, fieldProps, readOnly, helperText, ...props}: Props) => {
    const {input, meta} = useField<string>(name, fieldProps);

    return (
        <TextField
            fullWidth
            margin="dense"
            size="small"
            variant="outlined"
            disabled={readOnly}

            error={meta.touched && meta.invalid}
            helperText={meta.touched ? (<Trans>{meta.error}</Trans> || meta.submitError) : helperText}

            {...props}
            {...input}
        />
    );
}

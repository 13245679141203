import AdvertProperties from "component/form/myAdvert/AdvertProperties";
import Description from "component/form/myAdvert/Description";
import Photos from "component/form/myAdvert/Photos";
import Wizard from "component/form/Wizard";
import Loader from "component/Loader";
import SmallDesktopContainer from "component/SmallDesktopContainer";
import {FORM_ERROR} from "final-form";
import {Endpoint} from "lib/api";
import {useApi} from "lib/useApi";
import React, {FC, useMemo, useState} from "react";
import {useHistory} from "react-router-dom";
import MyAdvert from "types/MyAdvert";
import {Alert} from "@material-ui/lab";
import { Trans } from "react-i18next";

const steps: [FC, string][] = [
    [AdvertProperties, "Foto's maken"],
    [Photos, "Omschrijving"],
    [Description, "Preview"]
];

// I've spent several hours trying to get fromArchive down to this element. I couldn't. I did this instead.
// If you can do it better, please do. - NC
declare global {
    interface Window {
        fromArchive: boolean;
    }
}

export default () => {
    const [step, setStep] = useState<number>(0);
    const {push} = useHistory();

    const [{data}, updateMyAdvert] = useApi<{data: MyAdvert;} | null>(Endpoint.myAdvert, {
        autoTrigger: true,
        initialData: null
    });

    const onPrevious = useMemo(() => {
        if (step && steps[step - 1]) {
            return () => setStep(step - 1);
        }
    }, [step]);

    if (!data || !steps[step]) {
        return <Loader />;
    }

    const onSubmit = async (values: MyAdvert) => {
        try {
            await updateMyAdvert({
                method: "POST",
                data: values
            });

            if (steps[step + 1]) {
                return setStep(step + 1);
            }

            push(`/my-advert-preview/${values.id}`);
        } catch (e) {
            if (e.ERROR && e.ERROR.message) {
                return {
                    [FORM_ERROR]: e.ERROR.message
                };
            }
        }
    };

    const [Step, submit] = steps[step];
    // I've spent several hours trying to get fromArchive down to this element. I couldn't. I did this instead.
    // If you can do it better, please do. - NC
    window.fromArchive = data.data.fromArchive;

    return (
        <SmallDesktopContainer>
            {
                data.data.published &&
                <Alert severity="success">
                  <Trans>
                    Uw advertentie is op dit moment gepubliceerd. U kunt hier de gegevens van de advertentie aanpassen. Deze worden direct zichtbaar.
                  </Trans>
                </Alert>
            }
            <Wizard
                initialValues={data.data}
                fromArchive={data.data.fromArchive}
                onSubmit={onSubmit}
                children={<Step key={step}/>}

                length={steps.length}
                current={step}

                submit={submit}

                onPrevious={onPrevious}
            />
        </SmallDesktopContainer>
    );
};
import Box from "@material-ui/core/Box";
import Fab from "@material-ui/core/Fab";
import Typography from "@material-ui/core/Typography";
import FacebookIcon from "component/icon/Facebook";
import GoogleIcon from "component/icon/Google";
import WoonmatchIcon from "component/icon/Woonmatch";
import {externalLogin, login, loginCallback} from "lib/auth";
import React, {useState} from "react";
import {Trans} from "react-i18next";
import {useHistory, useLocation} from "react-router-dom";
import {AuthProvider} from "types/AuthProvider";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import LoginForm from "./LoginForm";
import Login from "../types/Login";
import {Alert} from "@material-ui/lab";
import {setAuthState} from "../redux/action";
import {useDispatch} from "react-redux";


type Props = {
    title: string;
    description?: string;
}

export default ({title, description}: Props) => {
    const [showError, setShowError] = useState(false);
    const {replace} = useHistory();
    const dispatch = useDispatch();
    const [woonmatch, setWoonmatch] = React.useState<Element>();
    const openWoonmatch = (e: React.MouseEvent) => setWoonmatch(e.currentTarget);
    const closeWoonmatch = () => setWoonmatch(undefined);

    const {pathname} = useLocation();

    const backUrl = pathname.match(/login|register/) ? "/" : pathname;

    const startExternalLogin = (url?: string) => () => {
        closeWoonmatch();

        if (!url) {
            console.error('you need to set-up env vars');
            console.error('REACT_APP_WOONMATCHWESTFRIESLAND_AUTH_URL');
            console.error('REACT_APP_WOONMATCHKOPNH_AUTH_URL');
            console.error('REACT_APP_WOONMATCHWATERLAND_AUTH_URL');
        }
        console.log(url)
        url && externalLogin(url, backUrl);
    }

    const doLogin = async (values: Login) => {
        try {
            const redirectUrl = await loginCallback(values.email, values.password);
            dispatch(setAuthState(true));
            replace(redirectUrl);
        } catch (e) {
            setShowError(true);
        }
    };

    return (
        <Box textAlign="center">
            <Box pt={2} pb={4}>
                <Typography variant="h1" align="center" paragraph>
                    <Trans>{title}</Trans>
                </Typography>

                {description && <Typography><Trans>{description}</Trans></Typography>}
            </Box>
            <Box bgcolor="#F5F5F5" margin="0px" padding="20px" paddingBottom="10px" marginBottom="10px" textAlign="left">
                {showError && (
                    <Alert severity="error">
                        Er is geen account gevonden met deze gegevens.
                    </Alert>
                )}
                <LoginForm onSubmit={doLogin} showRegister={true} title="Inloggen"/>
            </Box>
            <hr/>
            <Box paddingTop="20px" paddingBottom="10px" marginBottom="20px">
                <Typography variant="h1" align="center" paragraph>
                    <Trans>Inloggen met een ander account</Trans>
                </Typography>
                <Fab
                    color="primary"
                    variant="extended"
                    size="medium"
                    onClick={() => login(AuthProvider.google, backUrl)}
                >
                    <GoogleIcon/>
                    &nbsp;&nbsp;&nbsp;
                    <Trans>Google</Trans>
                </Fab>

                <Box pb={2} pl={2} display={{sm: "inline"}}/>

                <Fab
                    color="primary"
                    variant="extended"
                    size="medium"
                    onClick={() => login(AuthProvider.facebook, backUrl)}
                >
                    <FacebookIcon/>
                    &nbsp;&nbsp;&nbsp;
                    <Trans>Facebook</Trans>
                </Fab>

                <Box pb={2} pl={2} display={{sm: "inline"}}/>

                <Fab color="primary" variant="extended" size="medium" onClick={openWoonmatch}>
                    <WoonmatchIcon/>
                    &nbsp;&nbsp;&nbsp;
                    <Trans>Woonmatch</Trans>
                </Fab>

                <Menu
                    anchorEl={woonmatch}
                    keepMounted
                    open={Boolean(woonmatch)}
                    onClose={closeWoonmatch}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <MenuItem onClick={startExternalLogin(process.env.REACT_APP_WOONMATCHWESTFRIESLAND_AUTH_URL)}>
                        <Trans>West-Friesland</Trans>
                    </MenuItem>
                    <MenuItem onClick={startExternalLogin(process.env.REACT_APP_WOONMATCHKOPNH_AUTH_URL)}>
                        <Trans>Kop van Noord-Holland</Trans>
                    </MenuItem>
                    <MenuItem onClick={startExternalLogin(process.env.REACT_APP_WOONMATCHWATERLAND_AUTH_URL)}>
                        <Trans>Waterland</Trans>
                    </MenuItem>
                </Menu>
            </Box>
        </Box>
    );
};

import Chip from "@material-ui/core/Chip";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField, {TextFieldProps} from "@material-ui/core/TextField";
import Autocomplete, {AutocompleteGetTagProps} from "@material-ui/lab/Autocomplete";
import CloseIcon from "component/icon/Close";
import SearchIcon from "component/icon/Search";
import {Endpoint} from "lib/api";
import {useApi} from "lib/useApi";
import React, {ChangeEvent} from "react";
import {useField} from "react-final-form";
import {Trans} from "react-i18next";
import FilterField from "types/FilterField";

type Props = {
  name: string;
}

const renderInput = ( params: TextFieldProps ) => (
    <>
      <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
            ),
          }}

          fullWidth
          margin="dense"
          variant="outlined"
          placeholder="Tik hier om locaties toe te voegen"
      />
      {params.InputProps?.startAdornment}
    </>
);

const renderTags = ( tags: FilterField[], getTagProps: AutocompleteGetTagProps ) => {
  return tags.map( ( tag, index ) => (
      <Chip
          key={tag.id}
          label={tag.name}
          deleteIcon={<CloseIcon />}
          variant="outlined"
          color="primary"

          {...getTagProps( {index} )}
      />
  ) );
};

const getOptionLabel = ( option: FilterField ) => option.name;

const getOptionSelected = ( option: FilterField, value: FilterField ) => option.id === value.id;

export default ( {name}: Props ) => {
  const {input: {multiple, ...input}} = useField<FilterField[]>( name );

  const [{loading, data}, load] = useApi<{ results: FilterField[] }>( Endpoint.location, {
    initialData: {results: []},
  } );

  const onInputChange = ( event: ChangeEvent<{}>, data: string, reason: string ) => {
    if ( reason === "input" && data ) {
      load( {
        query: new URLSearchParams( {data} ),
      } );
    }
  };

  const onChange = ( event: ChangeEvent<{}>, item: (string | FilterField)[] ) => {
    if ( typeof item !== "string" ) {
      input.onChange( item );
    }
  };

  if ( !input.value ) {
    input.value = [];
  }

  return (
      <Autocomplete
          autoComplete
          freeSolo

          onInputChange={onInputChange}

          getOptionSelected={getOptionSelected}
          getOptionLabel={getOptionLabel}

          options={data.results}

          loading={loading}
          loadingText={<Trans>Loading…</Trans>}

          renderTags={renderTags}
          renderInput={renderInput}

          disableClearable

          size="small"

          {...input}
          multiple
          onChange={onChange}
      />
  );
}
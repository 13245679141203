import Box from "@material-ui/core/Box";
import Fab from "@material-ui/core/Fab";
import Typography from "@material-ui/core/Typography";
import React from "react";
import {Trans} from "react-i18next";
import {useSelector} from "react-redux";
import {Link as RouterLink} from "react-router-dom";
import {getLoginState} from "redux/selector";
import Link from "@material-ui/core/Link";

const p = {
    xs: 2,
    md: 3
};

export default () => {
    const loggedIn = useSelector(getLoginState);

    if (loggedIn) {
        return null;
    }
    return (
        <Box textAlign="center" mt={{md: 5}} mb={2} pt={p} pb={p} bgcolor={{md: "#0000000a"}}>
            <Typography paragraph variant="h1" component="h1">
                <Trans>Welkom op ruilmijnwoning.nl!</Trans>
            </Typography>

            <Typography paragraph variant="body1">
                <Trans>Vind je nieuwe huurwoning door te ruilen met je eigen woning.</Trans>
            </Typography>

            <Fab color="primary" variant="extended" size="medium" component={RouterLink} to="/login">
                <Trans>Inloggen</Trans>
            </Fab>
            <br/>
            <br/>
            Nog geen account? <Link component={RouterLink} to="/register">Registreer je hier.</Link>
        </Box>
    );
};
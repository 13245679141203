import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Chip from '@material-ui/core/Chip';
import Box from '@material-ui/core/Box';
import Person from '../component/icon/Person'
import House from '../component/icon/House'
import Document from '../component/icon/Document'
import Question from "types/Question";



const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        marginBottom: 10
    },
    accordion: {
        border: '1px solid #dedede',
        boxShadow: 'none',
        borderRadius: '25PX !important'
    },

    heading: {
        color: '#389afd',
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightBold,
    },
    accordionDetails: {
        display: 'block',
    },
    answer: {
        color: '#797779'
    },
    icon: {
        fontSize: '15px',
        marginRight: '20px',
        overflow: 'inherit'
    },
    chipContainer: {
        marginBottom: '20px',
        '& > *': {
            marginRight: theme.spacing(0.5),
        },
    },
    chip: {
        color: '#f5f5f5',
        backgroundColor: '#616161'
    },
    moreIcon: { color: '#389afd', fontSize: '30px' }
}));

export default ({ question, answer, icon, categories }: Question) => {
    const classes = useStyles();

    const innerHtml = {
        __html: answer
    }

    return (
        <>
            <div className={classes.root}>
                <Accordion className={classes.accordion}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon className={classes.moreIcon} />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        {icon && icon === 'person' && <Person color='primary' fontSize='default' className={classes.icon} />}
                        {icon && icon === 'house' && <House color='primary' fontSize='default' className={classes.icon} />}
                        {icon && icon === 'document' && <Document color='primary' fontSize='default' className={classes.icon} />}
                        <Typography className={classes.heading}>{question}</Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.accordionDetails}>
                        {categories && categories.length > 0 &&
                            <Box flexDirection="row" className={classes.chipContainer}>
                                {categories?.map((category, index) => <Chip key={`buble${index}`} size="small" label={category} className={classes.chip} />)}
                            </Box>
                        }
                        <Typography className={classes.answer} dangerouslySetInnerHTML={innerHtml}>

                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </div>
        </>
    )
}
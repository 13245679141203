import {Trans} from "react-i18next";
import {Alert} from "@material-ui/lab";
import React from "react";
import {Link as RouterLink} from "react-router-dom";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";


type Props = {
    isFitting: 1 | 0 | null
}

export default ({isFitting}: Props) => {
    return (
        <Box mb={1}>
            <Alert severity="error">
                <Trans>
                    <strong>Let op:</strong> Je inkomen, in combinatie met de grootte van jouw huishouden, voldoet voor deze woning met deze huur, niet aan de wettelijke regels van Passend Toewijzen. Om die reden is er een kans dat je afgewezen wordt..<br/>
                    <br/>
                    Wij adviseren je om een woning te zoeken die wel passend is. Je kunt met deze woning wel een woningruil aanvragen, maar houdt er rekening mee dat de kans op afwijzing groot is.<br/>
                    <br/>
                    Meer informatie over de regels m.b.t. Passend Toewijzen vind je <Link component={RouterLink} to="/fitting-rules">hier.</Link>
                </Trans>
            </Alert>
        </Box>

    );
};

import {AUTH_REFRESH_INTERVAL} from "data/config";
import {Endpoint, makeRequest} from "lib/api";
import {AuthProvider} from "types/AuthProvider";
import ProfileState from "types/ProfileState";
import TMap from "types/TMap";

const AUTH_TOKEN_KEY = "AUTH_TOKEN";
const REFRESH_TOKEN_KEY = "REFRESH_TOKEN";
const REFRESH_TIME_KEY = "REFRESH_TIME";
const STARTING_URL_KEY = "STARTING_URL";

const setRefreshTime = (time: number) => {
    localStorage.setItem(REFRESH_TIME_KEY, time.toString());
};

const getRefreshToken = (): string | null => {
    return localStorage.getItem(REFRESH_TOKEN_KEY);
};

const setAuthToken = (token: string) => {
    return localStorage.setItem(AUTH_TOKEN_KEY, token);
};

const setRefreshToken = (token: string) => {
    return localStorage.setItem(REFRESH_TOKEN_KEY, token);
};

const setStartingUrl = (url: string) => {
    return localStorage.setItem(STARTING_URL_KEY, url);
};

export const getStartingUrl = (): string | null => {
    return localStorage.getItem(STARTING_URL_KEY);
};

export const isLoggedIn = async (): Promise<boolean> => {
    if (!getRefreshToken()) {
        return false;
    }

    if (!getAuthToken() || getRefreshTime() < Date.now()) {
        return refreshAuthTokens();
    }

    return true;
};

export const getAuthToken = (): string | null => {
    return localStorage.getItem(AUTH_TOKEN_KEY);
};

export const getRefreshTime = (): number => {
    return parseInt(localStorage.getItem(REFRESH_TIME_KEY) || "0");
};

const clearStorage = () => {
    localStorage.removeItem(AUTH_TOKEN_KEY);
    localStorage.removeItem(REFRESH_TOKEN_KEY);
    localStorage.removeItem(REFRESH_TIME_KEY);
};

export const logout = async () => {
    clearStorage();

    window.location.assign("/");
};

export const login = async (provider: AuthProvider, startingUrl: string = "") => {
    const url = await getSocialAuthUrl(provider);

    setStartingUrl(startingUrl);

    window.location.assign(url);
};

export const deleteAccount = async (reason: string = '') => {
    await makeRequest<string>(Endpoint.profile, {
        method: "delete",
        data: {reason}
    });

    await logout();
}

export const changePassword = async(password: string) => {
    const response = await makeRequest<string>(Endpoint.changepw, {
        method: "post",
        useAuth: true,
        data: {password}
    });
    console.log(response)
}

export const forgotPassword = async(email: string) => {
    await makeRequest<string>(Endpoint.forgotpw, {
        method: "post",
        data: {email}
    });
}

export const externalLogin = async (url: string, startingUrl: string = "") => {
    setStartingUrl(startingUrl);

    window.location.assign(url);
}

export const getSocialAuthUrl = async (provider: AuthProvider): Promise<string> => {
    const {url} = await makeRequest<{ url: string }>(Endpoint.oAuthUrl, {
        params: {provider},
        useAuth: false,
    });

    return url;
};

const refreshAuthTokens = async (): Promise<boolean> => {
    try {
        const {token} = await makeRequest<{ token: string }>(Endpoint.authRefresh, {
            useAuth: false,
        });

        if (token) {
            setAuthToken(token);
            setRefreshTime(Date.now() + AUTH_REFRESH_INTERVAL);

            return true;
        }
    } catch (e) {
        await clearStorage();
    }

    return false;
};

export const oAuthCallback = async (provider: AuthProvider, args: TMap<string>): Promise<string> => {
    type Response = { token: string; STATE: ProfileState; state?: ProfileState };

    clearStorage();

    const response = provider === 'external'
        ? (
            await makeRequest<Response>(Endpoint.oAuthCallbackExternal, {
                data: {token: args.token},
                useAuth: false,
            })
        )
        : (
            await makeRequest<Response>(Endpoint.oAuthCallback, {
                params: {provider},
                query: new URLSearchParams(args),
                useAuth: false,
            })
        );

    setRefreshToken(response.token);

    await refreshAuthTokens();

    const profileState = response.state || response.STATE;

    if (profileState.state < 2) {
        return `/onboarding/${profileState.state}`;
    }

    if (profileState.state === 2) {
        return "/preferences";
    }

    return getStartingUrl() || "/"
};

export const loginCallback = async (email: string, password: string): Promise<string> => {
    type Response = { token: string; STATE: ProfileState; state?: ProfileState };

    clearStorage();

    const response = await makeRequest<Response>(Endpoint.login, {
        data: {email, password},
        useAuth: false,
    })

    setRefreshToken(response.token);

    await refreshAuthTokens();

    const profileState = response.state || response.STATE;

    if (profileState.state < 2) {
        return `/onboarding/${profileState.state}`;
    }

    if (profileState.state === 2) {
        return "/preferences";
    }

    return getStartingUrl() || "/"
};

export const registerCallback = async (email: string, password: string): Promise<string> => {
    type Response = { token: string; STATE: ProfileState; state?: ProfileState };

    clearStorage();

    const response = await makeRequest<Response>(Endpoint.register, {
        data: {email, password},
        useAuth: false,
    })

    setRefreshToken(response.token);

    await refreshAuthTokens();

    const profileState = response.state || response.STATE;

    if (profileState.state < 2) {
        return `/onboarding/${profileState.state}`;
    }

    if (profileState.state === 2) {
        return "/preferences";
    }

    return getStartingUrl() || "/"
};
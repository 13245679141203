import FiltersForm from "component/form/Filters";
import Loader from "component/Loader";
import SmallDesktopContainer from "component/SmallDesktopContainer";
import {FORM_ERROR} from "final-form";
import {Endpoint} from "lib/api";
import {useApi} from "lib/useApi";
import React from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {setProfile} from "redux/action";
import {getProfile} from "redux/selector";
import FilterForm from "types/FilterForm";
import Profile from "types/Profile";

export default () => {
  const [translate] = useTranslation();
  const dispatch = useDispatch();
  const {push} = useHistory();
  const profile = useSelector( getProfile );

  const [, load] = useApi<Profile | null>( Endpoint.profile, {
    initialData: null,
  } );

  if ( !profile ) {
    return <Loader />;
  }

  const onSubmit = async ( values: FilterForm ) => {
    try {
      const response = await load( {
        method: "POST",
        data: {
          searchprofile: values,
        },
      } );

      if ( response ) {
        dispatch( setProfile( response ) );
      }

      push( "/" );
    } catch ( e ) {
      return {
        [ FORM_ERROR ]: translate( e.message ),
      };
    }
  };

  return (
      <SmallDesktopContainer>
          <br/>
{/*
        <Typography variant="h2">
          <Trans>Uw zoekwensen</Trans>
        </Typography>
        <p>
            Geef hieronder aan waar je naar op zoek bent. Vul in ieder geval plaats, minimale en maximale huur, type woning en minimaal aantal kamers in.
        </p>
        <p>
            Klik, al je klaar bent, onderaan op "Sla voorkeuren op en toon woningen" en je ziet direct de beschikbare woningen automatisch gesorteerd op jouw voorkeuren.
        </p>
*/}

        <FiltersForm
            showRequired
            hideSorting
            initialValues={profile.searchprofile}
            onSubmit={onSubmit}
            title="Mijn voorkeuren"
            buttonTitle="Sla voorkeuren op en toon woningen"
        />
      </SmallDesktopContainer>
  );
};

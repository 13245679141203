import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";

interface IProps {
    className?: string;
    labels: string[]
}

const useStyles = makeStyles(({shape, spacing}: Theme) => createStyles({
    root: {
        borderRadius: 2,
        backgroundColor: "#f3b91a",
        padding: spacing(.5),
        marginBottom: spacing(),
        display: "inline-block",
    },
}));

export default ({className, labels}: IProps) => (
    <Typography className={`${className} ${useStyles().root}`} variant="caption" color="textSecondary" component="b">
        {labels.join(", ")}
    </Typography>
);
import createSagaMiddleware, { Saga, SagaMiddlewareOptions } from "@redux-saga/core";
import { History } from "history";
import { applyMiddleware, compose, createStore, Reducer, Store } from "redux";

interface IProps {
    saga: Saga;
    reducer: Reducer;
    history: History;
    initial: object;
}

export default ({saga, reducer, history, initial}: IProps): Store => {
    const sagaOptions: SagaMiddlewareOptions = {
        context: {
            history,
        },
        // onError: (error) => {
        //     console.log(error);
        //     throw error;
        // },
    };

    // Configure saga middleware
    const sagaMiddleware = createSagaMiddleware(sagaOptions);

    const middleware = [sagaMiddleware];

    const enhancers = [applyMiddleware(...middleware)];

    // Dev tools are helpful
    let composeEnhancers = compose;
    if (process.env.NODE_ENV !== "production" && typeof window !== "undefined") {
        const {__REDUX_DEVTOOLS_EXTENSION_COMPOSE__} = window as any;
        if (__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
            composeEnhancers = __REDUX_DEVTOOLS_EXTENSION_COMPOSE__({});
        }
    }

    const store = createStore(
        reducer,
        initial,
        composeEnhancers(...enhancers),
    );

    sagaMiddleware.run(saga);

    return store;
}

import {createMuiTheme, Theme} from "@material-ui/core/styles";
import CheckIcon from "component/icon/Check";
import React from "react";

export default (): Theme => {
  const fontWeightMedium = 700;
  const fontWeightBold = 800;

  const theme = createMuiTheme( {
    shape: {
      borderRadius: 6,
    },
    palette: {
      divider: "#d8d8d8",
      background: {
        paper: "#fff",
        default: "#fff",
      },
      primary: {
        light: "#e8f3fe",
        main: "#198efd",
      },
      secondary: {
        main: "#d42323",
      },
      text: {
        primary: "#666",
        secondary: "#000",
        // disabled: "rgba(0, 0, 0, 0.38)",
        hint: "#d8d8d8",
      },
    },
    typography: {
      fontFamily: "'Overpass', sans-serif",

      fontWeightMedium,
      fontWeightBold,

      h1: {
        fontSize: 24,
        lineHeight: 1.333,
        fontWeight: fontWeightBold,
        color: "#000",
      },
      h2: {
        fontSize: 20,
        lineHeight: 1.333,
        fontWeight: fontWeightBold,
        color: "#000",
      },
      h3: {
        fontSize: 16,
        lineHeight: 1.333,
        fontWeight: fontWeightBold,
        color: "#000",
      },
      h4: {
        fontSize: 14,
        lineHeight: 1.71,
        fontWeight: fontWeightBold,
        color: "#000",
      },

      body1: {
        lineHeight: 1.5,
      },

      body2: {
        lineHeight: 1.7,
      },

      button: {
        textTransform: "none",
      },

      subtitle1: {
        fontWeight: fontWeightBold,
      },

      caption: {
        lineHeight: 1.4,
      },
    },
    props: {
      MuiAppBar: {
        position: "static",
        elevation: 0,
        color: "inherit",
      },
      MuiButtonBase: {
        disableRipple: true,
      },
      MuiButton: {
        disableElevation: true,
      },
      MuiCard: {
        elevation: 0,
      },
      MuiCheckbox: {
        checkedIcon: <CheckIcon />,
        icon: <span />,
      },
    },
  } );

  const {typography, shadows, spacing, breakpoints, palette, shape} = theme;

  return {
    ...theme,
    overrides: {
      MuiAppBar: {
        root: {
          [ breakpoints.up( "md" ) ]: {
            borderBottom: `1px solid ${palette.divider}`,
          },
        },
      },
      MuiCssBaseline: {
        "@global": {
          "html, body, #root": {
            minHeight: "100vh",
          },
          "#root": {
            // overflow: "hidden",
            display: "flex",
            flexDirection: "column",
          },
          "#content": {
            flex: 1,
          },
          "strong, b": {
            fontWeight: `${fontWeightMedium} !important`,
          },
        },
      },
      MuiFab: {
        root: {
          backgroundColor: "#fff",
          border: `2px solid ${palette.divider}`,
          boxShadow: shadows[ 0 ],
          "&:active": {
            boxShadow: shadows[ 0 ],
          },
          "&:disabled": {
            color: palette.common.white,
            background: "#b0b0b0",
          },
        },
        primary: {
          border: "none",
        },
        extended: {
          width: "100%",

          "&$sizeSmall": {
            width: "100%",
            [ breakpoints.up( "sm" ) ]: {
              width: "auto",
              padding: spacing( 0, 5 ),
            },
          },
          "&$sizeMedium": {
            width: "100%",
            fontSize: typography.pxToRem( 16 ),
            [ breakpoints.up( "sm" ) ]: {
              width: "auto",
              padding: spacing( 0, 5 ),
            },
          },
        },
      },
      MuiChip: {
        root: {
          fontSize: typography.pxToRem( typography.fontSize ),
        },
        outlined: {
          color: palette.text.primary,
          borderColor: palette.divider,
          "& $icon": {
            marginLeft: 10,
          },
          "& $deleteIcon": {
            marginRight: 10,
            width: 8,
            height: 8,
          },
        },
        outlinedPrimary: {
          color: palette.text.secondary,
          borderColor: "#198efd4d",
          background: "#198efd1a",
        },
        avatar: {
          fontWeight: fontWeightMedium,
        },
        clickable: {
          "&:active": {
            boxShadow: shadows[ 0 ],
          },
        },
        label: {
          "& svg": {
            marginLeft: -5,
            marginRight: -5,
            marginTop: 6,
          },
        },
      },
      MuiSvgIcon: {
        root: {
          fontSize: typography.pxToRem( 16 ),
        },
        fontSizeSmall: {
          fontSize: typography.pxToRem( 8 ),
        },
        fontSizeLarge: {
          fontSize: typography.pxToRem( 22 ),
        },
      },
      MuiOutlinedInput: {
        root: {
          borderColor: palette.text.hint,

          "& svg": {
            color: palette.text.hint,
          },
        },
      },
      MuiSlider: {
        root: {
          padding: "22px 0",
          "@media (pointer: coarse)": {
            padding: "32px 0",
          },
        },
        rail: {
          backgroundColor: palette.text.hint,
          opacity: 1,
          height: 1,
        },
        track: {
          height: 1,
        },
        thumb: {
          width: 20,
          height: 20,
          marginLeft: -10,
          marginTop: -10,
        },
      },
      MuiSwitch: {
        root: {
          padding: 3,
        },
        thumb: {
          width: 24,
          height: 24,
          boxShadow: shadows[ 0 ],
        },
        switchBase: {
          padding: 7,
          color: palette.divider,
        },
        colorPrimary: {
          "&$checked + $track": {
            opacity: 1,
            borderColor: "#198efd4d",
            backgroundColor: "#198efd1a",
          },
        },
        track: {
          borderRadius: 16,
          opacity: 1,
          border: `1px solid ${palette.divider}`,
          backgroundColor: "#fff",
        },
      },
      MuiInputLabel: {
        root: {
          marginTop: spacing( 1.5 ),
          fontWeight: fontWeightBold,
          fontSize: typography.fontSize,
        },
      },
      MuiSelect: {
        root: {},
        iconOutlined: {
          top: `calc(50% - ${spacing()}px)`,
          right: spacing( 2 ),
        },
      },
      MuiLinearProgress: {
        root: {
          height: spacing(),
        },
        colorPrimary: {
          backgroundColor: "#e8f3fe",
          borderRadius: shape.borderRadius,
        },
        barColorPrimary: {
          borderRadius: shape.borderRadius,
        },
      },
      MuiCheckbox: {
        root: {
          color: palette.divider,
          margin: 2,
          width: spacing( 2 ),
          border: `1px solid currentColor`,
          height: spacing( 2 ),
          borderRadius: 2,
          "& svg": {
            fontSize: spacing(),
          },
        },
        checked: {
          backgroundColor: "rgba(25, 142, 253, 0.1)",
        },
      },
      // @ts-ignore
      MuiAutocomplete: {
        endAdornment: {
          top: `calc(50% - 10px)`,
          right: `${spacing( 2 )}px !important`,
        },
      },
    },
  };
}

import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import FieldRequiredAsterisk from "component/FieldRequiredAsterisk";
import FilterExtras from "component/form/field/FilterExtras";
import Location from "component/form/field/Location";
import MinMax from "component/form/field/MinMax";
import Price from "component/form/field/Price";
import Radius from "component/form/field/Radius";
import Select from "component/form/field/Select";
import Submit from "component/form/field/Submit";
import Tags from "component/form/field/Tags";
import Loader from "component/Loader";
import React from "react";
import { Form, FormProps } from "react-final-form";
import { Trans } from "react-i18next";
import { useSelector } from "react-redux";
import { getAdvertsFilter, getFilterData } from "redux/selector";
import FilterForm from "types/FilterForm";

export type Props = FormProps<FilterForm> & {
  title?: string;
  showRequired?: boolean;
  hideSorting?: boolean;
  buttonTitle?: string;
};

export default ({
  title = "Zoeken",
  buttonTitle = "Toon zoekresultaten",
  onSubmit,
  showRequired,
  hideSorting,
  ...props
}: Props) => {
  const data = useSelector(getFilterData);
  let initialValues = useSelector(getAdvertsFilter);

  if (!data) {
    return <Loader />;
  }

  const { sort, rent, radius, types, extras, rooms } = data;

  if (!initialValues) {
    initialValues = {
      sort: "bestMatch",
      rooms: "",
      extras: [],
      locations: [],
      types: [],
      rent: rent,
      specials: {
        likedMeOnly: false,
        likedByMeOnly: false,
        favoritedOnly: false,
      },
    };
  }

  const handleSubmit: Props["onSubmit"] = (values: FilterForm, ...args) => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200);
    values.locations = values.locations.map((location) => ({
      ...location,
      radius: values.radius,
    }));

    return onSubmit(values, ...args);
  };

  return (
    <Form initialValues={initialValues} onSubmit={handleSubmit} {...props}>
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Box pt={2} pb={2} textAlign={{ xs: "center", md: "left" }}>
            <Typography variant="h1">
              <Trans>{title}</Trans>
            </Typography>
          </Box>

          <Box pt={2} pb={2}>
            <Typography variant="subtitle1" color="textSecondary">
              <Trans>Locatie</Trans>
              {showRequired && <FieldRequiredAsterisk />}
            </Typography>
            <Location
              name="locations"
              // placeholder={<Trans>Zoek op plaats, buurt, etc.</Trans>}
            />
            <Typography variant="subtitle1" color="textSecondary">
              <Trans>Straal</Trans>
            </Typography>
            <Radius name="radius" min={radius.min} max={radius.max} />

            {hideSorting !== true && (
              <>
                <Typography variant="subtitle1" color="textSecondary">
                  <Trans>Sorteren</Trans>
                </Typography>
                <Select name="sort" options={sort} />
              </>
            )}

            <Typography variant="subtitle1" color="textSecondary">
              <Trans>Huurprijs</Trans>
            </Typography>
            <Price name="rent" min={rent.min} max={rent.max} />

            <Typography variant="subtitle1" color="textSecondary">
              <Trans>Type woning</Trans>
              {showRequired && <FieldRequiredAsterisk />}
            </Typography>
            <Tags name="types" available={types} showIcons multiple />

            <Typography variant="subtitle1" color="textSecondary">
              <Trans>Minimaal aantal kamers</Trans>
              {showRequired && <FieldRequiredAsterisk />}
            </Typography>
            <Typography variant="subtitle2" color="textSecondary">
              Tel hier woonkamer + slaapkamer(s). Dus wil je minimaal 3
              slaapkamers dan kies je hieronder "4"
            </Typography>
            <MinMax name="rooms" min={rooms.min} max={rooms.max} />

            <Box pt={3} pb={3}>
              <Typography variant="h1">
                <Trans>Aanvullende woonwensen</Trans>
              </Typography>
            </Box>

            <FilterExtras extras={extras} name="extras" />
          </Box>
          <Divider />
          <Submit title={buttonTitle} />
        </form>
      )}
    </Form>
  );
};

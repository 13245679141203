import {Box} from "@material-ui/core";
import Fab from "@material-ui/core/Fab";
/*import InputLabel from "@material-ui/core/InputLabel";*/
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
/*import Input from "component/form/field/Input";*/
import Photos from "component/form/field/Photos";
import CheckIcon from "component/icon/Check";
import Modal from "component/Modal";
import Tag from "component/Tag";
import React, {useCallback, useState} from "react";
import {Field, useField} from "react-final-form";
import {Trans} from "react-i18next";
import MyAdvertPhotos from "types/MyAdvertPhotos";

type Props = {
    name: string;
    advert: string;
}

export default ({name, advert}: Props) => {
    const [initialValue, setInitialValue] = useState<MyAdvertPhotos | undefined>();
    const {input: {value, onChange}} = useField<MyAdvertPhotos>(name, {
        subscription: {
            value: true
        }
    });

    const renderButton = useCallback((onOpen: () => void) => {
        const onClick = () => {
            setInitialValue(value);
            onOpen();
        };

        return (
            <Tag
                showIcons
                isActive={!!value.photos.length}
                tag={{name: "Foto's toevoegen", id: ""}}
                onToggle={onClick}
                deleteIcon={<CheckIcon />}
            />
        );
    }, [value]);

    const renderContent = useCallback((onClose: () => void) => {
        const onCancel = () => {
            onChange(initialValue);
            onClose();
        };

        return (
            <>
                <Field type="hidden" component="input" name="id" />
                <Box textAlign="center" pt={2} pb={3}>
                    <Typography variant="body1" color="primary" component="b" gutterBottom>
                        <Trans>Foto’s maken</Trans>
                    </Typography>
                    <Typography variant="h1">
                        <Trans>{value.name}</Trans>
                    </Typography>
                </Box>

                <Photos name={`${name}.photos`} part={value.id} advert={advert} />

                {/*<InputLabel><Trans>Voeg een beschrijving toe (optioneel)</Trans></InputLabel>
                <Input name={`${name}.description`} multiline rows={4} />*/}

                <Box pt={4} display="flex" justifyContent="space-between" alignItems="center">
                    <Link onClick={onCancel}>
                        <b><Trans>Annuleren</Trans></b>
                    </Link>

                    <Fab color="primary" variant="extended" size="medium" style={{width: "auto"}} onClick={onClose}>
                        <Trans>Foto's opslaan</Trans>
                    </Fab>
                </Box>
            </>
        );
    }, [name, onChange, initialValue, value, advert]);

    return (
        <Box display="flex" alignItems="center" mt={1} mb={.5}>
            <Modal
                button={renderButton}
                children={renderContent}
            />
        </Box>
    );
}
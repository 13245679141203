import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import AdvertCard from "component/AdvertCard";
import FavoriteActiveIcon from "component/icon/FavoriteActive";
import LikesMenu from "component/LikesMenu";
import {Endpoint} from "lib/api";
import {useApi} from "lib/useApi";
import React, {useEffect, useMemo} from "react";
import {Trans} from "react-i18next";
import {useParams} from "react-router-dom";
import Advert from "types/Advert";
import {LikesType} from "types/LikesType";

const items = [
    {
        label: <Trans>Mijn likes</Trans>,
        type: LikesType.like
    },
    {
        label: <Trans>Ontvangen likes</Trans>,
        type: LikesType.liked
    },
    {
        label: <FavoriteActiveIcon />,
        type: LikesType.favorite
    }
];

export default () => {
    const {type} = useParams<{type: LikesType;}>();

    const [{loading, data}, load] = useApi<{adverts: Advert[]}>(Endpoint.like, {
        initialData: {
            adverts: []
        }
    });

    useEffect(() => {
        load({params: {type}});
    }, [type, load]);

    const list = useMemo(() => (
        <Grid container spacing={4}>
            {data?.adverts.map((advert, index) => (
                <Grid item xs={12} sm={6} md={4} key={`${advert.id}-${index}`}>
                    <AdvertCard advert={advert} />
                </Grid>
            ))}
        </Grid>
    ), [data]);

    return (
        <>
            <Box textAlign="center" pt={2} pb={4}>
                <Typography paragraph variant="h1">
                    <Trans>Likes & favorieten</Trans>
                </Typography>

                <LikesMenu
                    items={items}
                    type={type as LikesType}
                    loading={loading}
                />
            </Box>

            {list}
        </>
    );
};
import Container from "@material-ui/core/Container";
import AppBar from "component/AppBar";
import Footer from "component/Footer";
import Loader from "component/Loader";
import PrivateRoute from "component/PrivateRoute";
import enumToPath from "lib/enumToPath";
import React, { Suspense } from "react";
import { useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { getLoginState } from "redux/selector";
import AccountInfo from "route/AccountInfo";
import Auth from "route/Auth";
import AuthCallback from "route/AuthCallback";
import Home from "route/Home";
import HomeExchange from "route/HomeExchange";
import Likes from "route/Likes";
import Messages from "route/Messages";
import MyAdvert from "route/MyAdvert";
import StartMatch from "route/StartMatch";
import MyAdvertPreview from "route/MyAdvertPreview";
import Onboarding from "route/Onboarding";
import Preferences from "route/Preferences";
import Static from "route/Static";
import ViewAdvert from "route/ViewAdvert";
import { LikesType } from "types/LikesType";
import { StaticPages } from "types/StaticPages";
import Register from "../route/Register";
import Forgot from "../route/Forgot";
import ChangePassword from "../route/ChangePassword";
import Faq from "../route/Faq";
import { Box } from "@material-ui/core";
import Support from "../route/Support";

export default () => {
  const loggedIn = useSelector(getLoginState);

  if (loggedIn === undefined) {
    return <Loader />;
  }

  return (
    <Suspense fallback={"LOADING... "}>
      <AppBar />

      <Container maxWidth="lg" id="content">
        <Box pt={10}></Box>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/login" component={Auth} />
          <Route exact path="/faq" component={Faq} />
          <Route exact path="/veelgesteldevragen" component={Faq} />
          <Route exact path="/view/:id" component={ViewAdvert} />
          <Route
            exact
            path="/auth-callback/:provider"
            component={AuthCallback}
          />
          <Route exact path="/register" component={Register} />
          <Route exact path="/forgot" component={Forgot} />

          <PrivateRoute exact path="/account-info" component={AccountInfo} />
          <PrivateRoute exact path="/support" component={Support} />
          <PrivateRoute exact path="/start-match/:id" component={StartMatch} />
          <PrivateRoute exact path="/home-exchange" component={HomeExchange} />
          <PrivateRoute exact path="/preferences" component={Preferences} />
          <PrivateRoute
            exact
            path="/onboarding/:step?"
            component={Onboarding}
          />
          <PrivateRoute exact path="/messages" component={Messages} />
          <PrivateRoute
            exact
            path="/change-password"
            component={ChangePassword}
          />
          <PrivateRoute exact path="/my-advert" component={MyAdvert} />
          <PrivateRoute
            exact
            path="/my-advert-preview/:id"
            component={MyAdvertPreview}
          />

          <PrivateRoute
            exact
            path={enumToPath(LikesType, "/:type")}
            component={Likes}
          />
          <Route
            exact
            path={enumToPath(StaticPages, "/:url")}
            component={Static}
          />
        </Switch>
      </Container>

      <Footer />
    </Suspense>
  );
};

import {Checkbox, CheckboxProps, Typography} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {Endpoint} from "lib/api";
import {useApi} from "lib/useApi";
import React from "react";
import {CheckboxQuestion} from "types/TradeResponse";

interface IProps extends CheckboxQuestion {
  index: React.ReactNode;
  setChecked: ( checked: boolean ) => void;
  checked: boolean;
  locked: boolean;
}

const useStyles = makeStyles( ( {spacing}: Theme ) => createStyles( {
  root: {
    display: "grid",
    gridTemplateColumns: "max-content max-content 1fr",
    columnGap: spacing(),
    alignItems: "center",
    marginBottom: spacing(2),
  },
  content: {
    gridColumn: 3,
  },
} ) );

export default ( {id, index, locked, title, content, checked, setChecked}: IProps ) => {
  const classes = useStyles();
  const [{loading, error}, updateTask] = useApi<string>( Endpoint.updateTradeTask, {
    autoTrigger: false,
    initialData: "",
    params: {id},
  } );

  const onChange: CheckboxProps["onChange"] = async ( e, checked ) => {
    if ( !locked ) {
      await updateTask( {
        data: {answer: checked ? 1 : 0},
      } );

      setChecked( checked );
    }
  };

  return (
      <label className={classes.root}>
        {!!error && <Snackbar open message={error.error} />}
        {loading ? <CircularProgress size={24} /> : index}
        <Checkbox color="primary" checked={checked} onChange={onChange} />
        <Typography variant="h4">{title}</Typography>
        <Typography variant="body2" className={classes.content}>
          {content}
        </Typography>
      </label>
  );
};
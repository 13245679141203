import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import Fab from "@material-ui/core/Fab";
import Snackbar from "@material-ui/core/Snackbar";
import clsx from "clsx";
import CheckboxTask from "component/TradeTasks/CheckboxTask";
import CorpTask from "component/TradeTasks/CorpTask";
import UploadTask from "component/TradeTasks/UploadTask";
import { Endpoint } from "lib/api";
import { useApi } from "lib/useApi";
import React, { useEffect } from "react";
import {
  TradeBlock,
  Question as TradeBlockQuestion,
} from "types/TradeResponse";
import TradeTaskState from "types/TradeTaskState";
import YesNoTask from "./TradeTasks/YesNoTask";

interface IProps extends TradeBlock {
  lockTitle?: string;
  startIndex: number;
  children: (
    questions: React.ReactNodeArray,
    lockBtn?: React.ReactNode
  ) => React.ReactNode;
  checkedState: TradeTaskState;
  setCheckedState: (state: TradeTaskState) => void;
}

const Index = ({ index, active }: { index: number; active?: boolean }) => {
  const classes: any = {}; //useStyles();
  return (
    <div
      className={clsx(classes.index, {
        [classes.active]: active,
      })}
    >
      {index}
    </div>
  );
};

export default ({
  checkedState,
  setCheckedState,
  children,
  lockTitle,
  locked,
  order,
  startIndex,
  questions,
}: IProps) => {
  const [isLocked, setIsLocked] = React.useState(!!locked);
  const [questionTitles, setQuestionTitles] = React.useState<string[]>([]);

  const isReadyToLock = React.useMemo(() => {
    return questions.every(
      (question) => !!checkedState[question.id] || !question.required
    );
  }, [checkedState, questions]);

  const [{ loading, error }, updateTask] = useApi<string>(
    Endpoint.lockTradeBlock,
    {
      autoTrigger: false,
      initialData: "",
      data: { block: order },
    }
  );

  const getUploadQuestions = (qustionId: string) => {
    const uploads = questions.filter((question) => question.type === "upload");
    const result = uploads.reduce((question: any, obj: any) => {
      return { ...question, [obj.id]: [...(question[obj.id] || []), obj] };
    }, {});
    return result[qustionId];
  };

  const getTitleIndex = (questionTitle: string) => {
    return questionTitles.findIndex((element) => element === questionTitle);
  };

  const collectQuestionsTitle = (q: TradeBlockQuestion[]) => {
    let temp: Array<string> = [];

    q.forEach((i: TradeBlockQuestion) => {
      if (temp.findIndex((j) => j === i.title) === -1) {
        temp.push(i.title);
      }
    });

    setQuestionTitles(temp);
  };

  useEffect(() => {
    collectQuestionsTitle(questions);
  }, [questions]);

  const lockTask = async () => {
    await updateTask({
      data: { block: order },
    });

    setIsLocked(true);
  };

  const questionIdsDisplayed: string[] = [];

  const items = questions.map((question, i) => {
    if (questionIdsDisplayed.indexOf(question.id) > -1) {
      return false;
    }
    questionIdsDisplayed.push(question.id);
    const index = (
      <Index active={checkedState[question.id]} index={startIndex + i} />
    );

    const onChange = (state: boolean) => {
      setCheckedState({
        ...checkedState,
        [question.id]: state,
      });
    };

    if (question.type === "checkbox") {
      return (
        <CheckboxTask
          index={index}
          key={question.id + startIndex}
          locked={isLocked}
          checked={!!checkedState[question.id]}
          setChecked={onChange}
          {...question}
        />
      );
    }

    if (question.type === "yesno") {
      return (
        <YesNoTask
          index={index}
          key={question.id + startIndex}
          locked={isLocked}
          isYes={!!checkedState[question.id]}
          setIsYes={onChange}
          {...question}
        />
      );
    }

    if (question.type === "upload") {
      return (
        <UploadTask
          index={getTitleIndex(question.title) + startIndex}
          documents={getUploadQuestions(question.id)}
          key={question.id + i}
          checked={!!checkedState[question.id]}
          setChecked={onChange}
          {...question}
        />
      );
    }

    if (question.type === "corp_yours" || question.type === "corp_theirs") {
      return (
        <CorpTask
          index={getTitleIndex(question.title) + startIndex}
          key={question.id + i}
          {...question}
        />
      );
    }

    return null;
  });

  let lockBtn;
  if (lockTitle) {
    lockBtn = (
      <Fab
        variant="extended"
        size="medium"
        color="primary"
        disabled={!isReadyToLock || isLocked}
        onClick={lockTask}
      >
        {!loading ? lockTitle : <CircularProgress size={12} color="inherit" />}
      </Fab>
    );
  }

  return (
    <Box mt={5}>
      {!!error && <Snackbar open message={error.error} />}

      {children(items, lockBtn)}
    </Box>
  );
};

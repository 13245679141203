import {Endpoint} from "lib/api";
import {useApi} from "lib/useApi";
import React from "react";
import {useHistory, useParams} from "react-router-dom";
import TradeResponse from "types/TradeResponse";

export default () => {
  const {id} = useParams<{ id: any }>();
  const {replace} = useHistory();

  const [{loaded}] = useApi<TradeResponse | null>( Endpoint.trade, {
    method: "POST",
    autoTrigger: true,
    initialData: null,
    data: {advert: id},
  } );

  React.useEffect( () => {
    if ( loaded ) {
      replace( "/home-exchange" );
    }
  }, [loaded, replace] );

  return null;
};
import React, {useEffect, useRef, useState} from "react";

const isInViewport = function(elem: HTMLDivElement) {
    const {top, left, bottom, right} = elem.getBoundingClientRect();
    return (
        top >= 0 &&
        left >= 0 &&
        bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        right <= (window.innerWidth || document.documentElement.clientWidth)
    );
};

type Props = {
    onToggle: (state: boolean) => void;
}

export default ({onToggle}: Props) => {
    const [inView, setInView] = useState<boolean>(false);
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const onScroll = () => {
            if (ref.current) {
                const status = isInViewport(ref.current);
                if (inView !== status) {
                    onToggle(status);
                    setInView(status);
                }
            }
        };

        window.addEventListener("scroll", onScroll);

        onScroll();

        return () => {
            window.removeEventListener("scroll", onScroll);
        };
    }, [inView, onToggle]);

    return (<div ref={ref} />);
}
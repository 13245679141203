import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";

const useStyles = makeStyles(({spacing, typography, palette}: Theme) => createStyles({
    root: {
        "& *": {
            margin: 0,
        },
        "& p": {
            ...typography.body1,
            marginBottom: spacing(),
        },
        "& a": {
            ...typography.body1,
            color: palette.primary.main,
        },
        "& h1": {
            ...typography.h1,
            textAlign: "center",
            marginBottom: spacing(2),
            marginTop: spacing(5),
        },
        "& h2": {
            ...typography.h2,
            marginBottom: spacing(2),
            marginTop: spacing(5),
        },
        "& h3": {
            ...typography.h3,
            fontWeight: typography.fontWeightRegular,
            marginBottom: spacing(),
            marginTop: spacing(5),
        },
        "& h4": {
            ...typography.h4,
            marginBottom: spacing(),
            marginTop: spacing(5),
        },
        "& h5": {
            ...typography.h5,
            marginBottom: spacing(),
            marginTop: spacing(5),
        },
        "& h6": {
            ...typography.h6,
            marginBottom: spacing(),
            marginTop: spacing(5),
        },
        "& ul": {
            paddingLeft: spacing(2),
        },
        "& li": {
            ...typography.body1,
        },
    },
}));

interface IProps {
    children: React.ReactNode
}

export default ({children}: IProps) => (
    <Typography classes={useStyles()} variant="body2" component="div">
        {children}
    </Typography>
);

import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";
import React from "react";

export default (props: SvgIconProps) => {

    return (
        <SvgIcon
            {...props}
            id="Layer_1"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <defs>
                {/* <style>.cls-1{fill:url(.linearGradient)}</style> */}
                <linearGradient id="linearGradient" x1="-9.12" y1="785.81" x2="-8.12" y2="785.81" gradientTransform="matrix(33.42, 0, 0, -36.88, 308.03, 28997.46)" gradientUnits="userSpaceOnUse"><stop offset="0" stopColor="#4984c5" /><stop offset="1" stopColor="#2b2872" /></linearGradient>
            </defs>
            <g id="Group_325" >
                <path
                    id="Path_3451"
                    className="cls-1"
                    d="M22,18.59c.45.18.9.38,1.33.6a11.24,11.24,0,0,1,1.26.74,9,9,0,0,0-.72.91,9.12,9.12,0,0,0-.6,1,11.22,11.22,0,0,0-3-1.37,11.46,11.46,0,0,0-6.28,0,11.89,11.89,0,0,0-2.75,1.16,11.62,11.62,0,0,0-4.14,4.13A12,12,0,0,0,6,28.47a10.86,10.86,0,0,0-.41,3.06H3.29a13.76,13.76,0,0,1,2.45-7.85,13.66,13.66,0,0,1,2.82-3,13.25,13.25,0,0,1,3.66-2.07A9.81,9.81,0,0,1,9,15.25,8.93,8.93,0,0,1,7.9,10.78a9.2,9.2,0,0,1,5.63-8.5,9.31,9.31,0,0,1,7.18,0,9.22,9.22,0,0,1,3.11,14.83A8.82,8.82,0,0,1,22,18.59ZM10.2,10.78a6.91,6.91,0,0,0,.54,2.69,7,7,0,0,0,3.69,3.68,6.92,6.92,0,1,0-4.23-6.37Zm26.51,15a5.65,5.65,0,0,1-.3,1.82,6,6,0,0,1-.85,1.64v9.22L31,36.14l-4.61,2.3V29.22a6,6,0,0,1-.86-1.64,5.77,5.77,0,0,1,3.22-7.12,5.68,5.68,0,0,1,4.48,0,5.9,5.9,0,0,1,3.07,3.07,5.34,5.34,0,0,1,.46,2.23ZM31,22.3a3.5,3.5,0,0,0-1.35.27,3.45,3.45,0,0,0-1.84,1.84,3.51,3.51,0,0,0,0,2.7A3.45,3.45,0,0,0,29.6,29a3.51,3.51,0,0,0,2.7,0,3.45,3.45,0,0,0,1.84-1.84,3.51,3.51,0,0,0,0-2.7,3.45,3.45,0,0,0-1.84-1.84A3.5,3.5,0,0,0,31,22.3Zm2.31,12.41V31a5.77,5.77,0,0,1-4.61,0v3.67l1.15-.57c.38-.18.77-.38,1.15-.58.38.2.77.4,1.15.58l1.16.57Z" />
            </g>
        </SvgIcon>
    )
};
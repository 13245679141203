import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import Slider, {SliderProps} from "@material-ui/core/Slider";
import Typography from "@material-ui/core/Typography";
import React, {ChangeEvent} from "react";
import {useField} from "react-final-form";
import {Trans} from "react-i18next";
import MinMax from "types/MinMax";

type Props = SliderProps & {
  name: string;
  min: number;
  max: number;
}

export default ( {name, ...props}: Props ) => {
  const {input: {value, ...input}} = useField<MinMax>( name );
  const {min, max} = value;

  const onChange = ( e: ChangeEvent<{}>, value: number | number[] ) => {
    if ( Array.isArray( value ) ) {
      input.onChange( {
        min: value[ 0 ],
        max: value[ 1 ] || 1,
      } );
    }
  };

  React.useLayoutEffect( () => {
    if ( !min && !max ) {
      input.onChange( {
        min: props.min,
        max: props.max,
      } );
    }
  }, [min, max, props, input] );

  return (
      <FormControl fullWidth margin="dense" size="small">
        <Box display="flex" justifyContent="space-between">
          <Typography variant="body2">
            <Trans values={{min: min || props.min}} children="€ {{min}},-" />
          </Typography>
          <Typography variant="body2">
            <Trans values={{max: max || props.max}} children="€ {{max}},-" />
          </Typography>
        </Box>
        <Box pl={1.25} pr={1.25}>
          <Slider
              {...props}
              {...input}

              value={[
                min || props.min,
                max || props.max,
              ]}

              onChange={onChange}
          />
        </Box>
      </FormControl>
  );
}
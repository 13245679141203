import {Box} from "@material-ui/core";
import Link from "@material-ui/core/Link";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import FacebookIcon from "component/icon/Facebook";
import MailIcon from "component/icon/Mail";
import TwitterIcon from "component/icon/Twitter";
import WhatsAppIcon from "component/icon/WhatsApp";
import React from "react";
import {Trans} from "react-i18next";

const useStyles = makeStyles(({spacing, palette}: Theme) => createStyles({
    icon: {
        display: "inline-block",
        color: palette.common.white,
        width: spacing(4),
        height: spacing(4),
        marginLeft: spacing(2),
        backgroundColor: palette.primary.main,
        borderRadius: spacing(2),
        padding: spacing()
    }
}));

const items = [
    {
        Icon: WhatsAppIcon,
        getUrl: () => `https://wa.me/?text=${document.title} ${window.location}`
    },
    {
        Icon: MailIcon,
        getUrl: () => `mailto:?subject=${document.title}&body=Check out this advert: ${window.location}`
    },
    {
        Icon: FacebookIcon,
        getUrl: () => `https://www.facebook.com/sharer.php?u=${window.location}`
    },
    {
        Icon: TwitterIcon,
        getUrl: () => `https://twitter.com/share?url=${window.location}&text=${document.title}`
    }
];

export default () => {
    const classes = useStyles();

    return (
        <Box pt={5} display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h2">
                <Trans>Delen</Trans>
            </Typography>

            <div>
                {items.map(({Icon, getUrl}, index) => (
                    <Link
                        href={getUrl()}
                        className={classes.icon}
                        key={index}
                        target="_blank"
                        rel="noopener noreferrer"
                        children={<Icon />}
                    />
                ))}
            </div>
        </Box>
    );
};
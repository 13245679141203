import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Dialog from "@material-ui/core/Dialog";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import CheckAvailability from "component/action/CheckAvailability";
import ProfileForm from "component/form/Profile";
import Loader from "component/Loader";
import {FORM_ERROR} from "final-form";
import {Endpoint} from "lib/api";
import {deleteAccount} from "lib/auth";
import {useApi} from "lib/useApi";
import React, {ReactNode} from "react";
import {Trans} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useParams} from "react-router-dom";
import {setProfile} from "redux/action";
import {getProfile} from "redux/selector";
import Profile from "types/Profile";

const Wrapper = ({children}: { children: NonNullable<ReactNode> }) => (
    <Dialog disableEnforceFocus fullScreen open>
        <Toolbar>
            <Box flexGrow={1}/>
        </Toolbar>

        <Container maxWidth="xs">
            {children}
        </Container>
    </Dialog>
);

export default () => {
    const {step = "0"} = useParams();

    const dispatch = useDispatch();
    const {push} = useHistory();
    const profile = useSelector(getProfile);

    const [, load] = useApi<Profile | null>(Endpoint.profile, {
        initialData: null,
    });

    if (!profile) {
        return <Loader/>;
    }

    if (step === "0") {
        return (
            <Wrapper>
                <CheckAvailability
                    profile={profile}
                    onSuccess={() => {
                        push(`/onboarding/1`);
                    }}
                    onError={deleteAccount}
                />
            </Wrapper>
        );
    }

    const onSubmit = async (values: Profile) => {
        try {
            let birthdate = values.person.birthdate;
            if (birthdate && birthdate.toFormat) {
                // @ts-ignore
                birthdate = birthdate.toFormat("yyyy-LL-dd");
            }

            const response = await load({
                method: "POST",
                data: {
                    contact: values.contact,
                    person: {
                        ...values.person,
                        birthdate,
                    },
                },
            });

            if (response) {
                dispatch(setProfile(response));

                push("/preferences");
            }
        } catch (e) {
            return {
                [FORM_ERROR]: e.message === 'Invalid Field Format'
                    ? 'U heeft niet alle gegevens ingevuld. Vul uw gegevens aan om verder te kunnen.'
                    : e.message
            };
        }
    };

    return (
        <Wrapper>
            <Typography paragraph variant="h1" align="center">
                <Trans>Persoonlijke gegevens</Trans>
            </Typography>

            <Typography paragraph variant="body1" align="center">
                <Trans>
                    Om je nog beter te kunnen helpen in je zoektocht naar een nieuwe
                    woning,
                    willen we dit nog graag weten.
                </Trans>
            </Typography>

            <ProfileForm
                title="Account aanmaken"
                onSubmit={onSubmit}
                initialValues={profile}
            />
        </Wrapper>
    );
}

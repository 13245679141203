import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Logo from "component/icon/Logo";
import Menu from "component/Menu";
import React from "react";
import { Link } from "react-router-dom";

const useStyles = makeStyles(({ breakpoints}: Theme) => createStyles({
    root: {
        "& > :first-child": {
            height: "64px",
            display: "flex",
            alignItems: "center",
        },
        [breakpoints.up("lg")]: {
            padding: 0,
            minHeight: 85,
            alignItems: "flex-end",
        },
    },
}));

export default () => (
    <AppBar position="fixed">
        <Container disableGutters maxWidth="lg">
            <Toolbar classes={useStyles()}>
                <Link to="/"><Logo/></Link>
                <Box flexGrow={1}/>
                <Menu/>
            </Toolbar>
        </Container>
    </AppBar>
);
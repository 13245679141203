import {Trans} from "react-i18next";
import {Alert} from "@material-ui/lab";
import React from "react";
import Box from "@material-ui/core/Box";


type Props = {
    age: 1 | 0 | null
}

export default ({age}: Props) => {
    return (
        <Box mb={1}>
            <Alert severity="error">
                <Trans>
                    <strong>Let op:</strong> Jouw leeftijd is niet passend voor deze woning.<br/>
                    <br/>
                    De verhuurder van deze woning heeft vereisten gesteld aan de leeftijd van nieuwe huurders en jouw leeftijd voldoet hier niet aan.<br/>
                    <br/>
                    Je kunt wel reageren op deze woning, maar je komt alleen in aanmerking als iemand in je huishouden wel aan de leeftijd voldoet.
                </Trans>
            </Alert>
        </Box>

    );
};

import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import Fab from "@material-ui/core/Fab";
import Link from "@material-ui/core/Link";
import FormSteps from "component/FormSteps";
import React, {ReactNode} from "react";
import {Form, FormProps, FormRenderProps} from "react-final-form";
import {Trans} from "react-i18next";
import SubmitError from "component/form/field/SubmitError";

type Props<FormData> = FormProps<FormData> & {
    children: ReactNode;
    submit: string;
    onPrevious?: () => void;
    fromArchive: boolean;

    current: number;
    length: number;
}

export default <FormData extends {}>({children, current, length, onPrevious, submit, fromArchive,...props}: Props<FormData>) => (

    <Form {...props}>
        {({handleSubmit, submitting}: FormRenderProps) => (
            <form onSubmit={handleSubmit}>

                <FormSteps
                    current={current}
                    length={length}
                />

                {children}

                <SubmitError/>
                <Box pt={5} pb={2} display="flex" justifyContent="space-between" alignItems="center">
                    {onPrevious ? (
                        <Link onClick={onPrevious}>
                            <b><Trans>Vorige stap</Trans></b>
                        </Link>
                    ) : <div/>}

                    <Fab color="primary"
                         variant="extended"
                         size="medium"
                         type="submit"
                         disabled={submitting}
                         style={{width: "auto"}}>
                        {!submitting ? <Trans>{submit}</Trans> : (
                            <CircularProgress size={12} color="inherit"/>
                        )}
                    </Fab>
                </Box>
            </form>
        )}
    </Form>
);

import React from "react";

export default () => (
    <>
      <h2>Woningruil. Iets voor u?</h2>
      <p>
        Woont u in een huurhuis van een woningcorporatie*? En wilt u graag
        verhuizen naar een andere huurwoning die beter bij u past? Dan kan
        woningruil een goede oplossing zijn. U wilt bijvoorbeeld liever kleiner
        wonen en alles gelijkvloers. Terwijl een andere huurder juist graag een
        grotere huurwoning met meer slaapkamers wil. Met het ruilen van elkaars
        woning zijn er in één klap twee huishoudens geholpen! Niet opnieuw
        inschrijven, geen punten opbouwen, geen lange wachttijden: wat zouden
        veel huurders daarmee geholpen zijn!
      </p>

      <h3><b>RuilMijnWoning</b></h3>
      <p>
        Precies daarom is <b>RuilMijnWoning</b> ontwikkeld. Een platform dat
        huurders helpt bij het vinden van een andere huurwoning die beter bij
        hun past. Ook corporaties zien de grote voordelen van woningruil. Daarom
        doen steeds meer corporaties hieraan mee. Zo leveren ze een actieve
        bijdrage aan het verminderen van de wachtlijsten en een betere
        doorstroom op de woningmarkt. Bovendien zal de woontevredenheid onder
        hun huurders toenemen.
      </p>
      <p>
        Bent u woningzoekende? Dan is het zeker de moeite waard om te kijken
        welke woningcorporaties hieraan meedoen. Als u zich daar inschrijft kunt
        u later als huurder meedoen aan <b>RuilMijnWoning</b>.
      </p>

      <h3>Hoe werkt <b>RuilMijnWoning</b>?</h3>
      <p>
        Uw corporatie vermeldt de (wettelijke verplichte) voorwaarden waaraan u
        en uw aanvraag moeten voldoen. Zo kunt u uw eigen huurwoning adverteren,
        een geschikte woning zoeken om mee te ruilen en, samen met de andere
        huurder, een aanvraag voorbereiden en indienen. Of de woningruil echt
        door gaat hangt altijd af van de toetsing en goedkeuring door uw
        corporatie
      </p>
      <p>
        Ook als uw corporatie niet deelneemt aan <b>RuilMijnWoning</b> kunt u uw
        eigen huurwoning adverteren en een woningruil voorbereiden en aanvragen.
        Maar doet u dan eerst navraag over de voorwaarden en mogelijkheden van
        woningruil. De corporatie bepaalt uiteindelijk of de woningruil wel of
        niet plaatsvindt.
      </p>

      <h3><b>Uw ervaringen en tips maken het verschil</b></h3>
      <p>
        Het platform <b>RuilMijnWoning</b> is nieuw. ServiceSites International
        BV heeft het ontwikkeld, waarbij intensief is samengewerkt met een
        aantal woningcorporaties. <b>RuilMijnWoning</b> heeft de ambitie om hét
        platform voor woningruil van Nederland te worden. Dat kan alleen door
        goed naar uw ervaringen te luisteren. Wilt u ze
        via <a href="mailto:helpdesk@ruilmijnwoning.nl">helpdesk@ruilmijnwoning.nl</a> aan ons
        doorgeven? Ook uw tips zijn van harte welkom! Zo blijven we onze service
        verbeteren en kunnen we huurders steeds beter helpen met hun woningruil.
      </p>

      <p>*hiermee worden ook gemeentelijke woningbedrijven bedoeld</p>
    </>
)

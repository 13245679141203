import React from "react";
import Message from "types/Message";

type Props = {
    onClose: () => void;
    message: Message;
}

export default (props: Props) => (
    <>&nbsp;</>
);
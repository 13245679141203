import {createStyles, makeStyles} from "@material-ui/core/styles";
import {TooltipProps} from "@material-ui/core";
import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import Info from "component/icon/Info";

const useStyles = makeStyles(() => createStyles({
    icon: {
        fontSize: 12,
        cursor: 'pointer'
    },
}));

export default (props: Omit<TooltipProps, 'children'>) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    return (
        <Tooltip arrow {...props} open={open} onClose={handleClose} onOpen={handleOpen}>
            <Info className={classes.icon} onClick={handleOpen}/>
        </Tooltip>
    );
};

import {Trans} from "react-i18next";
import {Alert} from "@material-ui/lab";
import React from "react";
import {Link as RouterLink} from "react-router-dom";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";


type Props = {
    householdsize: 1 | 0 | null
}

export default ({householdsize}: Props) => {
    return (

            <Box mb={1}>
                <Alert severity="error">
                    <Trans>
                        <strong>Let op:</strong> Jouw gezinsgrootte is niet passend voor deze woning.<br/>
                        <br/>
                        De verhuurder van deze woning heeft vereisten gesteld aan de gezinsgrootte van nieuwe huurders. De huidige instellingen van je gezin voldoen hier niet aan.
                        Via <Link component={RouterLink} to="/account-info">Account</Link> kun je de grootte van je gezin aangeven.<br/>
                        <br/>
                        Je kunt wel reageren op deze woning, maar de kans dat je in aanmerking komt is erg klein.
                    </Trans>
                </Alert>
            </Box>

    );
};

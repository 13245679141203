import {MouseEvent, useCallback, useState} from "react";

export default (initialState: boolean = false): [boolean, () => void] => {
    const [state, setState] = useState(initialState);
    const toggle = useCallback((e?: MouseEvent) => {
        if (e) {
            e.preventDefault();
        }

        setState((state: boolean) => !state);
    }, []);

    return [state, toggle];
}

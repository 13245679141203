export default (value?: number): string => {
    if (value === undefined) {
        return "#d8d8d8";
    }
// 4 out of 4 matched => Green
    if (value === 4) {
        return "#32782d";
    }
// 3 out of 4 matched => Light Green
    if (value === 3) {
        return "#64cb54";
    }
// 2 out of 4 matched => Orange
    if (value === 2) {
        return "#f3b91a";
    }
// 1 out of 4 matched => Yellow
    if (value === 1) {
        return "#F3F31A";
    }

    // 0 out of 4 matched => Red
    return "#d42323";
};

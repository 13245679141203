import {Link} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import useToggle from "lib/useToggle";
import React from "react";
import {Trans} from "react-i18next";
import FullAdvert from "types/FullAdvert";
import InfoTooltip from 'component/InfoTooltip';
import {formatMoney} from "../lib/format";

const useStyles = makeStyles(({spacing}: Theme) => createStyles({
    list: {
        display: "grid",
        gridTemplateColumns: "0fr 1fr",
        gridAutoRows: "auto",
        gridRowGap: spacing(),
        gridColumnGap: spacing(2),
        alignItems: "center",
        marginBottom: spacing(),
    },
    infoProperty: {
        display: "flex",
        alignItems: "center",
        "& svg": {
            marginLeft: 5,
        },
    },
}));

interface IProps {
    advert: FullAdvert
}

const formatCosts = (value: number) => {
    return formatMoney(value);
}

export default ({advert: {properties, extras, buildyear}}: IProps) => {
    const classes = useStyles();
    const [active, toggleActive] = useToggle(false);

    const {surface: {livable}, rent} = properties;
    return (
        <Box pt={{xs: 2, sm: 5}}>
            <Typography variant="h2" paragraph>
                <Trans>Kenmerken</Trans>
            </Typography>

            <div className={classes.list}>
                <Typography variant="h3" className={classes.infoProperty}>
                    <Trans>Huurprijs</Trans>
                    <InfoTooltip
                        title="De corporatie behoudt het recht om de geadverteerde huurprijs aan te passen. Je kunt geen rechten ontlenen aan deze huurprijs."
                    />
                </Typography>
                <Typography variant="body1">
                    € {formatCosts(rent.basiccosts)} per maand
                </Typography>
                <Typography variant="h3" className={classes.infoProperty}>
                    <Trans>Servicekosten</Trans>
                    <InfoTooltip
                        title="De corporatie behoudt het recht om de geadverteerde servicekosten aan te passen. Je kunt geen rechten ontlenen aan deze servicekosten"
                    />
                </Typography>
                <Typography variant="body1">
                    € {formatCosts(rent.servicecosts)} per maand
                </Typography>

                <Typography variant="h3">
                    <Trans>Type</Trans>
                </Typography>
                <Typography variant="body1">
                    {properties.type.name}
                </Typography>

                <Typography variant="h3">
                    <Trans>Aantal kamers</Trans>
                </Typography>
                <Typography variant="body1">
                    {properties.rooms}
                </Typography>

                <Typography variant="h3">
                    <Trans>Woonoppervlakte</Trans>
                </Typography>
                <Typography variant="body1">
                    <Trans values={{livable}}>
                        {{livable}}m<sup>2</sup>
                    </Trans>
                </Typography>
                {active && (
                    <>
                        <Typography variant="h3">
                            <Trans>Bouwjaar</Trans>
                        </Typography>
                        <Typography variant="body1">
                            {buildyear}
                        </Typography>
                        <Typography variant="h3">
                            <Trans>Energielabel</Trans>
                        </Typography>
                        <Typography variant="body1">
                            {properties.energylabel.toUpperCase()}
                        </Typography>

                        <Typography variant="h3">
                            Extra's
                        </Typography>
                        <Typography variant="body1">
                            {extras.map(item => item.name).join(", ")}
                        </Typography>
                    </>
                )}
            </div>

            <Typography color="primary"
                        component={Link}
                        href=""
                        onClick={toggleActive}>
                <b>
                    <Trans>
                        {active ? "Minder kenmerken" : "Bekijk alle kenmerken"}
                    </Trans>
                </b>
            </Typography>
        </Box>
    );
}

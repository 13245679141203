import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";

export default () => (
  <>
    <h2>Passend Toewijzen </h2>
    <p>
      Voor sociale huurwoningen zijn woningcorporaties verplicht om de regels
      van het Passend Toewijzen te volgen. De Rijksoverheid heeft hier regels
      voor opgesteld.
    </p>
    <p>
      De regels voor Passend Toewijzen kijken naar twee elementen: uw
      verzamelinkomen en uw huishoudgrootte. Uw huishoudinkomen wordt berekend
      door het verzamelinkomen van de bewoners bij elkaar op te tellen. Het
      verzamelinkomen wordt elk jaar vastgesteld door de Belastingdienst aan de
      hand van uw belastingaangifte. Inwonende kinderen tellen hiervoor niet
      mee.
    </p>
    <p>
      Voor uw huishoudgrootte wordt gekeken naar al uw medebewoners, dus
      inclusief kinderen en inwonende ouders.
    </p>
    <p>
      In de tabel hieronder kunt U zien welke huur voor u passend is in 2024:
    </p>
    <Table className="striped">
      <TableHead>
        <TableRow>
          <TableCell>Huishoudgrootte</TableCell>
          <TableCell>Inkomen</TableCell>
          <TableCell>Huurprijs</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {/* 1 persoon */}
        <TableRow>
          <TableCell rowSpan={4}>1 persoon</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>minder dan € 27.725</TableCell>
          <TableCell>maximaal € 650,43</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>tussen € 27.725 en € 47.699</TableCell>
          <TableCell>tussen € 650,44 en € 879,66</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>meer dan € 47.699</TableCell>
          <TableCell>minimaal € 879,66</TableCell>
        </TableRow>

        {/* 2 personen */}
        <TableRow>
          <TableCell rowSpan={4}>2 personen</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>minder dan € 37.625</TableCell>
          <TableCell>maximaal € 650,43</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>tussen € 37.625 en € 52.671</TableCell>
          <TableCell>tussen € 650,44 en € 879,66</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>meer dan € 52.671</TableCell>
          <TableCell>minimaal € 879,66</TableCell>
        </TableRow>

        {/* 3 of meer personen */}
        <TableRow>
          <TableCell rowSpan={4}>3 of meer personen</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>minder dan € 37,625</TableCell>
          <TableCell>maximaal € 697,07</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>tussen € 37.625 en € 52.671</TableCell>
          <TableCell>tussen € 697,07 en € 879,66</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>meer dan € 52.671</TableCell>
          <TableCell>minimaal € 879,66</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </>
);

import Tags, { TagsProps } from "component/form/field/Tags";
import React, { useMemo } from "react";
import FilterField from "types/FilterField";

type IProps = Omit<TagsProps, "available"> & {
    formatName?: (name: number) => string;
    min: number;
    max: number;
    readOnly?: boolean;
    onChange?: (value: number) => void
}

export default ({ min, max, formatName, readOnly, onChange, ...props }: IProps) => {
    const available = useMemo<FilterField[]>(() => {
        const items: FilterField[] = [];
        let current = min;
        while (current <= max) {
            items.push({
                name: formatName ? formatName(current) : `${current}`,
                id: current,
            });

            current++;
        }
        return items;
    }, [min, max, formatName]);

    return (
        <Tags
            onClick={(current) => {
                if (onChange) {
                    onChange(current);
                }
            }}
            readOnly={readOnly}
            available={available}
            {...props}
        />
    );
}
import React from "react";

export default () =>
    (
        <>
            <h1>In vier stappen naar een andere woning</h1>
            <p>
                Wil je eerst even rondkijken op ruilmijnwoning.nl? Dat kan! Misschien spreken één of meer woningen je
                aan. Je kunt eenvoudig de lijst met aangeboden woningen korter maken door jouw filters in te stellen.
                Denk je nu dat woningruil een optie voor jou is? Dan is het tijd om aan de slag te gaan. We helpen je in
                vier stappen op weg naar een andere woning:
            </p>

            <ol type="1">
                <li>
                    <b>Het aanmaken van een persoonlijk account:</b> Met een account kun je woningen bekijken en opslaan
                    als favoriet. Hoe je een account aanmaakt lees je hier.
                </li>
                <li>
                    <b>Een zoekprofiel instellen:</b> Wanneer je bent ingelogd met je persoonlijke account, kun je je
                    zoekprofiel instellen. In dit zoekprofiel kun je jouw woonwensen aangeven. Bijvoorbeeld in welke
                    omgeving je op zoek bent naar een woning en hoeveel kamers deze woning zou moeten hebben. Met je
                    zoekprofiel en jouw voorkeuren kun je heel gericht zoeken naar interessante huurwoningen voor jou.
                </li>
                <li>
                    <b>Je eigen woning adverteren.</b> Woningruil kan alleen wanneer jij jouw eigen woning ook
                    adverteert. Daarvoor maak je een eigen advertentie aan. Hoe je dat doet lees je hier.
                </li>
                <li>
                    <b>Aangeven dat je interesse hebt in een woning:</b> Je kunt op twee manieren laten zien dat je een
                    woning je interesseert. Dit kun je dus alleen doen wanneer je ook je eigen woning adverteert.

                    <ol type="a">
                        <li>
                            <u>Een woning als favoriet opslaan.</u> Wil je een woning bewaren omdat je er geïnteresseerd
                            in bent? Dan kun je deze woning als favoriet opslaan door op het hartje te klikken. Woningen
                            die je als favoriet hebt opgeslagen kun je terugvinden onder likes & favorieten. De
                            aanbieder krijgt nog geen melding dat je geïnteresseerd bent in zijn of haar woning.
                        </li>
                        <li>
                            <u>Een woning leuk vinden.</u> Je kunt een woning leuk vinden door op het duimpje omhoog te
                            klikken. De aanbieder van de woning ontvangt daar een bericht van. Vindt de aanbieder jouw
                            woning ook leuk? Dan is er een match! Jullie kunnen dan het proces van woningruil volgen en
                            contact met elkaar opnemen. Wanneer jullie beiden enthousiast zijn kan de woningruil in gang
                            gezet worden.
                        </li>
                    </ol>
                </li>
            </ol>

        </>
    )

import Chip from "@material-ui/core/Chip";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import ChevronRightIcon from "component/icon/ChevronRight";
import Slaagkans from "component/icon/Slaagkans";
import React, {MouseEvent, useMemo} from "react";
import {Trans} from "react-i18next";
import {useSelector} from "react-redux";
import {getLoginState, getProfileState} from "redux/selector";
import AdvertMatch from "types/AdvertMatch";
import {useHistory} from 'react-router-dom'

const useStyles = makeStyles(({spacing, typography}: Theme) => createStyles({
    root: {
        height: 40,
        borderRadius: 20,
        marginLeft: spacing(),
        background: "#fff",
        "&:hover $label, &:active $label": {
            opacity: 1,
            width: "auto"
        },
        "&:hover $deleteIcon, &:active $deleteIcon": {
            display: "none"
        },
        "&:hover": {
            background: "#fff"
        }
    },
    icon: {
        width: "auto"
    },
    label: {
        width: 0,
        opacity: 0,
        lineHeight: typography.pxToRem(15),
        fontSize: typography.pxToRem(12)
    },
    deleteIcon: {
        width: "1em",
        height: "1em"
    }
}));

const isTouchDevice = 'ontouchstart' in window || 'onmsgesturechange' in window;

interface IProps {
    id: string;
    match: AdvertMatch;
    className?: string;
}

export default ({id, match, className}: IProps) => {
    const activeRef = React.useRef(false);
    const {push} = useHistory()
    const loggedIn = useSelector(getLoginState);

    const profileState = useSelector(getProfileState);
    const {myDemands: {count: myCount, of: myOf}, theirDemands: {count: theirCount, of: theirOf}} = match;

    const label = useMemo(() => {
        if (!loggedIn) {
            return (
                <Trans>
                    <b>Bekijk de woonwensen van de aanbieder</b>
                </Trans>
            )
        }
        if (!profileState || !profileState.advertPublished) {
            return (
                <Trans values={{myCount, myOf}}>
                    <b>{{myCount}}/{{myOf}} van mijn woonwensen</b>
                </Trans>
            );
        }

        return (
            <Trans values={{myCount, myOf, theirCount, theirOf}}>
                <b>Jouw huis: {{theirCount}}/{{theirOf}} woonwensen van aanbieder</b><br/>
                Dit huis: {{myCount}}/{{myOf}} van mijn woonwensen
            </Trans>
        );
    }, [loggedIn, profileState, myCount, myOf, theirCount, theirOf]);

    const onClick = (event: MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
        event.preventDefault();

        if (!loggedIn && (activeRef.current || !isTouchDevice)) {
            push(`/view/${id}#ruilkans`);
        }

        activeRef.current = true;
    };

    return (
        <Chip
            clickable
            className={className}
            classes={useStyles()}
            icon={(
                <Slaagkans
                    left={loggedIn ? myCount : undefined}
                    right={profileState && profileState.advertPublished ? theirCount : undefined}
                />
            )}
            label={label}
            deleteIcon={(
                <ChevronRightIcon fontSize="small"/>
            )}
            onClick={onClick}
            onDelete={onClick}
        />
    );
};

import React from "react";

export default () =>
    (
        <>
            <h1>
                Bedankt voor jouw betaling.
            </h1>
            <p>
                Als jouw betaling succesvol afgerond is, ontvang je van ons een e-mail. We informeren ook meteen jouw nieuwe verhuurder.
            </p>
            <p>
                Jouw betaling is pas succesvol afgerond als je van ons een e-mail met een bevestiging ontvangen hebt. Heb je wel betaald maar deze e-mail na een uur nog niet ontvangen? Neem dan even contact op met ons via helpdesk@ruilmijnwoning.nl
            </p>
        </>
    )
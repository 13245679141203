import DateUtils from "@date-io/luxon";
import CssBaseline from "@material-ui/core/CssBaseline";
import {ThemeProvider} from "@material-ui/core/styles";
import {LocalizationProvider} from "@material-ui/pickers";
import App from "component/App";
import {i18n} from "data/makeI18n";
import makeStore from "data/makeStore";
import makeTheme from "data/makeTheme";
import {createBrowserHistory} from "history";
import React from "react";
import {render} from "react-dom";
import {I18nextProvider} from "react-i18next";
import {Provider} from "react-redux";
import {Router} from "react-router-dom";
import reducer, {initial} from "redux/reducer";
import saga from "redux/saga";
import 'bootstrap/dist/css/bootstrap.min.css';

import ScrollToTop from "./component/ScrollToTop";

const history = createBrowserHistory( {
  basename: i18n.language,
} );
const store = makeStore( {saga, reducer, history, initial} );
const theme = makeTheme();

const element = (
    <LocalizationProvider dateAdapter={DateUtils}>
      <Router history={history}>
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <ScrollToTop />
            <I18nextProvider i18n={i18n}>
              <App />
            </I18nextProvider>
          </ThemeProvider>
        </Provider>
      </Router>
    </LocalizationProvider>
);

render( element, document.getElementById( "root" ) );
import React from "react";
import {useSelector} from "react-redux";
import {Route, RouteProps} from "react-router-dom";
import {getLoginState} from "redux/selector";
import Auth from "route/Auth";

export default (props: RouteProps) => {
    const loggedIn = useSelector(getLoginState);

    if (!loggedIn) {
        return (
            <Route
                {...props}
                component={Auth}
            />
        );
    }

    return (
        <Route {...props} />
    );
}
import Box from "@material-ui/core/Box";
import InputLabel from "@material-ui/core/InputLabel";
import Typography from "@material-ui/core/Typography";
import RoomPhoto from "component/form/field/RoomPhoto";
import CheckIcon from "component/icon/Check";
import React, {useMemo} from "react";
import {useField} from "react-final-form";
import {Trans} from "react-i18next";
import MyAdvertPhotos from "types/MyAdvertPhotos";

export default () => {
    const {input: {value: advert}} = useField<string>("id", {
        subscription: {
            value: true
        }
    });

    const {input: {value}} = useField<MyAdvertPhotos[]>("photos", {
        subscription: {
            value: true
        }
    });

    const fields = useMemo(() => (value || [])
        .map((v, i) => (
            <div key={i} style={{order: v.order}}>
                <InputLabel><Trans>{v.name}</Trans></InputLabel>
                <RoomPhoto name={`photos.${i}`} advert={advert} />
            </div>
        )), [value, advert]);

    return (
        <>
            <Typography variant="h1" align="center">
                <Trans>Foto’s maken</Trans>
            </Typography>

            <Box pt={3} pb={2}>
                <Typography variant="body1" gutterBottom>
                    <CheckIcon style={{color: "#64cb54", fontSize: 12}} />&nbsp;
                    <Trans>Ruim je huis netjes op</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <CheckIcon style={{color: "#64cb54", fontSize: 12}} />&nbsp;
                    <Trans>Zet persoonlijke dingen even weg</Trans>
                </Typography>
                <Typography variant="body1">
                    <CheckIcon style={{color: "#64cb54", fontSize: 12}} />&nbsp;
                    <Trans>Maak foto’s vanuit verschillende hoeken</Trans>
                </Typography>
                <Typography variant="body1">
                    <CheckIcon style={{color: "#64cb54", fontSize: 12}} />&nbsp;
                    <Trans>TIP: Gebruik je smartphone om in te loggen en direct foto's te maken en te uploaden.</Trans>
                </Typography>
            </Box>

            <Box display="flex" flexDirection="column">
                {fields}
            </Box>

            <Box pt={2} />
            <Typography variant="body2" align="center">
                <Trans>
                    Ben je nog niet in de gelegenheid om foto's te maken? Ga dan een andere keer verder. LET OP: Zonder foto's is het niet mogelijk om de woning te adverteren, of een preview van de woning te bekijken!
                </Trans>
            </Typography>
        </>
    );
};
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import Fab from "@material-ui/core/Fab";
import React from "react";
import {useFormState} from "react-final-form";
import {Trans} from "react-i18next";
import SubmitError from "component/form/field/SubmitError";

type Props = {
    title: string;
}

export default ({title}: Props) => {
    const {submitting} = useFormState();

    return (
        <Box pt={2} pb={2} textAlign="center">
            <SubmitError/>
            <Fab color="primary" variant="extended" size="medium" type="submit" disabled={submitting}>
                {
                    !submitting
                        ? <Trans>{title}</Trans>
                        : <CircularProgress size={12} color="inherit"/>
                }
            </Fab>
        </Box>
    );
}

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import {forgotPassword} from "lib/auth";
import React, {useState} from "react";
import {Trans} from "react-i18next";
import Login from "../types/Login";
import {Alert} from "@material-ui/lab";
import ForgotForm from "./ForgotForm";


type Props = {
    title: string;
    description?: string;
}

export default ({title, description}: Props) => {
    const [done, setDone] = useState(false);

    const onSubmit = async (values: Login) => {
        try {
            await forgotPassword(values.email);
        } catch (e) {
            /* Do nothing */
        }
        setDone(true);
    };

    return (
        <Box textAlign="center">
            <Box pt={2} pb={4}>
                <Typography variant="h1" align="center" paragraph>
                    <Trans>{title}</Trans>
                </Typography>

                {description && <Typography><Trans>{description}</Trans></Typography>}
            </Box>
            <Box bgcolor="#F5F5F5" padding="20px" paddingBottom="10px" marginBottom="20px" textAlign="left">
                {done && (
                    <Alert severity="success">
                        Je ontvangt binnen enkele minuten een e-mail met een link om je wachtwoord opnieuw in te stellen. Geen e-mail ontvangen? Controleer je spam of ongewenste e-mail. Nog niets ontvangen? Stuur dan een e-mail naar <a href="mailto:helpdesk@ruilmijnwoning.nl">helpdesk@ruilmijnwoning.nl</a>
                    </Alert>
                )}
                <ForgotForm onSubmit={onSubmit} title="Wachtwoord opnieuw instellen"/>
            </Box>
        </Box>
    );
};

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React from "react";

type Props = {
    length: number;
    current: number;
}

const useStyles = makeStyles(({palette, spacing, typography}: Theme) => createStyles({
    root: (length: number) => ({
        display: "grid",
        gridTemplateColumns: `repeat(${length}, 0fr)`,
        gridColumnGap: spacing(),
        justifyContent: "center",
        margin: spacing(2),
    }),
    item: {
        ...typography.caption,
        width: spacing(3),
        height: spacing(3),
        lineHeight: `${spacing(3)}px`,
        backgroundColor: palette.divider,
        borderRadius: spacing(1.5),
        textAlign: "center",
        color: palette.common.white,
    },
    active: {
        backgroundColor: palette.primary.main,
    },
}));

export default ({length, current}: Props) => {
    const classes = useStyles(length);

    return (
        <div className={classes.root}>
            {Array.from({length}, (v, i) => (
                <b key={i} className={`${classes.item} ${i === current ? classes.active : ""}`}>
                    {i + 1}
                </b>
            ))}
        </div>
    );
}
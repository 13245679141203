import IconButton from "@material-ui/core/IconButton";
import {SvgIconProps} from "@material-ui/core/SvgIcon";
import FavoriteActiveIcon from "component/icon/FavoriteActive";
import FavoriteInactiveIcon from "component/icon/FavoriteInactive";
import {Endpoint} from "lib/api";
import {useApi} from "lib/useApi";
import useToggle from "lib/useToggle";
import React, {MouseEvent} from "react";
import {useDispatch} from "react-redux";
import {toggleFavorite} from "redux/action";
import {LikesType} from "types/LikesType";

type IProps = SvgIconProps & {
    active: 0 | 1;
    advert: string;
}

export default ({active, advert, ...props}: IProps) => {
    const [state, toggle] = useToggle(Boolean(active));
    const dispatch = useDispatch();
    const [{loading}, sendRequest] = useApi<string>(Endpoint.like, {
        params: {type: LikesType.favorite},
        initialData: ""
    });

    const Icon = state ? FavoriteActiveIcon : FavoriteInactiveIcon;

    const onClick = async (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        const resp = await sendRequest({
            method: state ? "DELETE" : "POST",
            data: {advert}
        });

        if (resp === "ok") {
            toggle();

            dispatch(toggleFavorite(advert));
        }
    };

    return (
        <IconButton disabled={loading} size="small" onClick={onClick}>
            <Icon {...props} />
        </IconButton>
    );
}
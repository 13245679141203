import each from "lodash/each";
import isDate from "lodash/isDate";
import isEmpty from "lodash/isEmpty";
import isUndefined from "lodash/isUndefined";
import map from "lodash/map";
import mapValues from "lodash/mapValues";
import omitBy from "lodash/omitBy";
import isNumber from "lodash/isNumber";
import {DateTime} from 'luxon'
import {Composer, Errors, ObjectMap, Rule, Rules, Validator} from "./types";

export const required = (value: any) => !!value && !isEmpty(value);

const passwordRegExp = /[ !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~-]/;
export const password = (value: any) => {
    if(value.length <= 7) {
        return false;
    } else if(!/\d/.test(value)) {
        return false;
    } else if(!/[A-Z]/.test(value)) {
        return false;
    } else if(!passwordRegExp.test(value)) {
        return false;
    } else {
        return true;
    }
}

export const date = (value: any) => isDate(value) || DateTime.isDateTime(value);

const emailRegExp = /\S+@\S+\.\S+/;
export const email = (value: any) => !isEmpty(value) && emailRegExp.test(value);

export const number = (value: any) => isNumber(value);

// export const isRequiredIf = <Values extends FormValues>( field: keyof Values, is: any ): ValidatorRule<Values> => ( value:Values[keyof Values], data:Values ) => (
//     data[ field ] === is || isEmpty( value )
// );
//
// export const isNotRequiredIf = <Values extends FormValues>( field: keyof Values, is: any ): ValidatorRule<Values> => ( value:Values[keyof Values], data:Values ) => (
//     data[ field ] === is || isEmpty( value )
// );
//
// export const sizeIsLessThan = ( minValue: number ): ValidatorRule => value => {
//     return size( value ) < minValue;
// };
//
// export const sizeIsMoreThan = ( maxValue: number ): ValidatorRule => value => {
//     return size( value ) > maxValue;
// };
//
// export const numberIsLessThan = ( minValue: number ): ValidatorRule => value => {
//     return toNumber( value ) < minValue;
// };
//
// export const numberIsMoreThan = ( maxValue: number ): ValidatorRule => value => {
//     return toNumber( value ) > maxValue;
// };
//
// export const isInteger: ValidatorRule = value => Number.isInteger( Number( value ) );
// export const isNumber: ValidatorRule = value => Number.isNaN( Number.parseFloat( value ) );
//
// export const isOneOf = ( enumeration: any[] ): ValidatorRule => value => {
//     return enumeration.indexOf( value ) > -1;
// };
// export const isNotOneOf = ( enumeration: any[] ): ValidatorRule => value => {
//     return enumeration.indexOf( value ) === -1;
// };
//
// export const isMatch = <Values extends FormValues>( field: keyof Values ): ValidatorRule<Values> => ( value, data ) => {
//     return data[ field ] === value;
// };
//
// export const isMatchRegExp = ( regExp: RegExp ): ValidatorRule => value => {
//     return value && regExp.test( value );
// };
//
// export const isNotMatchRegExp = ( regExp: RegExp ): ValidatorRule => value => {
//     return value && !value.match( regExp );
// };

export const composeRules = <V extends ObjectMap, K extends keyof V>(rules: ObjectMap<Rule<V, K>>): Composer<V, K> => {
    return (value: V[K], data: V) => {
        for (const key in rules) {
            if (rules.hasOwnProperty(key) && !rules[key](value, data)) {
                return key;
            }
        }
    };
};

export const makeValidator = <V extends ObjectMap>(rules: Rules<V>): Validator<V> => {
    return (values: V): Errors => {
        const keys = mapValues(rules, (rule: Rule<V>, key) => {
            return rule(values[key], values);
        });

        const errors = omitBy(keys, isUndefined);

        return isEmpty(errors) ? undefined : errors;
    };
};

export const makeArrayValidator = <I extends ObjectMap>(rules: Rules<I>): Validator<I[]> => {
    const itemValidator = makeValidator<I>(rules);
    return (values: I[]) => {
        const errors: Errors = {};

        each(map(values, itemValidator), (item, key) => {
            if (!isEmpty(item)) {
                errors[key] = item;
            }
        });

        return isEmpty(errors) ? undefined : errors;
    };
};

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Fab from "@material-ui/core/Fab";
import Typography from "@material-ui/core/Typography";
import {Endpoint} from "lib/api";
import {useApi} from "lib/useApi";
import React from "react";
import {Trans} from "react-i18next";
import {Link} from "react-router-dom";
import {LikesType} from "types/LikesType";
import Message from "types/Message";

type Props = {
    onClose: () => void;
    message: Message;
}

export default ({message: {sender: {advert}}, onClose}: Props) => {
    const [, onCancel] = useApi<string>(Endpoint.like, {
        method: "DELETE",
        params: {type: LikesType.like},
        query: new URLSearchParams({advert}),
        initialData: ""
    });

    return (
        <>
            <Box p={2} textAlign="center">
                <Typography variant="h1" paragraph>
                    <Trans>Regel je woningruil</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>Je hebt een woning gevonden om je eigen huurwoning mee te ruilen.</Trans>
                </Typography>
                <Typography variant="body1" paragraph>
                    <Trans>
                        Klik op ‘Ik wil ruilen’ om verder te gaan en je woningruil met de andere huurder en
                        woningcorporaties te regelen.
                    </Trans>
                </Typography>
            </Box>
            <Box p={2} textAlign="center">
                <Fab color="primary" variant="extended" size="medium" component={Link} to="/home-exchange">
                    <Trans>Ik wil ruilen</Trans>
                </Fab>
                <Box p={1} />
                <Button variant="text" onClick={() => onCancel().then(onClose)}>
                    <Trans>Toch niet ruilen</Trans>
                </Button>
            </Box>
        </>
    );
};
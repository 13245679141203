import StaticDesktopContainer from "component/StaticDesktopContainer";
import ExchangeMyHouse from "data/static/ExchangeMyHouse";
import HowDoesItWork from "data/static/HowDoesItWork";
import UsefulTips from "data/static/UsefulTips";
import InFourSteps from "data/static/InFourSteps";
import HowToCreateAccount from "data/static/HowToCreateAccount";
import CreateYourAd from "data/static/CreateYourAd";
import ShowInterestInAProperty from "data/static/ShowInterestInAProperty";
import ArrangeHomeExchange from "data/static/ArrangeHomeExchange";
import React, {FC} from "react";
import {useParams} from "react-router-dom";
import {StaticPages} from "types/StaticPages";
import TMap from "types/TMap";
import StaticContent from "component/StaticContent";
import FittingRules from "../data/static/FittingRules";
import Webinar from "../data/static/Webinar";
import PrivacyStatement from "../data/static/PrivacyStatement";
import PaymentDone from "../data/static/PaymentDone";

const Static: TMap<FC> = {
    [StaticPages.howDoesItWork]: HowDoesItWork,
    [StaticPages.usefulTips]: UsefulTips,
    [StaticPages.exchangeMyHome]: ExchangeMyHouse,
    [StaticPages.inFourSteps]: InFourSteps,
    [StaticPages.howToCreateAccount]: HowToCreateAccount,
    [StaticPages.createYourAd]: CreateYourAd,
    [StaticPages.showInterestInAProperty]: ShowInterestInAProperty,
    [StaticPages.arrangeHomeExchange]: ArrangeHomeExchange,
    [StaticPages.privacyStatement]: PrivacyStatement,
    [StaticPages.fittingRules]: FittingRules,
    [StaticPages.webinar]: Webinar,
    [StaticPages.paymentDone]: PaymentDone,
};
export default () => {
    const {url} = useParams<{ url: StaticPages }>();

    const Page: FC = Static[url as StaticPages] ? Static[url as StaticPages] : () => null;

    return (
        <StaticDesktopContainer>
            <StaticContent>
                <Page/>
            </StaticContent>
        </StaticDesktopContainer>
    );
};

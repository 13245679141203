import Fab from "@material-ui/core/Fab";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import DislikeToggle from "component/action/DislikeToggle";
import LikeToggle from "component/action/LikeToggle";
import Slaagkans from "component/icon/Slaagkans";
import React, {MutableRefObject, useMemo} from "react";
import {Trans} from "react-i18next";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {getProfileState} from "redux/selector";
import FullAdvert from "types/FullAdvert";

const useStyles = makeStyles(({spacing, typography, palette}: Theme) => createStyles({
    root: {
        position: "relative",
        textAlign: "center",

        padding: spacing(2),
        paddingBottom: spacing(3),

        "&:before": {
            top: 0,
            bottom: spacing(-4),
            left: "-1000%",
            right: "-1000%",
            content: "''",
            position: "absolute",
            backgroundColor: palette.primary.light,
            zIndex: -1
        }
    },
    list: {
        display: "grid",
        gridTemplateColumns: "repeat(3, 80px)",
        gridTemplateRows: "1fr 0fr",
        gridRowGap: spacing(),
        gridAutoFlow: "column",
        justifyItems: "center",
        justifyContent: "center",
        alignItems: "center"
    },
    button: {
        color: palette.text.primary
    },
    buttonActive: {
        color: palette.primary.main,
        borderColor: "#198efd4d",
        background: "#198efd1a"
    },
    logo: {
        fontSize: typography.pxToRem(24),
        width: "auto"
    }
}));

interface IProps {
    advert: FullAdvert;
    id: string;
    goToRef: MutableRefObject<null | HTMLElement>;
}

export default ({advert, id, goToRef}: IProps) => {
    const profileState = useSelector(getProfileState);
    const classes = useStyles();

    const message = useMemo(() => {
        if (profileState && profileState.state < 4) {
            return (
                <>
                    <Typography variant="h2">
                        <Trans>Is deze woning een match?</Trans>
                    </Typography>
                    <Typography variant="body2" paragraph>
                        <Trans>Om te kunnen matchen moet je eerst je eigen woning toevoegen.</Trans>
                    </Typography>

                    <Fab color="primary" variant="extended" size="medium" component={Link} to="/my-advert">
                        <Trans>Voeg jouw woning toe</Trans>
                    </Fab>
                </>
            );
        }

        if (profileState && profileState.state === 7) {
            return (
                <>
                    <Typography variant="body2" paragraph>
                        <Trans>
                            U hebt op dit moment een actieve match en kunt niet liken. Gaat de huidige match niet goed
                            of heeft u een woning gezien die u lievert wilt? Klik dan onder ‘mijn match’ op ‘match
                            stoppen’
                        </Trans>
                    </Typography>

                    <Fab color="primary" variant="extended" size="medium" component={Link} to="/my-advert">
                        <Trans>Voeg jouw woning toe</Trans>
                    </Fab>
                </>
            );
        }

        return null;
    }, [profileState]);

    const actions = useMemo(() => {
        if (!profileState || profileState.state < 4 || profileState.state === 7) {
            return null;
        }

        const scrollTo = () => {
            if (goToRef.current) {
                goToRef.current.scrollIntoView({
                    behavior: "smooth",
                    block: "center"
                });
            }
        };

        return (
            <div className={classes.list}>
                <LikeToggle
                    className={classes.button}
                    activeClassName={classes.buttonActive}

                    isLiked={advert.liked}
                    isLikedMe={advert.likedme}
                    id={id}
                />
                <Typography variant="caption">
                    <Trans>Ik heb interesse</Trans>
                </Typography>

                <Slaagkans
                    className={classes.logo}
                    left={advert.match.myDemands.count}
                    right={advert.match.theirDemands.count}
                    onClick={scrollTo}
                />
                <Typography variant="caption" color="primary" component="b" onClick={scrollTo}>
                    <Trans>Wat is de ruilkans?</Trans>
                </Typography>

                <DislikeToggle
                    className={classes.button}
                    activeClassName={classes.buttonActive}

                    active={advert.disliked}
                    id={id}
                />
                <Typography variant="caption">
                    <Trans>Ik heb geen interesse</Trans>
                </Typography>
            </div>
        );

    }, [classes, advert, id, goToRef, profileState]);

    return (
        <div className={classes.root}>
            {message}
            {actions}
        </div>
    );
};
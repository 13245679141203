import { Reducer } from "redux";
import PayloadAction from "types/PayloadAction";
import TMap from "types/TMap";

export type Handlers<S> = TMap<(state: S, action: any) => S>;

export default <S>(handlers: Handlers<S>, initial: S): Reducer<S> => (
    (state: S = initial, action: PayloadAction): S => {
        const {type} = action;

        if (typeof handlers[type] === "function") {
            return handlers[type](state, action);
        }

        return state;
    }
);

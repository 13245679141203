import Chip, {ChipProps} from "@material-ui/core/Chip";
import CloseIcon from "component/icon/Close";
import PlusIcon from "component/icon/Plus";
import React, {MouseEvent, ReactElement} from "react";
import FilterField from "types/FilterField";

type TProps = {
    tag: FilterField;
    isActive: boolean;
    showIcons?: boolean;
    onToggle: (e: MouseEvent) => void;
    deleteIcon: ReactElement;
    icon: ReactElement;
};

const Tag = ({tag, isActive, onToggle, showIcons, deleteIcon, icon}: TProps) => {
    const props: ChipProps = {
        clickable: true,
        variant: "outlined",
        label: tag.name,
        color: isActive ? "primary" : "default",
        onClick: onToggle,
        id: String(tag.id)
    };

    if (showIcons && isActive) {
        props.deleteIcon = deleteIcon;
        props.onDelete = onToggle;
    }

    if (showIcons && !isActive) {
        props.icon = icon;
    }

    return (<Chip {...props} />);
};

Tag.defaultProps = {
    deleteIcon: <CloseIcon />,
    icon: <PlusIcon fontSize="small" />
};

export default Tag;
import React, {useState} from "react";
import {useField, UseFieldConfig} from "react-final-form";
import Switch from "@material-ui/core/Switch";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel, {FormControlLabelProps} from "@material-ui/core/FormControlLabel";
import {Trans} from "react-i18next";
import FieldRequiredAsterisk from "component/FieldRequiredAsterisk";
import {makeStyles} from "@material-ui/core/styles";
import FormHelperText from "@material-ui/core/FormHelperText";
import Link from '@material-ui/core/Link';
import StaticContent from "component/StaticContent";
import PrivacyStatement from "data/static/PrivacyStatement";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

type Props = Omit<FormControlLabelProps, 'control' | 'label'> & {
    name: string;
    fieldProps?: UseFieldConfig<boolean>;
}

const useStyles = makeStyles(({spacing}) => ({
    root: {
        margin: spacing(2, 0, 1, -0.5),
    },
    label: {
        marginLeft: spacing(1)
    }
}));

export default ({name, fieldProps, ...props}: Props) => {
    const [privacyState, setPrivacyState] = useState(false);
    const classes = useStyles();
    const {input, meta} = useField<boolean>(name, {
        ...fieldProps,
        type: 'checkbox'
    });

    const helperText = meta.touched ? (<Trans>{meta.error}</Trans> || meta.submitError) : "";

    const togglePrivacy = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        setPrivacyState(!privacyState)
    }

    const toggleChecked = () => {
        if (!input.checked) {
            setPrivacyState(true);
        }
    }

    const handleAgree = () => {
        input.onChange(true);
        setPrivacyState(false);
    }

    return (
        <>
            <FormControl fullWidth margin="dense" size="small" error={meta.touched && meta.invalid}>
                <FormControlLabel
                    classes={classes}
                    label={(
                        <>
                            <Trans>
                                Akkoord met <Link onClick={togglePrivacy} href="#">privacy statement</Link>
                            </Trans>
                            <FieldRequiredAsterisk/>
                        </>
                    )}
                    control={(
                        <Switch
                            color="primary"
                            {...input}

                            onChange={toggleChecked}
                        />
                    )}

                    {...props}
                />

                {helperText && <FormHelperText>{helperText}</FormHelperText>}
            </FormControl>

            <Dialog scroll="paper" open={privacyState} onClose={togglePrivacy}>
                <DialogContent dividers>
                    <StaticContent>
                        <PrivacyStatement/>
                    </StaticContent>

                    {!input.checked && (
                        <DialogActions>
                            <Button onClick={togglePrivacy} color="primary">
                                Terug
                            </Button>

                            <Button onClick={handleAgree} color="primary">
                                Mee eens
                            </Button>
                        </DialogActions>
                    )}
                </DialogContent>

                {input.checked && (
                    <DialogActions>
                        <Button onClick={togglePrivacy} color="primary">
                            OK
                        </Button>
                    </DialogActions>
                )}
            </Dialog>
        </>
    )
}

import InputLabel from "@material-ui/core/InputLabel";
import Input from "component/form/field/Input";
import React from "react";
import {Form, FormProps, FormRenderProps} from "react-final-form";
import {Trans} from "react-i18next";
import Login from "types/Login";
import {composeRules, makeValidator, password, required} from 'validate';
import FieldRequiredAsterisk from "component/FieldRequiredAsterisk";
import Grid from "@material-ui/core/Grid";
import Submit from "./form/field/Submit";

type Props = FormProps<Login> & {
    title: string
}

const validator = makeValidator<Login>({
    password: composeRules({
        'Verplicht invullen': required,
        'Uw wachtwoord moet minimaal 8 tekens lang zijn, en tenminste 1 hoofdletter, 1 cijfer, en 1 leesteken bevatten': password
    }),
});

export default ({title, ...props}: Props) => (
    <Form validate={validator} initialValues={{ email: ''}} {...props}>
        {({handleSubmit}: FormRenderProps) => (
            <form onSubmit={handleSubmit}>
                <InputLabel>
                    <Trans>Wachtwoord</Trans>
                    <FieldRequiredAsterisk/>
                </InputLabel>
                <Input name="password" type="password"/>
                <Grid container spacing={2}>
                    <Grid item xs>
                        <Submit title={title}/>
                    </Grid>
                </Grid>
            </form>
        )}
    </Form>
);

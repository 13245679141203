import React from "react";
import account from 'assets/accountaanmaken.png';
import beschikbaarheid from 'assets/beschikbaarheid.png'
import persoonsgegevens from 'assets/persoonsgegevens.png'
import zoekprofiel from 'assets/zoekprofiel.png'
import { Link } from "react-router-dom";
import {Link as RouterLink} from "react-router-dom";


export default () =>
(
    <>
        <h1>Hoe maak ik een account aan?</h1>
        <div className="container">
            <div className="row">
                <div className="col">
                    <div className="card"  style={{ border: 'none' }}>
                        <img src={account} className="card-img-top" alt="Card 1" style={{ width: '180px' }} />
                        <div className="card-body"  style={{ marginTop: '-40px',}}>
                            <h5 className="card-title" style={{fontSize: '18px', fontWeight: 'bold' }} >Stap 1. Account aanmaken </h5>
                            <p className="card-text" style={{fontSize: '15px' }} >Via de knop  <Link to="/register">Registreren</Link> maak jij een account aan op ruilmijnwoning.nl. Je kunt eenvoudig inloggen met je account van Google, Facebook of Woonmatch. </p>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="card"  style={{ border: 'none' }}>
                    <img src={beschikbaarheid} className="card-img-top" alt="Card 1" style={{ width: '180px' }} />
                        <div className="card-body"  style={{ marginTop: '-40px' }}>
                            <h5 className="card-title" style={{fontSize: '18px', fontWeight: 'bold' }} >Stap 2. Check op beschikbaarheid</h5>
                            <p className="card-text" style={{fontSize: '15px' }} >Vul de postcode en het huisnummer van jouw huidige huurwoning in en selecteer de woningcorporatie waarvan jij de woning huurt. Klik vervolgens op de button 'Check woningruil'. Je krijgt dan een melding te zien of jouw woning geschikt is voor woningruil. </p>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="card"  style={{ border: 'none' }}>
                    <img src={persoonsgegevens} className="card-img-top" alt="Card 1" style={{ width: '180px' }}/>
                        <div className="card-body"  style={{ marginTop: '-40px' }}>
                            <h5 className="card-title" style={{fontSize: '18px', fontWeight: 'bold' }} >Stap 3. Vul je persoonsgegevens in</h5>
                            <p className="card-text" style={{fontSize: '15px' }} >Met deze gegevens komen de woningen in beeld die voor jou het meest relevant zijn.</p>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="card"  style={{ border: 'none' }}>
                    <img src={zoekprofiel} className="card-img-top" alt="Card 1" style={{ width: '180px' }} />
                        <div className="card-body"  style={{ marginTop: '-40px' }}>
                            <h5 className="card-title" style={{fontSize: '18px', fontWeight: 'bold' }} >Stap 4. Maak je zoekprofiel aan</h5>
                            <p className="card-text" style={{fontSize: '15px' }} >Vul je zoekprofiel zo volledig en zo goed mogelijk in. Op basis hiervan laat ruilmijnwoning.nl de beste woningen voor jou zien.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
       
    </>
)

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React from "react";
import Photo from "types/Photo";

const useStyles = makeStyles(({spacing, shape}: Theme) => createStyles({
    list: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr",
        gridTemplateRows: "1fr 1fr",
        gridRowGap: spacing(3),
        gridColumnGap: spacing(3),
        gridTemplateAreas: "'first first .' 'first first .'",
        alignItems: "stretch",
        justifyItems: "stretch",
    },
    item: {
        width: "100%",
        objectFit: "cover",
        borderRadius: shape.borderRadius,
        "&:first-child": {
            gridArea: "first",
        },
    },
}));

interface IProps {
    photos: Photo[]
}

export default ({photos}: IProps) => {
    const classes = useStyles();

    return (
        <div className={classes.list}>
            {photos.map((photo, index) => (
                <img
                    alt={`${index}`}
                    key={index}
                    src={photo.link}
                    className={classes.item}
                />
            ))}
        </div>
    );
}
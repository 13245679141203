import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import ProfileForm from "component/form/Profile";
import Loader from "component/Loader";
import SmallDesktopContainer from "component/SmallDesktopContainer";
import { FORM_ERROR } from "final-form";
import { Endpoint } from "lib/api";
import { useApi } from "lib/useApi";
import React from "react";
import { Trans } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setProfile } from "redux/action";
import { getProfile } from "redux/selector";
import Profile from "types/Profile";
import { useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";

export default () => {
    const { push } = useHistory();
    const profile = useSelector(getProfile);
    const dispatch = useDispatch();
    const [, load] = useApi<Profile | null>(Endpoint.profile, {
        initialData: null
    });

    if (!profile) {
        return <Loader />;
    }

    const onSubmit = async (values: Profile) => {
        try {
            let birthdate = values.person.birthdate;
            if (birthdate && birthdate.toFormat) {
                // @ts-ignore
                birthdate = birthdate.toFormat("yyyy-LL-dd");
            }

            const response = await load({
                method: "POST",
                data: {
                    contact: values.contact,
                    person: {
                        ...values.person,
                        birthdate,
                    },
                    partner: values.partner
                }
            });

            if (response) {
                dispatch(setProfile(response));
            }

            push("/");
        } catch (e) {
            return {
                // @ts-ignore
                [FORM_ERROR]: e.message
            };
        }
    };

    return (
        <SmallDesktopContainer>
            <Box textAlign="center" pt={2} pb={2}>
                <Typography variant="h1" gutterBottom>
                    <Trans>Accountgegevens</Trans>
                </Typography>
            </Box>

            <Typography variant="h2">
                <Trans>Huidige woning</Trans>
            </Typography>
            <Box pt={2} pb={2}>
                <Grid container spacing={1}>
                    <Grid item xs={8}>
                        <InputLabel><Trans>Straat</Trans></InputLabel>
                        <TextField
                            disabled
                            fullWidth
                            margin="dense"
                            size="small"
                            variant="outlined"
                            value={profile.address.street}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <InputLabel><Trans>Huisnummer</Trans></InputLabel>
                        <TextField
                            disabled
                            fullWidth
                            margin="dense"
                            size="small"
                            variant="outlined"
                            value={profile.address.number}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <InputLabel><Trans>Postcode</Trans></InputLabel>
                        <TextField
                            disabled
                            fullWidth
                            margin="dense"
                            size="small"
                            variant="outlined"
                            value={profile.address.zipcode}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <InputLabel><Trans>Plaats</Trans></InputLabel>
                        <TextField
                            disabled
                            fullWidth
                            margin="dense"
                            size="small"
                            variant="outlined"
                            value={profile.address.city}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel><Trans>Verhuurder</Trans></InputLabel>
                        <TextField
                            disabled
                            fullWidth
                            margin="dense"
                            size="small"
                            variant="outlined"
                            value={profile.corporation.name}
                        />
                    </Grid>
                </Grid>
            </Box>

            <Typography variant="h2">
                <Trans>Persoonlijk</Trans>
            </Typography>
            <ProfileForm
                withDeleteAction
                title="Wijzigingen opslaan"
                onSubmit={onSubmit}
                initialValues={profile}
            />
        </SmallDesktopContainer>
    );
};

import { Theme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import PhotoCarousel from "component/PhotoCarousel";
import PhotoGrid from "component/PhotoGrid";
import React, { useMemo } from "react";
import FullAdvert from "types/FullAdvert";

type Props = {
  advert: FullAdvert;
};

const groupIndex = {
  "Buitenkant woning": 0,
  "Woonkamer en keuken": 1,
  Slaapkamer: 2,
  Toilet: 3,
  Badkamer: 4,
  Overig: 5,
};
export default ({ advert: { photos } }: Props) => {
  const items = useMemo(() => {
    return photos.sort(
      ({ room: lRoom, order: lOrder }, { room: rRoom, order: rOrder }) => {
        if (lRoom === rRoom) {
          return lOrder - rOrder;
        }

        // @ts-ignore
        return groupIndex[lRoom] - groupIndex[rRoom];
      }
    );
  }, [photos]);
  const upSm = useMediaQuery(({ breakpoints }: Theme) => breakpoints.up("sm"));

  if (upSm) {
    return <PhotoGrid photos={items} />;
  }

  return <PhotoCarousel photos={items} />;
};

import React from "react";

export default () =>
    (
        <>
            <h1>Interesse in een woning tonen</h1>
            <p>
                Je hebt nu je account aangemaakt en je eigen woning geadverteerd. Vanaf nu kun je kenbaar maken of je
                een woning leuk vindt of juist niet. Dit doe je door button aan te klikken: ‘Vind ik leuk’ en ‘Vind ik
                niet leuk’.
            </p>
            <p>
                De lijst met woningadvertenties is gesorteerd op volgorde van jouw woonwensen die je in je zoekprofiel
                hebt aangegeven. De best passende woningen staan bovenaan. Zie je een interessante advertentie en wil je
                meer weten? Klik op de advertentie om de details van de advertentie te bekijken. Je kunt de kenmerken,
                foto’s, omschrijving en locatie van de woning bekijken. Is dit een mogelijk ‘droomhuis’? Klik op het
                duimpje omhoog. Hiermee maak je kenbaar dat je geïnteresseerd bent in de woning. Het duimpje wordt nu
                blauw.
            </p>

            <p>
                Als je op de knop ‘liken & bericht sturen’ drukt, stuur je via ruilmijnwoning.nl een bericht naar de
                aanbieder. Je kunt ervoor kiezen het standaard bericht te sturen of een persoonlijk bericht te sturen.
                De aanbieder kan vervolgens jouw woning gaan bekijken.
            </p>
            <p>
                Heb je je vergist of bedacht en wil je de ‘vind ik leuk’ weer ongedaan maken? Geen probleem! Je kunt
                jouw ‘like’ altijd weer intrekken door deze weer uit te klikken. De aanbieder krijgt automatisch bericht
                dat de interesse in zijn of haar woning is ingetrokken.
            </p>
            <p>
                Andersom geldt natuurlijk hetzelfde: Iemand anders kan ook interesse in jouw woning hebben. Je ontvangt
                hierover automatisch bericht. In het bericht staat een link naar de woning van degene die interesse in
                jouw woning heeft. Jij kunt deze woning bekijken. Geïnteresseerd in de woning? Dan geef jij deze woning
                een duimpje omhoog.
            </p>

            <p>
                <i>Je kunt meerdere woning tegelijk liken. Je vindt alle woningen waarin je interesse hebt terug in het
                    menu onder Likes & Favorieten.</i>
            </p>
            <h3>Geen interesse in de aangeboden woning</h3>
            <p>
                Vindt iemand jouw woning leuk, maar wil jij jouw woning niet ruilen met deze woning? Dan kun je deze
                woning ‘disliken’. Dit doe je door op de knop ‘niet interessant’ te klikken.
            </p>
            <p>
                Er wordt automatisch een bericht gestuurd naar degene die interesse in jouw woning heeft, dat jij liever
                niet wil ruilen. Zo is de andere persoon snel op de hoogte.
            </p>
        </>
    )

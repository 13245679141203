import SvgIcon, {SvgIconProps} from "@material-ui/core/SvgIcon";
import getMatchColor from "lib/getMatchColor";
import React from "react";

type Props = SvgIconProps & {
    left?: number;
    right?: number;
}

export default ({left, right, ...props}: Props) => (
    <SvgIcon {...props} viewBox="0 0 36 24">
        <path
            d="M9.142 23.99H19.65c.42 0 .759-.34.759-.76V19.7a.759.759 0 00-.759-.759h-4.715a.759.759 0 01-.759-.759v-6.055c0-.419.34-.758.759-.758h4.715c.42 0 .759-.34.759-.759V7.079a.759.759 0 00-.759-.759H9.142a.759.759 0 00-.54.226L.627 14.622a.759.759 0 000 1.066l7.977 8.076a.759.759 0 00.54.225z"
            fill={getMatchColor(left)}
        />
        <path
            d="M35.205 9.377l-7.976 8.076a.759.759 0 01-.54.226H16.181a.759.759 0 01-.758-.759v-3.53c0-.42.34-.76.758-.76h4.716c.419 0 .758-.339.758-.758V5.817a.759.759 0 00-.758-.759H16.18a.759.759 0 01-.758-.758V.768c0-.418.34-.758.758-.758H26.69c.203 0 .398.081.54.225l7.976 8.076a.759.759 0 010 1.066z"
            fill={getMatchColor(right)}
        />
    </SvgIcon>
);
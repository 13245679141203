import Box from "@material-ui/core/Box";
import React from "react";
import RegisterBox from "../component/RegisterBox";

export default () => {
    return (
        <Box pt={2}>
            <RegisterBox
                title="Registreren bij Ruilmijnwoning.nl"
                description="Met je e-mailadres maak je een eigen account aan op Ruilmijnwoning.nl. Bedenk een wachtwoord en je kunt je registreren."
            />
        </Box>
    );
};

import Box from "@material-ui/core/Box";
import Grid from '@material-ui/core/Grid';
import FAQ from "component/icon/FAQ";
import React from "react";
import FaqQuestions from "../component/FaqQuestions";

export default () => {
    return (
        <>
            <h1>
                Veelgestelde vragen
            </h1>
            <Grid container spacing={3}>
                <Grid item
                    container
                    direction="column"
                    justify="center"
                    alignItems="center" xs={3}>
                    <Box pt={2}>
                        <FAQ fontSize='large' />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={9}>
                    <Box pt={2}>
                        <p>
                            Hieronder vindt u antwoorden op de meest gestelde vragen. In het veld hieronder kunt u een zoekwoord intikken. De lijst met vragen wordt dan automatisch gefilterd op antwoorden waarin de ingevulde tekst voor komt.
                        </p>
                        <p>
                            Als u het antwoord op uw vraag niet vindt kunt u een e-mail sturen naar <a href="mailto:helpdesk@ruilmijnwoning.nl">helpdesk@ruilmijnwoning.nl</a>.
                        </p>
                        <p>
                            Vermeld in die e-mail niet alleen uw vraag maar ook uw naam en adres. Wij nemen dan uiterlijk binnen 2 werkdagen contact met u op.
                        </p>
                    </Box>
                </Grid>
            </Grid>
            <Box pt={2}>
                <FaqQuestions></FaqQuestions>
            </Box>
        </>
    );
};

import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";
import React from "react";

export default (props: SvgIconProps) => (
    <SvgIcon {...props} viewBox="0 0 24 16">
        <path
            d="M14 13.833c.473 0 .857.373.857.834 0 .422-.322.77-.74.825L14 15.5H.857A.845.845 0 010 14.667c0-.422.322-.77.74-.826l.117-.008H14zm9.143-6.666c.473 0 .857.373.857.833 0 .422-.322.77-.74.826l-.117.007H.857A.845.845 0 010 8c0-.422.322-.77.74-.826l.117-.007h22.286zM18.57.5c.474 0 .858.373.858.833 0 .422-.323.77-.741.826l-.117.008H.857A.845.845 0 010 1.333C0 .911.322.563.74.508L.858.5h17.714z"
            fill="#000"
            fillRule="nonzero"
        />
    </SvgIcon>
);
import {SagaIterator} from "@redux-saga/core";
import {fork, put, select, takeLatest} from "@redux-saga/core/effects";
import {Endpoint, makeRequest, RequestParams} from "lib/api";
import {isLoggedIn} from "lib/auth";
import {setAdverts, setAuthState, setFilterData, setProfile, setProfileState} from "redux/action";
import {LOAD_MORE_ADVERTS, SET_AUTH_STATE} from "redux/constant";
import {getAdvertsState, getLoginState} from "redux/selector";
import AdvertsResponse from "types/AdvertsResponse";
import FilterData from "types/FilterData";
import Profile from "types/Profile";
import ProfileState from "types/ProfileState";

function* loadMoreAdverts() {
    const {start, limit, filters, ...state} = yield select(getAdvertsState);

    const params: RequestParams = {
        query: new URLSearchParams({start, limit})
    };

    if (filters) {
        params.method = "POST";
        params.data = filters;
    }

    const {count, adverts = []} = yield makeRequest<AdvertsResponse>(Endpoint.advert, params);

    yield put(setAdverts(count, [...state.list, ...adverts]));
}

function* syncAuthState() {
    const authState = yield isLoggedIn();

    yield put(setAuthState(authState));
}

function* loadProfileState() {
    const state = yield makeRequest<ProfileState>(Endpoint.profileState);

    yield put(setProfileState(state));
}

function* loadProfile() {
    const profile = yield makeRequest<Profile>(Endpoint.profile);

    yield put(setProfile(profile));
}

function* syncUserProfile() {
    if (yield select(getLoginState)) {
        yield fork(loadProfileState);
        yield fork(loadProfile);
    }
}

function* loadFilterData() {
    const data = yield makeRequest<FilterData>(Endpoint.filter);

    yield put(setFilterData(data));
}

export default function* (): SagaIterator {
    yield fork(loadFilterData);
    yield fork(syncAuthState);

    yield takeLatest(SET_AUTH_STATE, syncUserProfile);

    yield takeLatest(LOAD_MORE_ADVERTS, loadMoreAdverts);
}
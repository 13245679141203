import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import Slider, {SliderProps} from "@material-ui/core/Slider";
import Typography from "@material-ui/core/Typography";
import React, {ChangeEvent} from "react";
import {useField} from "react-final-form";
import {Trans} from "react-i18next";

type Props = SliderProps & {
  name: string;
  min: number;
  max: number;
}

export default ( {name, ...props}: Props ) => {
  const {input: {value, ...input}} = useField<number>( name );

  const onChange = ( e: ChangeEvent<{}>, value: number | number[] ) => {
    input.onChange( value );
  };

  React.useLayoutEffect( () => {
    if ( !value ) {
      input.onChange( props.min );
    }
  }, [value, props, input] );

  return (
      <FormControl fullWidth margin="dense" size="small">
        <Typography variant="body2">
          <Trans values={{value: value || props.min}} children="{{value}} km" />
        </Typography>
        <Box pl={1.25} pr={1.25}>
          <Slider
              {...props}
              {...input}

              value={value || props.min}

              onChange={onChange}
          />
        </Box>
      </FormControl>
  );
}
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { Trans } from "react-i18next";
import FullAdvert from "types/FullAdvert";

interface IProps {
    advert: FullAdvert
}

export default ({advert: {description}}: IProps) => (
    <Box pt={5}>
        <Typography variant="h2" paragraph>
            <Trans>Omschrijving</Trans>
        </Typography>

        <Typography gutterBottom>{description}</Typography>

        {/*<Typography color="primary" component="b">*/}
        {/*    <Trans>Lees de volledige omschrijving</Trans>*/}
        {/*</Typography>*/}
    </Box>
)
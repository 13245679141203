import Box from "@material-ui/core/Box";
import React from "react";
import {Trans} from "react-i18next";
import Button from "@material-ui/core/Button";
import {useApi} from "../lib/useApi";
import {Endpoint} from "../lib/api";

export default () => {

    const [, load] = useApi<null>( Endpoint.support, {
        initialData: null
    } );

    const callSupport = () => {
        load();
        alert('Support aangevraagd!')
    }

    return (
        <Box pt={2}>
            <h1>
                Verzoek voor assistentie
            </h1>
            <p>
                U heeft contact gehad met onze helpdesk. Door op de onderstaande knop te klikken geeft u onze support medewerkers toegang tot uw gegevens en kunnen we u actief ondersteunen.
            </p>
            <Button variant="contained" color="primary" onClick={callSupport}>
                <Trans>Ik wil graag assistentie en geef toegang tot mijn gegevens</Trans>
            </Button>
        </Box>
    );
};

import Fab from "@material-ui/core/Fab";
import ThumbDownActive from "component/icon/ThumbDownActive";
import {Endpoint} from "lib/api";
import {useApi} from "lib/useApi";
import useToggle from "lib/useToggle";
import React from "react";
import {LikesType} from "types/LikesType";

type Props = {
    id: string;
    active: 0 | 1;
    className?: string;
    activeClassName?: string;
}

export default ({id, active, className, activeClassName}: Props) => {
    const [state, toggle] = useToggle(Boolean(active));
    const [{loading}, sendRequest] = useApi<string>(Endpoint.like, {
        params: {type: LikesType.dislike},
        initialData: ""
    });

    const onUnlike = async () => {
        const resp = await sendRequest({
            method: "POST",
            data: {advert: id}
        });

        if (resp === "ok") {
            toggle();
        }
    };

    if (state) {
        return (
            <Fab size="small" className={activeClassName}>
                <ThumbDownActive />
            </Fab>
        );
    }

    return (
        <Fab disabled={loading} size="small" className={className} onClick={onUnlike}>
            <ThumbDownActive />
        </Fab>
    );
};
import MobileStepper from "@material-ui/core/MobileStepper";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import React, {useReducer} from "react";
import {useSwipeable} from "react-swipeable";
import Photo from "types/Photo";

enum ACTION {
    NEXT = "NEXT",
    PREV = "PREV",
    STOP = "STOP",
}

type Props = {
    photos: Photo[]
}

type State = {
    numItems: number;
    nextPosition?: number;
    position: number;
    sliding: boolean;
    dir: ACTION.NEXT | ACTION.PREV;
}

type Action = {
    type: ACTION;
}

const useStyles = makeStyles(({shape, spacing}: Theme) => createStyles({
    wrapper: {
        width: "100%",
        overflow: "hidden",
        position: "relative"
    },
    container: {
        display: "flex",
        transform: "translateX(-100%)"
    },
    sliding: {
        transition: "transform 1s ease"
    },
    slideNext: {
        transform: "translateX(-200%)"
    },
    slidePrev: {
        transform: "translateX(0%)"
    },
    item: {
        flex: "1 0 100%",
        borderRadius: shape.borderRadius,
        paddingBottom: "56.2%",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat"
    },
    count: {
        position: "absolute",
        right: spacing(),
        bottom: spacing(4),
        background: "#ffffff80",
        borderRadius: shape.borderRadius,
        padding: spacing(.5)
    }
}));

function reducer(state: State, {type}: Action): State {
    const {position, numItems, nextPosition} = state;
    switch (type) {
        case ACTION.PREV:
            return {
                ...state,
                dir: ACTION.PREV,
                sliding: true,
                nextPosition: (position + numItems - 1) % numItems
            };
        case ACTION.NEXT:
            return {
                ...state,
                dir: ACTION.NEXT,
                sliding: true,
                nextPosition: (position + 1) % numItems
            };
        case ACTION.STOP:
            return {
                ...state,
                position: nextPosition || 0,
                sliding: false
            };
        default:
            return state;
    }
}

export default ({photos}: Props) => {
    const [state, dispatch] = useReducer(reducer, {
        position: 0,
        sliding: false,
        dir: ACTION.NEXT,
        numItems: photos.length
    });

    const slide = (dir: ACTION.NEXT | ACTION.PREV) => {
        dispatch({type: dir});

        setTimeout(() => dispatch({type: ACTION.STOP}), 900);
    };

    const handlers = useSwipeable({
        onSwipedLeft: () => slide(ACTION.NEXT),
        onSwipedRight: () => slide(ACTION.PREV),
        preventDefaultTouchmoveEvent: true,
        trackMouse: true
    });

    const renderPhoto = (index: number, key: string) => {
        const photo = photos[index];

        return (
            <div
                key={key}
                className={classes.item}
                style={{
                    backgroundImage: `url(${photo.link})`
                }}
            />
        );
    };

    const classes = useStyles();

    const containerClass = clsx(classes.container, {
        [classes.sliding]: state.sliding,
        [classes.slideNext]: state.sliding && state.dir === ACTION.NEXT,
        [classes.slidePrev]: state.sliding && state.dir === ACTION.PREV
    });

    return (
        <div {...handlers} className={classes.wrapper}>
            <div className={containerClass}>
                {renderPhoto((state.position + state.numItems - 1) % state.numItems, "prev")}
                {renderPhoto(state.position, "current")}
                {renderPhoto((state.position + 1) % state.numItems, "next")}
            </div>

            <MobileStepper
                variant="dots"
                steps={state.numItems}
                position="static"
                activeStep={state.position}
                nextButton={<span />}
                backButton={<span />}
            />

            <Typography variant="caption" color="textSecondary" component="b" className={classes.count}>
                {state.position + 1} / {state.numItems}
            </Typography>
        </div>
    );
};
import Box from "@material-ui/core/Box";
import Input from "component/form/field/Input";
import Submit from "component/form/field/Submit";
import React from "react";
import {Field, Form, FormProps, FormRenderProps} from "react-final-form";

export default (props: FormProps<{message: string; advert: string;}>) => (
    <Form {...props}>
        {({handleSubmit}: FormRenderProps) => (
            <form onSubmit={handleSubmit}>
                <Field type="hidden" name="advert" component="input" />

                <Box mb={1} borderRadius="borderRadius" bgcolor="primary.light" textAlign="left">
                    <Input name="message" margin="none" multiline />
                </Box>

                <Submit title="Verstuur bericht & like" />
            </form>
        )}
    </Form>
);
import {oAuthCallback} from "lib/auth";
import {useLayoutEffect} from "react";
import {useDispatch} from "react-redux";
import {useHistory, useParams} from "react-router-dom";
import {setAuthState} from "redux/action";
import {AuthProvider} from "types/AuthProvider";

const {location} = window;
type Params = {
    provider: AuthProvider;
}

export default () => {
    const {replace} = useHistory();
    const dispatch = useDispatch();
    const {provider} = useParams<Params>();

    useLayoutEffect(() => {
        (async () => {
            const query = new URLSearchParams(location.search);

            try {
                const redirectUrl = await oAuthCallback(provider as AuthProvider, {
                    state: query.get("state") || '',
                    code: query.get("code") || '',
                    token: query.get("token") || '',
                });

                dispatch(setAuthState(true));
                replace(redirectUrl);
            } catch (e) {
                replace('/')
            }
        })();
    }, [provider, dispatch, replace]);

    return null;
};

import {
    SET_ADVERTS,
    SET_ADVERTS_FILTER,
    SET_AUTH_STATE,
    SET_FILTER_DATA,
    SET_PROFILE,
    SET_PROFILE_STATE,
    TOGGLE_FAVORITE_STATE
} from "redux/constant";
import Advert from "types/Advert";
import SetAdvertsAction from "types/SetAdvertsAction";
import SetAdvertsFilterAction from "types/SetAdvertsFilterAction";
import SetAuthStateAction from "types/SetAuthStateAction";
import SetFilterDataAction from "types/SetFilterDataAction";
import SetProfileAction from "types/SetProfileAction";
import SetProfileStateAction from "types/SetProfileStateAction";
import ToggleFavoriteAction from "types/ToggleFavoriteAction";

export const setAdverts = (count: number, adverts: Advert[]): SetAdvertsAction => ({
    type: SET_ADVERTS,
    payload: {
        count,
        adverts
    }
});

export const setAdvertsFilter = (payload: SetAdvertsFilterAction["payload"]): SetAdvertsFilterAction => ({
    type: SET_ADVERTS_FILTER,
    payload
});

export const setAuthState = (payload: SetAuthStateAction["payload"]): SetAuthStateAction => ({
    type: SET_AUTH_STATE,
    payload
});

export const setProfile = (payload: SetProfileAction["payload"]): SetProfileAction => ({
    type: SET_PROFILE,
    payload
});

export const setProfileState = (payload: SetProfileStateAction["payload"]): SetProfileStateAction => ({
    type: SET_PROFILE_STATE,
    payload
});

export const toggleFavorite = (payload: ToggleFavoriteAction["payload"]): ToggleFavoriteAction => ({
    type: TOGGLE_FAVORITE_STATE,
    payload
});

export const setFilterData = (payload: SetFilterDataAction["payload"]): SetFilterDataAction => ({
    type: SET_FILTER_DATA,
    payload
});
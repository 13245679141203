import InputLabel from "@material-ui/core/InputLabel";
import Grid from "@material-ui/core/Grid";
import DatePicker from "component/form/field/DatePicker";
import Input from "component/form/field/Input";
import MinMax from "component/form/field/MinMax";
import PrivacyCheckbox from "component/form/field/PrivacyCheckbox";
import Submit from "component/form/field/Submit";
import React, { useState } from "react";
import { Form, FormProps, FormRenderProps } from "react-final-form";
import { Trans } from "react-i18next";
import { useSelector } from "react-redux";
import { getProfile } from "redux/selector";
import Profile from "types/Profile";
import { DateTime } from "luxon";
import { composeRules, date, makeValidator, number, required } from 'validate';
import FieldRequiredAsterisk from "component/FieldRequiredAsterisk";
import DeleteAccount from "component/action/DeleteAccount";
import { Box } from "@material-ui/core";
import Fab from "@material-ui/core/Fab";
import YesNo from "./field/YesNo";

type Props = FormProps<Profile> & {
    title: string;
    withDeleteAction?: boolean
}

const validator = makeValidator<Profile>({
    person: makeValidator<Profile['person']>({
        firstname: composeRules({
            'Verplicht invullen': required
        }),
        lastname: composeRules({
            'Verplicht invullen': required
        }),
        birthdate: composeRules({
            'Verplicht invullen': (value) => {
                // @ts-ignore
                return date(value) || DateTime.fromISO(value as string).isValid
            }
        }),
        jointIncome: composeRules({
            'Verplicht invullen': (value) => {
                return !!value && (number(value) || number(parseFloat(value)));
            }
        }),
        familySize: composeRules({
            'Verplicht invullen': number
        }),
        privacy: composeRules({
            'Verplicht invullen': (v) => v,
        }),
    }),
    contact: makeValidator<Profile['contact']>({
        phone: composeRules({
            'Verplicht invullen': required
        }),
        preferences: composeRules({
            'Verplicht invullen': required
        }),
    }),
});

export default ({ title, withDeleteAction, ...props }: Props) => {
    const profile = useSelector(getProfile)
    const currentFamilySize = profile?.person.familySize;
    const [familySize, setFamilySize] = useState<number | undefined>(currentFamilySize ? parseInt(currentFamilySize) : undefined);
    const [hasPartner, setHasPartner] = useState(profile?.partner.hasPartner ?? false);
    const [sameAddress, setSameAddress] = useState(profile?.partner.sameAddress ?? false);

    return (
        <Form
            validate={validator}
            initialValues={useSelector(getProfile)}
            mutators={{
                setValue: ([field, value], state, { changeValue }) => {
                    changeValue(state, field, () => value)
                }
            }}
            {...props}>
            {({ handleSubmit, form }: FormRenderProps) => {

                return (
                    <form onSubmit={handleSubmit}>
                        <InputLabel>
                            <Trans>Voornaam</Trans>
                            <FieldRequiredAsterisk />
                        </InputLabel>
                        <Input name="person.firstname" />

                        <InputLabel>
                            <Trans>Achternaam</Trans>
                            <FieldRequiredAsterisk />
                        </InputLabel>
                        <Input name="person.lastname" />

                        <InputLabel>
                            <Trans>Geboortedatum</Trans>
                            <FieldRequiredAsterisk />
                        </InputLabel>
                        <DatePicker name="person.birthdate" disableFuture />

                        <InputLabel>
                            <Trans>Telefoonnummer</Trans>
                            <FieldRequiredAsterisk />
                        </InputLabel>
                        <Input name="contact.phone" type="tel" />

                        <InputLabel>
                            <Trans>E-mail</Trans>
                        </InputLabel>
                        <Input
                            name="contact.email"
                            type="email"
                            disabled={true}
                            helperText="U kunt uw e-mail niet aanpassen"
                        />

                        <InputLabel>
                            <Trans>Gezamenlijk bruto jaarinkomen van jou en je partner</Trans>
                            <FieldRequiredAsterisk />
                        </InputLabel>
                        <Input
                            name="person.jointIncome"
                            helperText="Vul hier het verzamelinkomen in van jezelf, jouw partner en eventueel medebewoners. Het inkomen van inwonende kinderen (minderjarig en meerderjarig) hoe je niet mee te tellen"
                        />

                        <InputLabel>
                            <Trans>Hoeveel leden telt je gezin?</Trans>
                            <FieldRequiredAsterisk />
                        </InputLabel>
                        <MinMax
                            name="person.familySize"
                            formatName={item => `${item}${item === 5 ? "+" : ""}`}
                            min={1}
                            max={5}
                            onChange={(value) => {
                                setFamilySize(value)
                                if (value === 1) {
                                    form.mutators.setValue('partner.hasPartner', false)
                                    form.mutators.setValue('partner.sameAddress', false)
                                    setHasPartner(false);
                                    setSameAddress(false)
                                }
                            }}
                        />

                        {familySize && familySize >= 2 &&
                            <>
                                <InputLabel>
                                    <Trans>Is een van de meerverhuizende personen jouw partner?</Trans>
                                    <FieldRequiredAsterisk />
                                </InputLabel>
                                <YesNo
                                    initialValue={hasPartner === true}
                                    onChange={(value) => {
                                        setHasPartner(value)
                                        form.mutators.setValue('partner.hasPartner', value)
                                    }}
                                    name="partner.hasPartner"
                                    helperText={hasPartner === true ? "Heb je het inkomen van jouw partner hierboven meegeteld bij jullie gezamelijke bruto jaarinkomen? Zo nee, doe dat dan alsnog." : ""}
                                />

                                {hasPartner === true &&
                                    <>
                                        <InputLabel>
                                            <Trans>Staat jouw partner nu ingeschreven op het adres van de huidige woning hierboven?</Trans>
                                            <FieldRequiredAsterisk />
                                        </InputLabel>
                                        <YesNo
                                            initialValue={sameAddress === true}
                                            onChange={(value) => {
                                                setSameAddress(value)
                                                form.mutators.setValue('partner.sameAddress', value)
                                            }}
                                            name="partner.sameAddress"
                                        />
                                    </>
                                }
                            </>
                        }


                        {/*<InputLabel>
                            <Trans>Andere huurders mogen mij</Trans>
                            <FieldRequiredAsterisk />
                        </InputLabel>
                        <ContactPreference
                            name="contact.preferences"
                            multiple
                        />*/}

                        <PrivacyCheckbox name="person.privacy" />

                        <Grid container spacing={2}>
                            <Grid item xs>
                                <Submit title={title} />
                            </Grid>
                        </Grid>
                        <Box paddingTop="20px" paddingBottom="10px" marginBottom="20px" textAlign="center">
                            <Fab color="primary" variant="extended" size="medium" onClick={event => window.location.href = '/change-password'}>
                                <Trans>Wachtwoord Aanpassen</Trans>
                            </Fab>
                        </Box>

                        <Box textAlign="center">
                            {withDeleteAction && (
                                <DeleteAccount />
                            )}
                        </Box>
                    </form>
                )
            }}
        </Form>
    );
}

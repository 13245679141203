import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import FavoriteToggle from "component/action/FavoriteToggle";
import AdvertDescription from "component/AdvertDescription";
import AdvertLike from "component/AdvertLike";
import AdvertLocation from "component/AdvertLocation";
import AdvertMatch from "component/AdvertMatch";
import AdvertOwner from "component/AdvertOwner";
import AdvertPhotos from "component/AdvertPhotos";
import AdvertProperties from "component/AdvertProperties";
import AdvertRelated from "component/AdvertRelated";
import AdvertShare from "component/AdvertShare";
import AdvertViewedMessage from "component/AdvertViewedMessage";
import Labels from "component/Labels";
import React, { useRef } from "react";
import { Trans } from "react-i18next";
import { useSelector } from "react-redux";
import { getLoginState } from "redux/selector";
import FullAdvert from "types/FullAdvert";
import FittingWarning from "./FittingWarning";
import HouseholdWarning from "./HouseholdWarning";
import AgeWarning from "./AgeWarning";
import { Alert } from "@material-ui/lab";
import Box from "@material-ui/core/Box";

type Props = {
  isPreview?: boolean;
  advert: FullAdvert;
  id: string;
};

const useStyles = makeStyles(({ spacing, breakpoints, palette }: Theme) =>
  createStyles({
    title: {
      position: "relative",
      marginTop: spacing(2),
      marginBottom: spacing(3),

      [breakpoints.up("sm")]: {
        textAlign: "center",
        marginTop: spacing(4),
        marginBottom: spacing(4),
      },
    },
    previewBanner: {
      padding: spacing(2),
      background: "#210f78",
      textAlign: "center",
      color: palette.common.white,
      marginLeft: spacing(-3),
      marginRight: spacing(-3),
    },
    favoriteToggle: {
      position: "absolute",
      bottom: -3,
      right: 0,
    },
    "@global": {
      "#root": {
        overflow: "hidden",
      },
    },
  })
);

export default ({ advert, id, isPreview }: Props) => {
  const loggedIn = useSelector(getLoginState);
  const classes = useStyles();
  const matchRef = useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if ("#ruilkans" === window.location.hash && matchRef.current) {
      matchRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, []);

  const { labels, address, favorited } = advert;
  return (
    <>
      {!address.street && console.log("Not Found")}

      {isPreview && (
        <div className={classes.previewBanner}>
          <Trans>
            Zo komt je advertentie er uit te zien. Tevreden? Klik dan onderaan
            op plaatsen om jouw woning te adverteren.
          </Trans>
        </div>
      )}
      <div className={classes.title}>
        <Labels labels={labels} />

        <Typography variant="h1" color="textSecondary">
          {address.street}
        </Typography>
        <Typography variant="body1">
          <Trans
            values={{
              city: address.city,
              neighborhood: address.neighborhood,
            }}
            children="in {{city}} ({{neighborhood}})"
          />
        </Typography>

        {!isPreview && loggedIn && (
          <Typography variant="body1" className={classes.favoriteToggle}>
            <Hidden smDown>
              <Trans>Bewaar woning</Trans>&nbsp;
            </Hidden>
            <FavoriteToggle active={favorited} color="secondary" advert={id} />
          </Typography>
        )}
      </div>

      {!isPreview && loggedIn && (
        <AdvertLike id={id} advert={advert} goToRef={matchRef} />
      )}

      {advert.photos.length > 0 && <AdvertPhotos advert={advert} />}

      {!advert?.photos?.length && (
        <div className="p-2 border border-yellow-500 bg-yellow-100 rounded">
          Geen foto's beschikbaar
        </div>
      )}

      <AdvertViewedMessage advert={advert} />

      <Grid container>
        <Grid item xs={12} md={4}>
          <AdvertProperties advert={advert} />
        </Grid>

        <Grid item xs={12} md={8}>
          <AdvertDescription advert={advert} />

          {!isPreview && advert.isFitting === 0 && (
            <FittingWarning isFitting={advert.isFitting} />
          )}
          {!isPreview && advert.householdsize === 0 && (
            <HouseholdWarning householdsize={advert.householdsize} />
          )}
          {!isPreview && advert.age === 0 && <AgeWarning age={advert.age} />}
          {!isPreview &&
            advert.landlord.payment !== undefined &&
            advert.landlord.payment.required && (
              <Box mb={1}>
                <Alert severity="info">
                  <Trans>
                    <strong>Ter informatie:</strong> Deze verhuurder brengt
                    verwerkingskosten in rekening.
                    <br />
                    <br />
                    {advert.landlord.name} brengt &euro;{" "}
                    {advert.landlord.payment.amount || "0.00"} verwerkingskosten
                    in rekening bij de nieuwe huurder.
                    <br />
                    Dit bedrag betaal je pas via Ruilmijnwoning.nl als jouw ruil
                    door jouw eigen verhuurder én jouw nieuwe verhuurder is
                    goedgekeurd. Je krijgt hiervoor dan een e-mail met een
                    betaalverzoek.
                  </Trans>
                </Alert>
              </Box>
            )}
          {isPreview &&
            advert.landlord.payment !== undefined &&
            advert.landlord.payment.required && (
              <Box mb={1}>
                <Alert severity="info">
                  <Trans>
                    <strong>Ter informatie:</strong> Jouw verhuurder brengt
                    verwerkingskosten in rekening.
                    <br />
                    <br />
                    {advert.landlord.name} brengt &euro;{" "}
                    {advert.landlord.payment.amount || "0.00"} verwerkingskosten
                    in rekening bij de nieuwe huurder.
                    <br />
                    Een ruiler die met jou ruilt zal deze kosten via
                    ruilmijnwoning.nl moeten betalen als de ruil door beide
                    verhuurders goedgekeurd is. Je betaalt zelf niets aan jouw
                    eigen verhuurder, maar jouw nieuwe verhuurder kan mogelijk
                    ook kosten in rekening brengen. Als dat het geval is dan zie
                    je dat bij de advertentie.
                  </Trans>
                </Alert>
              </Box>
            )}
          {!isPreview && <AdvertMatch ref={matchRef} advert={advert} />}

          <AdvertLocation advert={advert} />

          <Grid container alignItems="flex-end">
            <Grid item xs={12} sm>
              <AdvertOwner advert={advert} />
            </Grid>
            {!isPreview && (
              <Grid item xs={12} sm="auto">
                <AdvertShare />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>

      {!isPreview && <AdvertRelated />}
    </>
  );
};

import Container from "@material-ui/core/Container";
import Link from "@material-ui/core/Link";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ArrowUpIcon from "component/icon/ArrowUp";
import Logo from "component/icon/Logo";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {Trans} from "react-i18next";
import {Link as NavLink} from "react-router-dom";
import {StaticPages} from "types/StaticPages";
import clsx from "clsx";

const useStyles = makeStyles(({spacing, breakpoints, palette}: Theme) => createStyles({
    root: {
        marginTop: spacing(6),
        paddingTop: spacing(2),
        paddingBottom: spacing(2),

        [breakpoints.up("sm")]: {
            borderTop: `1px solid ${palette.divider}`
        }
    },
    fixed: {
        background: '#fff',
        position: 'sticky',
        bottom: 0,

        '& $copyright': {
            display: 'none'
        }
    },
    menu: {
        display: "flex",
        flexDirection: "column",

        "& > :last-child": {
            marginBottom: 0,
        },
        [breakpoints.up("sm")]: {
            flexDirection: "row",
            "& > :first-child": {
                order: 999,
                marginLeft: "auto",
                marginRight: 0
            },
            "& > *": {
                marginBottom: 0,
                marginRight: spacing(3)
            }
        },
        [breakpoints.up("md")]: {
            "& > *": {
                marginRight: spacing(5)
            }
        }
    },
    copyright: {
        display: "flex",
        paddingTop: spacing(5),

        "& svg": {
            marginRight: spacing()
        },

        [breakpoints.up("sm")]: {
            paddingTop: spacing(3)
        }
    },
    supportLink: {
        textDecoration: 'none'
    },
}));

export default () => {
    const scrollValueRef = useRef<number>(0);
    const [fixed, setFixed] = useState(false);
    const classes = useStyles();

    const goTop = useCallback((e) => {
        e.preventDefault();

        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, []);

    useEffect(() => {
        const onScroll = () => {
            const scroll = (window.pageYOffset + window.innerHeight) / (document.body.offsetHeight / 100);

            setFixed(scroll >= scrollValueRef.current && scroll < 99 && window.pageYOffset > window.innerHeight);

            scrollValueRef.current = scroll;
        }

        window.addEventListener('scroll', onScroll);

        return () => window.removeEventListener('scroll', onScroll);
    }, []);

    return (
        <div className={clsx(classes.root, {[classes.fixed]: fixed})}>
            <Container maxWidth="lg">
                <div className={classes.menu}>
                    <Link variant="body1" paragraph component={NavLink} to="#" onClick={goTop}>
                        <b><Trans>Terug naar boven</Trans></b> <ArrowUpIcon style={{fontSize: 14}}/>
                    </Link>

                    {/*<Link variant="body1" gutterBottom color="textPrimary" component={NavLink} to={customerService}>*/}
                    {/*    {t("Klantenservice")}*/}
                    {/*</Link>*/}
                    <Link
                        variant="body1"
                        gutterBottom
                        color="textPrimary"
                        component={NavLink}
                        to={``}
                    >
                        <Trans>Home</Trans>
                    </Link>
                    <Link 
                        variant="body1" 
                        gutterBottom 
                        color="textPrimary" 
                        component={NavLink} 
                        to={`/register`}
                    >
                        <Trans>Account</Trans>
                    </Link>
                    <Link 
                        variant="body1" 
                        gutterBottom 
                        color="textPrimary" 
                        component={NavLink} 
                        to={`/${StaticPages.createYourAd}`}
                    >
                        <Trans>Woningruilen</Trans>
                    </Link>
                    <Link 
                        variant="body1" 
                        gutterBottom 
                        color="textPrimary" 
                        component={NavLink} 
                        to={`/faq`}
                    >
                        <Trans>Q&A</Trans>
                    </Link>
                    {/* <Link 
                        variant="body1" 
                        gutterBottom 
                        color="textPrimary" 
                        component={NavLink} 
                        to={`/${StaticPages.inFourSteps}`}
                    >
                        <Trans>Matchen</Trans>
                    </Link>
                    <Link 
                        variant="body1" 
                        gutterBottom 
                        color="textPrimary" 
                        component={NavLink} 
                        to={`/${StaticPages.arrangeHomeExchange}`}
                    >
                        <Trans>Woningruil</Trans>
                    </Link>
                    <Link
                        variant="body1"
                        gutterBottom
                        color="textPrimary"
                        component={NavLink}
                        to={`/${StaticPages.privacyStatement}`}
                    >
                        <Trans>Privacybeleid</Trans>
                    </Link> */}
                </div>
                <a href="/support" className={classes.supportLink}>
                    <Typography variant="body2" className={classes.copyright}>
                        <Logo/> &copy; 2024
                    </Typography>
                </a>
            </Container>
        </div>
    );
}

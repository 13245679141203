import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import CloseIcon from "component/icon/Close";
import React, {ReactNode, useCallback, useState} from "react";

interface IProps {
    openByDefault?: boolean;
    button?: (onOpen: () => void) => NonNullable<ReactNode>;
    children: (onClose: () => void) => NonNullable<ReactNode>;
}

export default ({openByDefault = false, button, children}: IProps) => {
    const [isOpen, setOpen] = useState(openByDefault);

    const onClose = useCallback(() => setOpen(false), []);
    const onOpen = useCallback(() => setOpen(true), []);

    return (
        <>
            {button && button(onOpen)}

            {isOpen && (
                <Dialog fullScreen open scroll="paper" onClose={onClose}>
                    <Toolbar>
                        <Box flexGrow={1} />
                        <IconButton edge="end" color="inherit" onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>

                    <Container maxWidth="md">
                        {children(onClose)}
                    </Container>
                </Dialog>
            )}
        </>
    );
};
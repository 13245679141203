import Badge from "@material-ui/core/Badge";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Link from "@material-ui/core/Link";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import HamburgerIcon from "component/icon/Hamburger";
import Modal from "component/Modal";
import { logout } from "lib/auth";
import React, { useEffect, useMemo } from "react";
import { Trans } from "react-i18next";
import { useSelector } from "react-redux";
import { NavLink, NavLinkProps, useHistory } from "react-router-dom";
import { getLoginState, getProfile } from "redux/selector";
import { StaticPages } from "../types/StaticPages";

const useStyles = makeStyles(({ spacing, palette, breakpoints, typography }: Theme) => createStyles({
    item: {
        ...typography.h2,
        color: palette.text.secondary,
        textDecoration: "none",
        lineHeight: `${spacing(8)}px`,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        "&:not(:last-child)": {
            borderBottom: `1px solid ${palette.text.hint}`
        },

        [breakpoints.up("lg")]: {
            fontWeight: typography.fontWeightRegular,
            marginLeft: spacing(2),
            position: "relative",
            color: palette.text.primary,
            "&:not(:last-child)": {
                borderBottom: "none"
            },
            "&:hover": {
                textDecoration: "none"
            },
            "&:hover:after, &$active:after": {
                content: "''",
                position: "absolute",
                height: 2,
                left: 0,
                right: 0,
                bottom: 0,
                background: palette.primary.main
            }
        }
    },
    active: {},
    subItem: {
        ...typography.body1,
        display: "block",
        marginBottom: spacing(),
        color: palette.text.primary
    },
    badge: {
        lineHeight: 0,
        marginRight: 10
    }
}));

const HistoryListener = ({ onChange }: { onChange: () => void }) => {
    const { listen } = useHistory();

    useEffect(() => listen(onChange));

    return null;
};

export default () => {
    const loggedIn = useSelector(getLoginState);
    const profile = useSelector(getProfile);
    const upMd = useMediaQuery(({ breakpoints }: Theme) => breakpoints.up("lg"));
    const classes = useStyles(() => { });
    const unreadMessages = profile ? profile.unreadMessages : 0;

    const items = useMemo(() => {
        const menu: NavLinkProps[] = [];

        if (!loggedIn) {
            menu.push(
                {
                    to: ``,
                    children: <Trans>Home</Trans>,
                },
                {
                    to: `/register`,
                    children: <Trans>Account</Trans>,
                },
                {
                    to: `/${StaticPages.createYourAd}`,
                    children: <Trans>Woningruilen</Trans>,
                },
                // {
                //     to: `/${StaticPages.showInterestInAProperty}`,
                //     children: <Trans>Reageren</Trans>,
                // },
                // {
                //     to: `/${StaticPages.inFourSteps}`,
                //     children: <Trans>Matchen</Trans>,
                // },
                // {
                //     to: `/${StaticPages.arrangeHomeExchange}`,
                //     children: <Trans>Woningruil</Trans>,
                // },
                {
                    to: `/faq`,
                    children: <Trans>Q&A</Trans>,
                },
            );
        }

        if (loggedIn) {
            menu.push(
                {
                    children: <Trans>Mijn zoekprofiel</Trans>,
                    to: "/preferences"
                },
                {
                    children: <Trans>Likes & favorieten</Trans>,
                    to: "/like"
                },
                {
                    to: "/messages",
                    children: (
                        <>
                            <Trans>Berichten</Trans>
                            {!!unreadMessages && (
                                <Badge
                                    color="secondary"
                                    badgeContent={unreadMessages}
                                    className={classes.badge}
                                    children="&nbsp;"
                                />
                            )}
                        </>
                    )
                },
                {
                    children: <Trans>Mijn woningruil</Trans>,
                    to: "/home-exchange"
                },
                {
                    children: <Trans>Mijn advertentie</Trans>,
                    to: "/my-advert"
                },
                {
                    children: <Trans>Account</Trans>,
                    to: "/account-info"
                },
                /*{
                    children: <Trans>Wachtwoord Aanpassen</Trans>,
                    to: "/change-password"
                },*/
                {
                    to: `/faq`,
                    children: <Trans>Service</Trans>,
                },
                {
                    children: <Trans>Uitloggen</Trans>,
                    to: "/logout",
                    onClick: logout
                }
            );
        }

        return menu.map((item, index) => (
            <NavLink
                key={index}

                className={classes.item}
                activeClassName={classes.active}
                exact
                {...item}
            />
        ));
    }, [classes, /*upMd,*/ unreadMessages, loggedIn]);

    if (upMd) {
        return (
            <>{items}</>
        );
    }

    return (
        <Modal
            button={onOpen => (
                <IconButton onClick={onOpen} edge="end">
                    <Badge color="secondary" variant="dot" badgeContent={unreadMessages}>
                        <HamburgerIcon
                            fontSize="large"
                        />
                    </Badge>
                </IconButton>
            )}

            children={onClose => (
                <Box p={4} flex={1} display="flex" flexDirection="column" justifyContent="space-between">
                    <HistoryListener onChange={onClose} />
                    <div>{items}</div>

                    <div>
                        {loggedIn ? (
                            <Link
                                href=""
                                onClick={logout}
                                className={classes.subItem}
                                children={<Trans>Uitloggen</Trans>}
                            />
                        ) : (
                            <Link
                                component={NavLink}
                                to="/login"
                                className={classes.subItem}
                                children={<Trans>Inloggen</Trans>}
                            />
                        )}
                        {!loggedIn && (
                            <Link
                                component={NavLink}
                                to="/register"
                                className={classes.subItem}
                                children={<Trans>Registreren</Trans>}
                            />
                        )}
                    </div>
                </Box>
            )}
        />
    );
};
import React from "react";
import Modal from "component/Modal";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import {Trans} from "react-i18next";
import {useSelector} from "react-redux";
import {getProfileState} from "redux/selector";
import Tooltip from "@material-ui/core/Tooltip";
import {Form, FormRenderProps} from "react-final-form";
import Button from "@material-ui/core/Button";
import Select from "component/form/field/Select";
import FilterField from "types/FilterField";
import SmallDesktopContainer from "component/SmallDesktopContainer";
import {composeRules, makeValidator, required} from "validate";
import {deleteAccount} from 'lib/auth'
import Fab from "@material-ui/core/Fab";

/*const useStyles = makeStyles(({spacing}: Theme) => createStyles({
    root: {
        margin: spacing(2, 0),
        border: 'none'
    }
}));*/

const options: FilterField[] = [
    {
        name: 'Geen belangstelling meer voor woningruil',
        id: 'geen belangstelling meer voor woningruil'
    },
    {
        name: 'Andere huurwoning gevonden',
        id: 'andere huurwoning gevonden'
    },
    {
        name: 'Ik kan geen ruilwoning vinden',
        id: 'ik kan geen ruilwoning vinden'
    },
    {
        name: 'Het systeem werkt niet voor mij',
        id: 'het systeem werkt niet voor mij'
    },
    {
        name: 'Andere reden',
        id: 'andere reden'
    },
];

type FormValues = {
    reason: string
}

const validator = makeValidator<FormValues>({
    reason: composeRules({
        'Reden is verplicht': required
    }),
});


export default () => {
    const [open, setOpen] = React.useState(false);

    const values: FormValues = {
        reason: ''
    }

    const handleSubmit = ({reason}: FormValues) => deleteAccount(reason);

    const handleClose = () => setOpen(false);
    const handleOpen = () => setOpen(true);

    const state = useSelector(getProfileState);

    if (state?.inTrade) {
        return (
            <Tooltip
                arrow
                title="Je kunt je account niet verwijderen als je actief aan het ruilen bent."
                open={open}
                onClose={handleClose}
                onOpen={handleOpen}
            >
                <Fab color="secondary" variant="extended" size="medium" >
                    <Trans>Account Verwijderen</Trans>
                </Fab>
           </Tooltip>
        );
    }

    return (
        <Modal
            button={(onOpen) => (
                <Fab color="secondary" variant="extended" size="medium" onClick={onOpen}>
                    <Trans>Account Verwijderen</Trans>
                </Fab>
            )}
            children={onClose => (
                <SmallDesktopContainer>
                    <Form validate={validator} onSubmit={handleSubmit} initialValues={values}>
                        {({handleSubmit}: FormRenderProps) => (
                            <Box component="form" onSubmit={handleSubmit} pt={2}>
                                <Typography paragraph align="center" variant="h1">
                                    <Trans>Account verwijderen?</Trans>
                                </Typography>

                                <Select placeholder="Wat is de reden?" name="reason" options={options}/>

                                <Box textAlign="right" pt={3}>
                                    <Button color="inherit" onClick={onClose}>
                                        <Trans>Terug</Trans>
                                    </Button>
                                    &nbsp;&nbsp;&nbsp;
                                    <Button color="primary" onClick={handleSubmit}>
                                        <Trans>Verwijder</Trans>
                                    </Button>
                                </Box>
                            </Box>
                        )}
                    </Form>
                </SmallDesktopContainer>
            )}
        />
    );
};

import {PER_PAGE} from "data/config";
import makeReducer from "lib/makeReducer";
import {
    LOAD_MORE_ADVERTS,
    SET_ADVERTS,
    SET_ADVERTS_FILTER,
    SET_AUTH_STATE,
    SET_FILTER_DATA,
    SET_PROFILE,
    SET_PROFILE_STATE,
    TOGGLE_FAVORITE_STATE
} from "redux/constant";
import ReduxState from "types/ReduxState";
import SetAdvertsAction from "types/SetAdvertsAction";
import SetAdvertsFilterAction from "types/SetAdvertsFilterAction";
import SetAuthStateAction from "types/SetAuthStateAction";
import SetFilterDataAction from "types/SetFilterDataAction";
import SetProfileAction from "types/SetProfileAction";
import SetProfileStateAction from "types/SetProfileStateAction";
import ToggleFavoriteAction from "types/ToggleFavoriteAction";

export const initial: ReduxState = {
    adverts: {
        list: [],
        count: Number.MAX_SAFE_INTEGER,
        start: -PER_PAGE,
        limit: PER_PAGE,
        loading: false,
        filters: undefined
    },

    filterData: undefined,

    loginState: undefined,

    profile: undefined,
    profileState: undefined
};

const handlers = {
    [SET_ADVERTS]: (state: ReduxState, {payload: {count, adverts: list}}: SetAdvertsAction): ReduxState => ({
        ...state,
        adverts: {
            ...state.adverts,
            loading: false,
            list,
            count
        }
    }),
    [LOAD_MORE_ADVERTS]: (state: ReduxState): ReduxState => ({
        ...state,
        adverts: {
            ...state.adverts,
            start: state.adverts.start + PER_PAGE,
            loading: true
        }
    }),
    [SET_ADVERTS_FILTER]: (state: ReduxState, {payload}: SetAdvertsFilterAction): ReduxState => ({
        ...state,
        adverts: {
            ...initial.adverts,
            filters: payload
        }
    }),
    [SET_AUTH_STATE]: (state: ReduxState, {payload}: SetAuthStateAction): ReduxState => ({
        ...state,
        loginState: payload
    }),

    [SET_PROFILE]: (state: ReduxState, {payload}: SetProfileAction): ReduxState => ({
        ...state,
        profile: payload,
        adverts: {
            ...initial.adverts,
            filters: state.adverts.filters
        }
    }),
    [SET_PROFILE_STATE]: (state: ReduxState, {payload}: SetProfileStateAction): ReduxState => ({
        ...state,
        profileState: payload
    }),

    [TOGGLE_FAVORITE_STATE]: (state: ReduxState, {payload}: ToggleFavoriteAction): ReduxState => ({
        ...state,
        adverts: {
            ...state.adverts,
            list: state.adverts.list.map(advert => {
                if (advert.id === payload) {
                    advert.details.favorited = advert.details.favorited ? 0 : 1;
                }

                return advert;
            })
        }
    }),
    [SET_FILTER_DATA]: (state: ReduxState, {payload}: SetFilterDataAction): ReduxState => ({
        ...state,
        filterData: payload
    })
};

export default makeReducer<ReduxState>(handlers, initial);
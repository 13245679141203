import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Grid from "@material-ui/core/Grid";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import FavoriteToggle from "component/action/FavoriteToggle";
import AdvertMatchLabel from "component/AdvertMatchLabel";
import Labels from "component/Labels";
import React from "react";
import {Trans} from "react-i18next";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {getLoginState} from "redux/selector";
import Advert from "types/Advert";
import {formatMoney} from "lib/format";

interface IProps {
    advert: Advert
}

const useStyles = makeStyles(({shape, spacing}: Theme) => createStyles({
    root: {
        background: "none"
    },
    image: {
        position: "relative",
        borderRadius: shape.borderRadius,
        paddingBottom: "56.2%",
        backgroundPosition: "center",
        backgroundSize: "cover"
    },
    content: {
        padding: `${spacing()}px 0 0`
    },
    labels: {
        position: "absolute",
        top: spacing(),
        left: spacing()
    },
    matchLabel: {
        position: "absolute",
        bottom: spacing(),
        left: spacing()
    }
}));

export default ({advert}: IProps) => {
    const loggedIn = useSelector(getLoginState);

    const classes = useStyles();

    const {photos, address: {city, neighborhood, street}, details, id} = advert;
    const {labels, match, rent: {basiccosts}, rooms, favorited, surface: {livable, plot}} = details;

    const formatCosts = (value: number) => {
        return formatMoney(value);
    }

    return (
        <Card className={classes.root}>
            <CardActionArea component={Link} to={`/view/${id}`}>
                <CardMedia image={photos.length ? photos[0].link : ""} className={classes.image}>
                    {!!labels.length && (
                        <Labels
                            labels={labels}
                            className={classes.labels}
                        />
                    )}

                    <AdvertMatchLabel
                        id={id}
                        className={classes.matchLabel}
                        match={match}
                    />
                </CardMedia>

                <CardContent className={classes.content}>
                    <Grid container justify="space-between">
                        <Typography variant="body2" component="h3" color="textSecondary">
                            {street}
                        </Typography>
                        {loggedIn && (
                            <FavoriteToggle
                                advert={id}
                                active={favorited}
                                color="secondary"
                            />
                        )}
                    </Grid>
                    <Grid container justify="space-between" alignItems="flex-end">
                        <Typography variant="body2" component="p">
                            <Trans values={{city, neighborhood}}>
                                in {{city}} ({{neighborhood}})
                            </Trans>
                        </Typography>
                    </Grid>

                    <Grid container justify="space-between" alignItems="flex-end">
                        <Typography variant="body2" component="p">
                            <Trans values={{rooms, livable, plot}}>
                                {{livable}}m<sup>2</sup> • {{rooms}} kamers
                            </Trans>
                        </Typography>
                        <Typography variant="body2" component="b">
                            € {formatCosts(basiccosts)} per maand
                        </Typography>
                    </Grid>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}

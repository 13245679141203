import {Box} from "@material-ui/core";
import Fab from "@material-ui/core/Fab";
import Grid from "@material-ui/core/Grid";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import AdvertCard from "component/AdvertCard";
import React, {useMemo} from "react";
import {Trans} from "react-i18next";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {getAdverts} from "redux/selector";

const useStyles = makeStyles(({spacing, breakpoints}: Theme) => createStyles({
    root: {
        position: "relative",

        marginTop: spacing(5),
        paddingTop: spacing(2),
        paddingBottom: spacing(2),

        "&:before": {
            top: 0,
            bottom: 0,
            left: "-1000%",
            right: "-1000%",
            content: "''",
            position: "absolute",
            backgroundColor: "#0000000a",
            zIndex: -1
        },

        [breakpoints.up("sm")]: {
            paddingTop: spacing(5),
            paddingBottom: spacing(5)
        }
    }
}));

export default () => {
    const classes = useStyles();
    const adverts = useSelector(getAdverts);

    const list = useMemo(() => (
        <Grid container spacing={4}>
            {adverts.slice(0, 3).map((advert, index) => (
                <Grid item xs={12} sm={6} md={4} key={`${advert.id}-${index}`}>
                    <AdvertCard advert={advert} />
                </Grid>
            ))}
        </Grid>
    ), [adverts]);

    return (
        <div className={classes.root}>
            <Typography variant="h2" paragraph>
                <Trans>Ook interessant voor jou</Trans>
            </Typography>

            {list}

            <Box pt={3} display="flex" justifyContent="center">
                <Fab color="inherit" variant="extended" size="small" component={Link} to="/">
                    <Trans>Bekijk alle huizen</Trans>
                </Fab>
            </Box>
        </div>
    );
};
import SvgIcon, {SvgIconProps} from "@material-ui/core/SvgIcon";
import getMatchColor from "lib/getMatchColor";
import React from "react";

type Props = SvgIconProps & {
    value?: number;
}

export default ({value, ...props}: Props) => (
    <SvgIcon {...props} viewBox="0 0 24 22" style={{color: getMatchColor(value)}}>
        <path
            d="M13.524 21.5H.91a.91.91 0 01-.91-.91v-4.18a.91.91 0 01.91-.91h5.66a.91.91 0 00.91-.91V7.41a.91.91 0 00-.91-.91H.909a.91.91 0 01-.91-.91V1.41A.91.91 0 01.908.5h12.615a.91.91 0 01.645.267l9.565 9.59a.91.91 0 010 1.286l-9.565 9.59a.91.91 0 01-.645.267z"
        />
    </SvgIcon>
);

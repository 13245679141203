import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";
import React from "react";

export default (props: SvgIconProps) => (
    <SvgIcon {...props} id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink">
        <defs>
            {/* <style>.cls-1{fill:url(#linear-gradient);}</style> */}
            <linearGradient id="linear-gradient" x1="-122.88" y1="785.46" x2="-121.88" y2="785.46" gradientTransform="matrix(21, 0, 0, -24.57, 2590, 19320.39)" gradientUnits="userSpaceOnUse"><stop offset="0" stopColor="#4984c5" /><stop offset="1" stopColor="#2b2872" /></linearGradient>
        </defs>
        <path id="Path_3455" className="cls-1" d="M23.58,7.71H9.5V32.29h21v-18ZM24,10.35l3.69,3.51H24Zm4.84,20.4H11.12V9.25h11.3v6.14h6.46Zm-9.8-7.2a3.67,3.67,0,0,1,.21-1.39,2.84,2.84,0,0,1,.77-1,3.89,3.89,0,0,0,.75-.82,1.23,1.23,0,0,0,.18-.66q0-1.05-1-1.05a1,1,0,0,0-.74.29,1.11,1.11,0,0,0-.29.78H17.2A2.38,2.38,0,0,1,18,17.83a3.53,3.53,0,0,1,4.1,0,2.23,2.23,0,0,1,.72,1.8,2.07,2.07,0,0,1-.23,1,4.37,4.37,0,0,1-.83,1l-.5.47a1.69,1.69,0,0,0-.53,1.07l0,.37Zm-.18,1.9a.86.86,0,0,1,.28-.68,1.09,1.09,0,0,1,1.44,0,1,1,0,0,1,0,1.35,1.12,1.12,0,0,1-1.46,0,.88.88,0,0,1-.27-.67Z" />
    </SvgIcon>
);
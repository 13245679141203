import SvgIcon, {SvgIconProps} from "@material-ui/core/SvgIcon";
import React from "react";

export default ( props: SvgIconProps ) => (
    <SvgIcon {...props} viewBox="0 0 24 4">
      <path
          fill="#b0b0b0"
          d="M22.286.286a1.714 1.714 0 01.232 3.413l-.232.015H1.714A1.714 1.714 0 011.482.301l.232-.015h20.572z"
      />
    </SvgIcon>
);
import React, { useState } from "react";
import FormHelperText from "@material-ui/core/FormHelperText";
import { FormControlLabel, makeStyles, Radio, RadioGroup } from "@material-ui/core";


const useStyles = makeStyles({
    root: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    icon: {
        borderRadius: '50%',
        width: 16,
        height: 16,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: '#137cbd',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#106ba3',
        },
    },
});

export type SelectProps = {
    initialValue?: boolean;
    name: string;
    helperText?: string;
    readOnly?: boolean;
    onChange?: (value: boolean) => void
}

export default ({ name, onChange, initialValue, helperText }: SelectProps) => {
    const classes = useStyles();
    const [value, setValue] = useState<boolean>(initialValue ?? false)
    const onValueSelect = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const s = e.target.value
        setValue(s === 'true');
        if (onChange) {
            onChange(s === 'true');
        }
    };
    return (
        <>
            <RadioGroup name={name} value={value} onChange={onValueSelect}>
                <FormControlLabel value={true}
                    control={
                        <Radio
                            className={classes.root}
                            disableRipple
                            color="default"
                            checkedIcon={<span className={`${classes.icon} ${classes.checkedIcon}`} />}
                            icon={<span className={classes.icon} />}
                        />
                    }
                    label="Ja" />
                <FormControlLabel value={false}
                    control={
                        <Radio
                            className={classes.root}
                            disableRipple
                            color="default"
                            checkedIcon={<span className={`${classes.icon} ${classes.checkedIcon}`} />}
                            icon={<span className={classes.icon} />}
                        />
                    }
                    label="Nee" />
            </RadioGroup>

            {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </>
    );
}

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";

type Props = {
    contents: string;
}

const useStyles = makeStyles(({spacing, typography, palette}: Theme) => createStyles({
    root: {
        "& *": {
            margin: 0,
        },
        "& p": {
            ...typography.body2,
            marginBottom: spacing(),
        },
        "& a": {
            ...typography.body2,
            color: palette.primary.main,
        },
        "& h1": {
            ...typography.h1,
            marginBottom: spacing(),
        },
        "& h2": {
            ...typography.h2,
            marginBottom: spacing(),
        },
        "& h3": {
            ...typography.h3,
            marginBottom: spacing(),
        },
        "& h4": {
            ...typography.h4,
            marginBottom: spacing(),
        }
    },
}));

export default ({contents}: Props) => (
    <Typography
        classes={useStyles()}
        variant="body2"
        gutterBottom
        component="div"
        dangerouslySetInnerHTML={{__html: contents}}
    />
)
import { Radio, RadioGroup, FormControlLabel, Typography, Box } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Endpoint } from "lib/api";
import { useApi } from "lib/useApi";
import React, { useState } from "react";
import { YesNoQuestion } from "types/TradeResponse";

interface IProps extends YesNoQuestion {
    index: React.ReactNode;
    setIsYes: (checked: boolean) => void;
    isYes: boolean;
    locked: boolean;
}

const useStyles = makeStyles(({ spacing }: Theme) => createStyles({
    root: {
        display: "grid",
        gridTemplateColumns: "max-content max-content 1fr",
        columnGap: spacing(),
        alignItems: "center",
        marginBottom: spacing(2),
    },
    content: {
        paddingLeft: 50,
    },
}));

export default ({ id, index, locked, title, content, isYes, setIsYes, answer }: IProps) => {
    const classes = useStyles();

    const [selected, setSelected] = useState<string | undefined>( answer.value === null ? undefined : ( answer.value ? 'true' : 'false' ))
    const [{ loading, error }, updateTask] = useApi<string>(Endpoint.updateTradeTask, {
        autoTrigger: false,
        initialData: "",
        params: { id },
    });

    const onChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!locked) {
            const s = e.target.value
            await updateTask({
                data: { answer: s === 'true' ? true : false },
            });
            setSelected(s);
            setIsYes(isYes);
        }
    };

    return (
        <>
            <label className={classes.root}>
                {!!error && <Snackbar open message={error.error} />}
                {loading ? <CircularProgress size={24} /> : index}
                <Typography variant="h4" >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{title}</Typography>
            </label >
            <Box className={classes.content}>
                <RadioGroup aria-label="yesno" name="yesNo" value={selected} onChange={onChange}>
                    <FormControlLabel value="true" control={<Radio />} label="Ja" />
                    <FormControlLabel value="false" control={<Radio />} label="Nee" />
                </RadioGroup>
            </Box>
        </>
    );
};
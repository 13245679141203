import React from "react";
import account from 'assets/accountaanmaken.png';
import advertentie from 'assets/advertentie.png';
import duimpje from 'assets/duimpje.png';
import woning from 'assets/woning.png';
import stap1_1 from 'assets/icons/1.1.png';
import stap1_2 from 'assets/icons/1.2.png';
import stap1_3 from 'assets/icons/1.3.png';
import stap1_4 from 'assets/icons/1.4.png';
import stap2_1 from 'assets/icons/2.1.png';
import stap2_2 from 'assets/icons/2.2.png';
import stap2_3 from 'assets/icons/2.3.png';
import stap2_4 from 'assets/icons/2.4.png';
import stap3_1 from 'assets/icons/3.1.png';
import stap3_2 from 'assets/icons/3.2.png';
import stap3_3 from 'assets/icons/3.3.png';
import stap3_4 from 'assets/icons/3.4.png';
import stap4_1 from 'assets/icons/4.1.png';
import stap4_2 from 'assets/icons/4.2.png';
import stap4_3 from 'assets/icons/4.3.png';
import stap4_4 from 'assets/icons/4.4.png';
import './styles.css';

export default () => (
    <>
        <h1>Hoe ruil ik mijn woning?</h1>
        <div className="container">
            <div className="row">
                {/* Col 1 */}
                <div className="col">
                    <div className='steps' id='steps'><img src={account} className="card-img-top" alt="Card 1" style={{ width: '180px' }} />
                    <h3>Maak een account aan</h3>
                        <div className='step'>
                            <div className='number'><img src={stap1_1} style={{ width: '20px' }} /> </div>
                            <div className='info'>
                                <p className='title'>
                                1. Account aanmaken
                                </p>
                                <br/>
                            </div>
                        </div>

                        <div className='step'>
                            <div className='number'><img src={stap1_2} style={{ width: '20px' }} /></div>
                            <div className='info'>
                                <p className='title'>2. Check beschikbaarheid </p>
                                <br/>
                            </div>
                        </div>
                        <div className='step'>
                            <div className='number'><img src={stap1_3} style={{ width: '20px' }} /></div>
                            <div className='info'>
                                <p className='title'>3. Vul in persoonsgegevens</p>
                                <br/>
                            </div>
                        </div>

                        <div className='step'>
                            <div className='number'><img src={stap1_4} style={{ width: '20px' }} /></div>
                            <div className='info'>
                                <p className='title'>4. Maak zoekprofiel aan </p>
                                <br/>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Col 2 */}
                <div className="col">
                    <div className='steps' id='steps'><img src={advertentie} className="card-img-top" alt="Card 1" style={{ width: '180px' }} />
                    <h3>Maak jouw advertentie</h3>
                        <div className='step'>
                            <div className='number'><img src={stap2_1} style={{ width: '20px' }} /></div>
                            <div className='info'>
                                <p className='title'>
                                1. Vul kenmerken van jouw huis in
                                </p>
                                <p className='text'></p>
                            </div>
                        </div>

                        <div className='step'>
                            <div className='number'><img src={stap2_2} style={{ width: '20px' }} /> </div>
                            <div className='info'>
                                <p className='title'>2. Voeg foto's toe</p>
                                <br />
                                <p className='text'></p>
                            </div>
                        </div>
                        <div className='step'>
                            <div className='number'><img src={stap2_3} style={{ width: '20px' }} /> </div>
                            <div className='info'>
                                <p className='title'>3. Omschrijf je woning </p>
                                <br/>
                            </div>
                        </div>

                        <div className='step'>
                            <div className='number'><img src={stap2_4} style={{ width: '20px' }} /> </div>
                            <div className='info'>
                                <p className='title'>4. Bekijk je advertentie </p>
                                <p className='text'></p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Col 3 */}
                <div className="col">
                    <div className='steps' id='steps'><img src={duimpje} className="card-img-top" alt="Card 1" style={{ width: '180px' }} />
                    <h3>Reageren</h3>
                        <div className='step'>
                            <div className='number'><img src={stap3_1} style={{ width: '20px' }} /></div>
                            <div className='info'>
                                <p className='title'>
                                1. Klik op advertentie
                                </p>
                                <br/>
                            </div>
                        </div>

                        <div className='step'>
                            <div className='number'><img src={stap3_2} style={{ width: '20px' }} /> </div>
                            <div className='info'>
                                <p className='title'>2. Bekijk details </p>
                                <br />
                                <p className='text'></p>
                            </div>
                        </div>
                        <div className='step'>
                            <div className='number'><img src={stap3_3} style={{ width: '20px' }} /> </div>
                            <div className='info'>
                                <p className='title'>3. Reageren op huis</p>
                                <br/>
                            </div>
                        </div>

                        <div className='step'>
                            <div className='number'><img src={stap3_4} style={{ width: '20px' }} /> </div>
                            <div className='info'>
                                <p className='title'>4. Stuur bericht </p>
                                <p className='text'></p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Col 4 */}
                <div className="col">
                    <div className='steps' id='steps'><img src={woning} className="card-img-top" alt="Card 1" style={{ width: '180px' }} />
                    <h3>Woningruil regelen</h3>
                        <div className='step'>
                            <div className='number'><img src={stap4_1} style={{ width: '20px' }} /></div>
                            <div className='info'>
                                <p className='title'>
                                1. Afspraak
                                </p>
                                <br/>
                            </div>
                        </div>

                        <div className='step'>
                            <div className='number'><img src={stap4_2} style={{ width: '20px' }} /></div>
                            <div className='info'>
                                <p className='title'>2. Bekijk woning </p>
                                <br/>
                            </div>
                        </div>
                        <div className='step'>
                            <div className='number'><img src={stap4_3} style={{ width: '20px' }} /></div>
                            <div className='info'>
                                <p className='title'>3. Documenten voorbereiden</p>
                                <br/>
                            </div>
                        </div>

                        <div className='step'>
                            <div className='number'><img src={stap4_4} style={{ width: '20px' }} /></div>
                            <div className='info'>
                                <p className='title'>4. Uw ruil! </p>
                                <p className='text'></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
)

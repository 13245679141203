import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import FiltersForm, {Props} from "component/form/Filters";
import FilterIcon from "component/icon/Filter";
import Modal from "component/Modal";
import SmallDesktopContainer from "component/SmallDesktopContainer";
import React, {useCallback} from "react";
import {Trans} from "react-i18next";

export default ( props: Props ) => {
  const renderForm = useCallback( onClose => (
      <SmallDesktopContainer>
        <FiltersForm
            {...props}
            onSubmit={( ...args ) => {
              props.onSubmit( ...args );

              onClose();
            }}
        />
      </SmallDesktopContainer>
  ), [props] );

  const renderButton = useCallback( onOpen => (
      <Box pt={2} pb={2} textAlign="center">
        <Chip
            onClick={onOpen}
            avatar={<Avatar children={<FilterIcon />} />}
            label={<Trans>Filters</Trans>}
            clickable
            color="primary"
            variant="outlined"
        />
      </Box>
  ), [] );

  return (
      <Modal
          button={renderButton}
          children={renderForm}
      />
  );
};
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";
import React from "react";

export default (props: SvgIconProps) => {

    return (
        <SvgIcon  {...props} id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <defs>
                {/* <style>
            .cls-1 {
                fill: url(#linear-gradient);
            }
        </style> */}
                <linearGradient id="linear-gradient" x1="-67.05" y1="785.6" x2="-66.05" y2="785.6" gradientTransform="matrix(30.38, 0, 0, -28.32, 2041.63, 22269.77)" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stopColor="#4984c5" />
                    <stop offset="1" stopColor="#2b2872" />
                </linearGradient>
            </defs>
            <path
                id="Path_3453"
                className="cls-1"
                d="M17.93,34.16H7.6v-13L6.27,22.49,4.81,21,20,5.84,35.19,21l-1.46,1.45L32.4,21.17v13Zm6.2-2.06h6.2v-13L20,8.77,9.67,19.1v13H24.13ZM18.58,26a5.8,5.8,0,0,1,.32-2.14,4.61,4.61,0,0,1,1.2-1.57A5.94,5.94,0,0,0,21.25,21a2,2,0,0,0,.29-1q0-1.62-1.5-1.62a1.54,1.54,0,0,0-1.14.44,1.76,1.76,0,0,0-.44,1.2H15.68a3.71,3.71,0,0,1,1.18-2.87,5.47,5.47,0,0,1,6.33-.05,3.48,3.48,0,0,1,1.13,2.78A3.43,3.43,0,0,1,24,21.38,6.36,6.36,0,0,1,22.68,23l-.78.74a2.6,2.6,0,0,0-.83,1.64L21,26Zm-.28,2.94a1.41,1.41,0,0,1,.43-1.05,1.7,1.7,0,0,1,2.24,0,1.48,1.48,0,0,1,0,2.09,1.74,1.74,0,0,1-2.25,0A1.37,1.37,0,0,1,18.3,28.89Z"
            />
        </SvgIcon>
    )
};
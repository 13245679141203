import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Tag from "component/Tag";
import React, { useMemo } from "react";
import { useField } from "react-final-form";
import FilterField from "types/FilterField";
import { Trans } from "react-i18next";

const useStyles = makeStyles(({ spacing }: Theme) => createStyles({
    root: {
        display: "flex",
        flexWrap: "wrap",
        marginLeft: spacing(-1),
        marginTop: spacing(-1),
        "& > *": {
            minWidth: 50,
            marginLeft: spacing(),
            marginTop: spacing()
        }
    }
}));

export type TagsProps = {
    name: string;
    available: FilterField[];
    multiple?: boolean;
    onClick?: (tagId: any) => void
    showIcons?: boolean;
    readOnly?: boolean;
}

export default ({ name, available, multiple, showIcons, readOnly, onClick }: TagsProps) => {
    const { input, meta } = useField<string[] | string>(name, {
        multiple
    });
    const classes = useStyles();

    const tags = useMemo(() => (
        available.map(tag => {
            const value = multiple ? input.value || [] : "";

            let isActive: boolean;
            let onToggle;
            if (multiple && Array.isArray(value)) {
                isActive = value.indexOf(tag.id) > -1;

                onToggle = () => input.onChange(
                    isActive ?
                        value.filter(v => v !== tag.id) :
                        [...value, tag.id]
                );
            } else {
                isActive = input.value === tag.id;

                onToggle = () => {
                    if (readOnly !== true) {
                        input.onChange(tag.id);
                    }
                    if (onClick) {
                        onClick(tag.id)
                    }
                }
            }


            return (
                <Tag
                    key={tag.id}
                    showIcons={showIcons}
                    isActive={isActive}
                    tag={tag}
                    onToggle={onToggle}
                />
            );
        })
    ), [available, input, multiple, showIcons, readOnly]);

    const helperText = meta.touched ? (meta.error || meta.submitError) : "";

    return (
        <FormControl fullWidth margin="dense" size="small" error={meta.touched && meta.invalid}>
            <div className={classes.root}>
                {tags}
            </div>

            {helperText && (
                <FormHelperText>
                    <Trans>{helperText}</Trans>
                </FormHelperText>
            )}
        </FormControl>
    );
}

import React from "react";
import {Link} from "react-router-dom";
import {StaticPages} from "types/StaticPages";

export default () => (
    <>
        <h1>Hoe werkt het?</h1>
        <p>
            Op ruilmijnwoning.nl kun jij je ideale huurwoning vinden door gebruik te maken van woningruil. Woningruil is
            een handige service om jouw eigen huurwoning te ruilen met de huurwoning van iemand anders. Je bent
            woningzoekende, maar met jouw inschrijfduur en/of inschrijfpunten kun je niet snel genoeg aan een andere
            huurwoning komen? En je wil wel graag verhuizen naar een andere woning? Dan is ruilmijnwoning.nl de ideale
            oplossing voor jou. Ruilmijnwoning.nl helpt jou om te zoeken, te vinden en te matchen met een andere
            huurwoning.
        </p>
        <h2>Wat moet je doen?</h2>
        <p>
            Wil jij eerst even rondkijken op ruilmijnwoning.nl? Dat kan! Kijk rustig rond in het overzicht met
            advertenties van huurwoningen voor woningruil. Je kunt alleen niet heel veel doen op het platform. Denk je
            dat woningruil een interessante optie voor jou is? Dan kun jij je eenvoudig aanmelden op het platform
            ruilmijnwoning.nl. In een paar stappen ben jij aangemeld!
        </p>
        <ol>
            <li>
                Maak eerst een account op ruilmijnwoning.nl aan. Met je account kun je gaan zoeken naar interessante
                huurwoningen die zijn aangeboden voor woningruil. Andere huurders van huurwoningen bieden hun woning
                namelijk ook aan voor woningruil op ruilmijnwoning.nl.
            </li>
            <li>
                Vul je zoekprofiel in. Met je zoekprofiel ga je heel gericht zoeken naar interessante huurwoning voor
                jou.
            </li>
            <li>
                Ook ga jij jouw huurwoning adverteren. Dit doe je door je woning zo goed mogelijk in de etalage te
                zetten. Als jij jouw huurwoning online hebt gezet, kun je op zoek naar een mogelijke match met iemand
                anders.
            </li>
            <li>
                Met de Like laat je weten dat je de huurwoning van iemand anders erg leuk vindt. En als jullie elkaar
                Liken kun je een woningruil gaan regelen.
            </li>
        </ol>

        <h2>Hoe maak ik een account aan?</h2>
        <p>
            Heb je goed rondgekeken op ruilmijnwoning.nl en wil je graag een account aanmaken? Doorloop dan de volgende
            stappen om op een goede manier je account aan te maken.
        </p>

        <h3>Stap 1. Account aanmaken</h3>
        <p>
            Via de knop Inloggen maak jij een account aan op ruilmijnwoning.nl. Voor het aanmaken van je account kun je
            heel eenvoudig inloggen met je account van Google of Facebook. Je hoeft dus niet nog een apart account te
            gebruiken, omdat je gewoon je account van Google of Facebook gebruikt.
        </p>
        <p>
            <i>
                Let op: ruilmijnwoning.nl checkt alleen je account, maar kan geen persoonlijke gegevens van Facebook of
                Google van jouw zien. Ook worden geen persoonlijke gegevens van Google of Facebook van jou bewaard op
                ruilmijnwoning.nl
            </i>
        </p>

        <h3>Stap 2. Check op beschikbaarheid</h3>
        <p>
            Vul de postcode en het huisnummer van jouw huidige huurwoning in en selecteer de woningcorporatie waarvan
            jij de woning huurt. Voer vervolgens de check uit of jij jouw huurwoning mag aanbieden voor woningruil.
            Ruilmijnwoning.nl laat het jouw weten. Waarom checkt ruilmijnwoning.nl op
            beschikbaarheid? <Link to={`/${StaticPages.usefulTips}`}>Zie handige tips</Link>
        </p>

        <h3>Stap 3. Vul je persoonsgegevens in</h3>
        <p>
            Ruilmijnwoning.nl heeft maar een paar persoonsgegevens van je nodig. Dit zijn gegevens over wie je bent, hoe
            groot je gezin is, hoeveel je verdient en hoe jij graag contact wilt hebben. Met deze gegevens zorgen we dat
            ruilmijnwoning.nl jou zo goed mogelijk helpt.
        </p>

        <h3>Stap 4. Maak je zoekprofiel aan</h3>
        <p>
            De laatste stap voor het aanmaken van je account is het instellen van je persoonlijke zoekprofiel. Vul je
            zoekprofiel zo volledig en zo goed mogelijk in. Op basis van jouw zoekprofiel laat ruilmijnwoning.nl jouw
            beste persoonlijke matches zien. Je wordt geholpen om jouw &lsquo;droomwoning&rsquo; zo snel mogelijk te
            kunnen vinden. Vul je jouw zoekprofiel niet direct in? Geen probleem! Ruilmijnwoning.nl vraagt jou de
            volgende keer om je zoekprofiel af te maken. En je kunt je zoekprofiel altijd weer aanpassen.
        </p>

        <h3>Je account is klaar!</h3>
        <p>
            Door jouw zoekprofiel wordt de lijst met advertenties gesorteerd op volgorde van de beste match. Dit
            betekent dat de woningen die bovenaan in jouw lijst staan het best aansluiten op jouw wensen uit je
            zoekprofiel. Nu kun je op zoek naar je &lsquo;droomwoning&rsquo;! Door jouw zoekprofiel zie je in de lijst
            met advertenties een nieuwe informatiebanner (met de huisjes).
        </p>

        <p>
            Deze banner laat zien hoe jouw zoekwensen aansluiten bij de woningadvertentie die je bekijkt. De best
            passende woningen hebben een groene indicatie. Een gele indicatie betekent dat er iets minder overeenkomsten
            zijn met jouw zoekwensen. Een oranje indicatie heeft nog minder overeenkomsten en een rode indicatie
            betekent eigenlijk geen match. Deze woningen sluiten niet aan bij jouw zoekwensen. Op deze manier kun jij
            heel snel zien welke woningen voor jouw interessant zijn!
        </p>

        <p>
            En zie je leuke woningen? Zet ze in je favorieten. Door het hartje aan te klikken zet je een
            woningadvertentie in jouw favorieten. Dan kun je heel snel je favoriete woningen weer terugvinden.
        </p>

        <h2>Maak jouw advertentie</h2>
        <p>
            Op ruilmijnwoning.nl kun je eenvoudig reageren op andere woningadvertenties. Dat doe je door te Liken
            (duimpje omhoog). Maar je kunt pas Liken als jij jouw woning ook hebt geadverteerd. Je moet namelijk elkaars
            woning leuk vinden om daadwerkelijk te kunnen ruilen. Maak daarom zo snel mogelijk je advertentie aan! Dan
            kunnen andere woningruilers ook reageren op jouw woningadvertentie.
        </p>

        <p>
            Heb jij jouw woningadvertentie nog niet aangemaakt? Maak je geen zorgen! Ruilmijnwoning.nl heeft
            verschillende functies beschikbaar om je advertentie aan te maken. Volg de stappen om jouw woning te
            adverteren. Doe dit zo goed mogelijk, dan heb je de beste kans dat iemand anders jouw woning ook leuk gaat
            vinden.
        </p>

        <h3>Stap 1. Vul kenmerken van jouw huis in</h3>
        <p>
            Bij de eerste stap geef je alle woningkenmerken van jouw woning op. Vul alle velden altijd in! Maar we
            hebben we ook al een aantal gegevens voor jou ingevuld. Dit kunnen we doen, omdat jij jouw huidige
            woningcorporatie en jouw adres bij de Check beschikbaarheid hebt opgegeven. Als jouw woningcorporatie is
            aangesloten bij ruilmijnwoning.nl zijn een aantal woningkenmerken dus al bekend. Dit hoef jij niet meer te
            doen. Handig toch! Zijn de kenmerken niet ingevuld? Dan is jouw corporatie nog niet aangemeld en vul je zelf
            de kenmerken in.
        </p>

        <p>Als je alle woningkenmerken hebt ingevuld ga je naar de volgende stap: Foto&rsquo;s maken</p>

        <h3>Stap 2. Voeg goede foto’s toe</h3>
        <p>
            De foto’s van jouw woning zijn hét belangrijkste onderdeel van jouw woningpresentatie. Goede foto’s geven
            immers een goed beeld van je woning en zorgen ervoor dat jouw advertentie opvalt. Zo wordt de kans op likes
            en een uiteindelijke match vergroot. Met je eigen mobiele telefoon kan je zelf goede foto’s maken. Maar je
            moet er wel de tijd voor nemen.
            <br/>
            Het begint met een goede voorbereiding en daarom hebben wij een aantal tips voor je op een rijtje gezet.
        </p>

        <h3>Voorbereiding</h3>
        <p><b>Ruim op</b></p>
        <p>
            Voor het maken van mooie foto’s is een schone en frisse omgeving een must. Zorg voor een stofvrije en schone
            woning. Dus spiegels zonder vingerafdrukken, glimmende badkamertegels, een schone keuken en gelapte ramen.
            Vergeet ook niet om de kattenbak, krabpaal, vogelkooi of hondenmand uit het zicht te zetten. Haal
            bijvoorbeeld ook zoveel mogelijk stekkers uit de stopcontacten en maak de stopcontacten schoon.
        </p>
        <p><b>Haal persoonlijke spullen weg</b></p>
        <p>
            Zet eigen foto’s, schilderijen en andere persoonlijke spullen liever niet op de foto. Het leidt onnodig af
            van de woning. Haal ook asbakken etc. In de hal kun je zorgen dat de jassen en schoenen zijn opgeruimd en er
            verder zo weinig mogelijk spullen staan.
        </p>
        <p><b>Badkamer en keuken</b></p>
        <p>
            Zorg ook hier dat je persoonlijke items niet op de foto komen. Denk in de badkamer aan tandenborstels,
            luchtjes, handdoeken, douchespullen en badmatten. Zet de lichten aan. Wat betreft de keuken: Maak deze zo
            leeg mogelijk! Haal het aanrechtblad leeg, ruim keukenspullen en keukenapparaten op. Een koffiezetapparaat
            of keukenmachine kun je eventueel laten staan. Een mooie bos (veld)bloemen of vers fruit staan vaak erg mooi
            en maken een ruimte juist weer levend en fris.
        </p>
        <p><b>Slaapkamer(s)</b></p>
        <p>
            Maak de bedden strak op (zorg ervoor dat er zo min mogelijk kreukels in het beddengoed zitten en trek de
            dekbedden zo strak mogelijk aan). Leg eventuele vloerkleedjes recht en zet alle lampen aan. Doe de gordijnen
            goed open en haal nu ook weer persoonlijke spullen weg.
        </p>
        <p><b>Vloeren vrij</b></p>
        <p>
            Houd de vloerruimtes zoveel mogelijk leeg! Zet geen spullen op de grond, onder de tafel, in de hoek, naast
            de bank of onder de kapstok. Een lege vloer geeft ruimte.
        </p>
        <p><b>Repareer kleine gebreken</b></p>
        <p>
            Vlekjes op de muur, afbladderende verf en losliggende snoeren zijn allemaal voorbeelden van kleine gebreken
            die afleiden op de fotopresentatie. Loop jouw huis eens na voordat je gaat en check welke kleine gebreken je
            zelf kunt oplossen.
        </p>
        <p><b>Creëer licht</b></p>
        <p>
            Open alle gordijnen, luxaflex en andere raambekleding! Buitenlicht is belangrijk voor mooie foto’s.
            Misschien houdt een kast het licht tegen, overweeg dan om deze voor de foto’s te verplaatsen. Zet binnen ook
            zoveel mogelijk alle lichten aan. Het licht van binnen heeft bij het fotograferen een positief effect op de
            warmte en kleur van alles dat erin staat.
        </p>
        <p><b>Buiten</b></p>
        <p>
            Vergeet ook de buitenkant van jouw woning niet. Vaak wordt het vooraanzicht van de woning de hoofdfoto van
            je advertentie, Dus zorg dat dit er netjes uit ziet! Check je voordeur en tuinpad. Zorg ook dat de tuin
            netjes is; het gras gemaaid, perken netjes, eventueel bloemen in bakken en snoei heggen en hagen om ruimte
            en licht te creëren. Ook een balkon ziet er beter uit als het netjes is. Mocht je buiten meubilair hebben;
            leg de eventuele kussens hier op. Zet altijd de afvalbakken uit het zicht en zorg dat er liefst geen
            vervoermiddelen in beeld staan.
        </p>
        <p>
            Succes met de voorbereidingen!
        </p>

        <h3>Het maken van de foto’s</h3>
        <p><b>Maak van de volgende (buiten)ruimtes minimaal één foto:</b></p>
        <p><b>Buitenkant woning</b></p>
        <p>
            Voeg een foto van de voor- én de achterkant toe (als dit mogelijk is). Daarnaast maak je foto’s van je voor-
            of achtertuin, balkon of terras. Probeer deze foto’s op een droge, onbewolkte dag te nemen zodat de foto’s
            zo licht en helder mogelijk worden.
        </p>
        <p><b>Woonkamer en keuken</b></p>
        <p>
            Neem de foto’s zoveel mogelijk in het licht door bij het raam te staan. Maak een goede foto van je keuken
            door recht voor je keuken te gaan staan. Let er op dat alle lichten aan staan om te voorkomen dat er donkere
            hoeken op de foto komen.
        </p>
        <p><b>Slaapkamer(s)</b></p>
        <p>
            Ga in de hoek van de slaapkamer staan en maak een complete foto van elke slaapkamer. Lukt dit niet? Probeer
            dan vanaf een raam een foto van elke slaapkamer te maken.
        </p>
        <p><b>Badkamer</b></p>
        <p>
            Maak 1 of 2 foto’s van de badkamer. Maak vooral een goede foto van de ruimte. Heb je een mooie douchekop of
            een mooie wastafel. Maak er een extra foto van. Ook als je extra toilet in je badkamer hebt, kun je ervoor
            zorgen dat het op een foto staat.
        </p>

        <p><b>Overige ruimtes</b></p>
        <p>
            Jet kunt foto’s maken van bijvoorbeeld de zolder, berging, toilet of bijvoorbeeld van mooie details van de
            woning als die er zijn. Als je een mooi uitzicht hebt kun je hier ook een leuke foto van toevoegen of van de
            gezellige straat waar je woning in ligt. Van elke ruimte is 1 foto voldoende om toe te voegen.
        </p>

        <h3>Stap 3. Voeg een omschrijving toe</h3>
        <p>
            De laatste stap is een leuke omschrijving van je advertentie te geven. De omschrijving van je woning komt in
            de advertentie te staan. Maak er een leuke tekst van. Je wilt toch graag jouw woning zo leuk mogelijk maken
            voor woningruil. Heb je de beschrijving klaar? Druk op preview.
        </p>

        <h3>Stap 4. Bekijk je advertentie</h3>
        <p>
            Je krijgt een overzicht van je woningadvertentie zoals de advertentie op ruilmijnwoning.nl wordt geplaatst.
            Wil je nog iets aanpassen? Klik gewoon op vorige stap en je kunt je advertentie wijzigen. Als je blij bent
            met je advertentie klik op Plaatsen en je advertentie staat online. En wil je later nog iets aanpassen? Geen
            probleem, via het menu onder Mijn advertentie kun je jouw advertentie altijd weer wijzigen.
        </p>

        <h2>Liken en Disliken</h2>
        <p>
            Je hebt nu je account aangemaakt en je eigen woning geadverteerd. Vanaf nu kun je Liken en Disliken. Met
            Liken en Disliken maak je kenbaar of je woningen leuk vindt of juist niet.
        </p>

        <h3>Liken</h3>
        <p>
            De lijst met woningadvertenties is gesorteerd op volgorde van jouw woonwensen volgens het zoekprofiel. De
            best passende woningen staan bovenaan. Zie je een interessante advertentie? Klik op de advertentie om de
            details van de advertentie te bekijken. Je kunt de kenmerken, foto&rsquo;s, omschrijving en locatie van de
            woning bekijken. Is dit een mogelijk &lsquo;droomhuis&rsquo;? <b>Druk op Like (duimpje omhoog)</b>. Met
            een Like maak je kenbaar dat je ge&iuml;nteresseerd bent in de woning. De Like wordt nu blauw.
        </p>

        <p>
            Na een Like stuur je een bericht naar de aanbieder van de woning. Je kunt het standaard bericht sturen en
            als je wilt kun je het bericht aanpassen. Wat jij wilt. Als je op de knop Liken & bericht sturen drukt,
            stuur je in ruilmijnwoning.nl een bericht naar de aanbieder. De aanbieder kan vervolgens jouw woning gaan
            bekijken.
        </p>

        <p>
            En heb jij je vergist? Wil je de Like liever niet? Geen probleem! Je kunt jouw Like altijd weer intrekken!
            Dit doe je door de Like weer uit te klikken. De aanbieder krijgt automatisch een berichtje dat een Like weer
            is ingetrokken.
        </p>

        <p>
            <i>
                Let op: Zie jij meerdere woningen die je interessant vindt? Je kunt meerdere woning tegelijk Liken! Je
                vindt jouw Likes terug in het menu onder Likes & Favorieten.
            </i>
        </p>

        <h3>Disliken</h3>
        <p>
            De andere functie is het Disliken van een woning. Disliken gebruik je om kenbaar te maken dat jij een woning
            niet leuk vindt. Je wilt jouw woning niet met deze woning ruilen. Bij Liken hebben we uitgelegd dat je een
            bericht stuurt als je een woning Liked. Iemand anders kan ook jouw woning Liken. Dan ontvang jij een
            berichtje dat jouw woning geliked is. In het berichtje staat een link naar de woning van degene die jouw
            geliked heeft. Jij kunt deze woning bekijken. Ge&iuml;nteresseerd in de woning? Dan Like jij terug. Hierna
            leggen we uit wat er dan gebeurt. <b>Vind je deze niet leuk? Dan Dislike je deze woning.</b> Er wordt een
            automatisch een berichtje gestuurd dat jij liever niet wil ruilen. Dan weet de andere persoon ook dat jullie
            niet gaan ruilen.
        </p>

        <p>
            Onder het menu vind je een optie met Likes & Favorieten. Als je deze opent, zie je een overzicht van Mijn
            Likes. Dit zijn alle woningen die jij hebt geliked.
        </p>

        <h3>Likes & favorieten</h3>
        <p>
            Onder het menu bij Likes & favorieten zie je het overzicht met alle woningen die jij geliked hebt, met alle
            advertenties die jouw woning geliked hebben (ontvangen likes) en alle woningen die jij in je favorieten hebt
            gezet.
        </p>

        <h2>Mijn Woningruil</h2>
        <p>
            Op het moment dat de aanbieder van een woning en jij allebei elkaars woning hebben geliked kun je kiezen om
            een Match tot stand te brengen. Wat is een match? Bij een match vind je elkaars woning interessant, doordat
            jij de woning van iemand anders hebt geliked en de andere persoon jouw woning heeft geliked. Druk op de knop
            Ik wil ruilen om de woningruil samen te gaan regelen.
        </p>

        <p>
            Je komt bij Mijn Woningruil. Bij Mijn Woningruil regel je alle belangrijke zaken om de woningruil te gaan
            regelen. Wat moet je regelen?
        </p>
        <ol>
            <li>
                Bekijk de woning van elkaar. Zorg dat je allebei enthousiast blijft.
            </li>
            <li>
                Regel de juiste documenten voor de aanvraag (bijv. verhuurderverklaring en inkomen)
            </li>
        </ol>

        <p>
            Allebei nog steeds enthousiast? Dien de aanvraag bij de woningcorporatie in. Dit doe je door op de knop Dien
            mijn aanvraag in te klikken. Dan wordt de aanvraag ingediend bij de juiste corporatie. Hiervoor hoef jij
            niets te doen.
        </p>

        <p>
            De corporatie beoordeelt jouw aanvraag en geeft wel of geen goedkeuring. Geen goedkeuring? Je ontvangt
            hiervan een bericht en de woningruil wordt afgebroken. Wel goedkeuring? De corporatie regelt met jou een
            nieuw huurcontract, waarmee de woningruil een feit wordt.
        </p>

        <p>
            <i>
                Let op: Je kunt de match altijd afbreken als je denkt dat een match voor woningruil toch niet tot stand
                komt. Weet ook dat bij een lopende match in Mijn Woningruil je op dat moment niet meer kan Liken.
            </i>
        </p>

        <h2>Wat kun je nog meer?</h2>
        <p>Ruilmijnwoning.nl heeft nog een paar andere functies beschikbaar.</p>

        <p>
            <u>Filteren</u>: hiermee kun jij eenvoudig filteren in de lijst met woningadvertenties. Je maakt de lijst
            korter door filters op te geven.
        </p>

        <p>
            <u>Mijn zoekprofiel</u>: Bij het account aanmaken heb je een zoekprofiel opgegeven. Wil je het profiel
            aanpassen? Dat kun je via Mijn zoekprofiel je zoekwensen wijzigen.
        </p>

        <p>
            <u>Favorieten</u>: Met het hartje zet je woningen in jouw favorieten overzicht. Het is jouw persoonlijke
            overzicht en er wordt geen bericht gestuurd op basis van je favorieten.
        </p>
        <p>
            <u>Accountgegevens</u>: via accountgegevens kun je persoonlijke gegevens van het account wijzigen, aanvullen
            of verwijderen.
        </p>
    </>
)

import InputLabel from "@material-ui/core/InputLabel";
import Input from "component/form/field/Input";
import React from "react";
import {Form, FormProps, FormRenderProps} from "react-final-form";
import {Trans} from "react-i18next";
import Login from "types/Login";
import {composeRules, email, makeValidator, required} from 'validate';
import FieldRequiredAsterisk from "component/FieldRequiredAsterisk";
import Grid from "@material-ui/core/Grid";
import Submit from "./form/field/Submit";
import {Link as RouterLink} from "react-router-dom";
import Link from "@material-ui/core/Link";
import {Box} from "@material-ui/core";

type Props = FormProps<Login> & {
    title: string
}

const validator = makeValidator<Login>({
    email: composeRules({
        'Verplicht invullen': required,
        'Dit veld moet een e-mail adres zijn': email
    })
});

export default ({title, ...props}: Props) => (
    <Form validate={validator} initialValues={{ email: ''}} {...props}>
        {({handleSubmit}: FormRenderProps) => (
            <form onSubmit={handleSubmit}>
                <InputLabel>
                    <Trans>E-mailadres</Trans>
                    <FieldRequiredAsterisk/>
                </InputLabel>
                <Input name="email" type="email"/>
                <Grid container spacing={2}>
                    <Grid item xs>
                        <Submit title={title}/>
                    </Grid>
                </Grid>
                <Box textAlign="center">
                    Nog geen account? <Link component={RouterLink} to="/register">Registreer je hier.</Link>
                    <br/>
                    <br/>
                </Box>

            </form>
        )}
    </Form>
);

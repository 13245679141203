import MessageDislike from "component/action/Message/Dislike";
import MessageLike from "component/action/Message/Like";
import MessageLikeBack from "component/action/Message/LikeBack";
import MessageMatchCancelled from "component/action/Message/MatchCancelled";
import MessageMatchStart from "component/action/Message/MatchStart";
import MessageUnlike from "component/action/Message/Unlike";
import MessageCard from "component/MessageCard";
import Modal from "component/Modal";
import {Endpoint} from "lib/api";
import {useApi} from "lib/useApi";
import React, {FC} from "react";
import Message from "types/Message";
import {MessageType} from "types/MessageType";
import TMap from "types/TMap";

type Props = {
    message: Message;
}

const Types: TMap<FC<{message: Message; onClose: () => void}>> = {
    [MessageType.MatchCancelled]: MessageMatchCancelled,
    [MessageType.MatchStart]: MessageMatchStart,
    [MessageType.Match_start]: MessageMatchStart,
    [MessageType.Dislike]: MessageDislike,
    [MessageType.Unlike]: MessageUnlike,
    [MessageType.LikeBack]: MessageLikeBack,
    [MessageType.Like]: MessageLike,
    "like": MessageLike,
};

export default ({message}: Props) => {
    const {id, message: {type}} = message;
    const [, open] = useApi<string>(Endpoint.messages, {
        method: "PUT",
        data: {message: id},
        initialData: ""
    });

    const MessageAction = Types[type];

    return (
        <Modal
            button={(onOpen) => (
                <MessageCard
                    onClick={() => open().then(onOpen)}
                    message={message}
                />
            )}
            children={onClose => (
                <MessageAction
                    message={message}
                    onClose={onClose}
                />
            )}
        />
    );
};
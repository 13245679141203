export enum StaticPages {
    customerService = "customer-service",
    exchangeMyHome = "exchange-my-home",
    howDoesItWork = "how-does-it-work",
    usefulTips = "useful-tips",
    inFourSteps = "in-four-steps-to-another-home",
    howToCreateAccount = "how-do-i-create-an-account",
    createYourAd = "create-your-ad",
    showInterestInAProperty = "show-interest-in-a-property",
    arrangeHomeExchange = "arrange-a-home-exchange",
    privacyStatement = "privacy",
    fittingRules = "fitting-rules",
    webinar = "webinar",
    paymentDone = "payment-done",
}
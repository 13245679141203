import {TextField} from "@material-ui/core";
import {DatePicker, DatePickerProps} from "@material-ui/pickers";
import React from "react";
import {useField} from "react-final-form";
import {Trans} from "react-i18next";

type Props = Partial<DatePickerProps> & {
    name: string;
}

export default ({name, ...props}: Props) => {
    const {input, meta} = useField<Date>(name);

    return (
        <DatePicker
            inputFormat="dd-LL-yyyy"
            renderInput={(props) => (
                <TextField
                    fullWidth
                    margin="dense"
                    size="small"
                    variant="outlined"
                    {...props}

                    error={(meta.touched && meta.invalid) || props.error}
                    helperText={meta.touched ? (<Trans>{meta.error}</Trans> || meta.submitError) : props.helperText}
                />
            )}

            {...props}
            {...input}
            value={input.value || null}
        />
    );
}

import React from "react";

export default () =>
    (
        <>
            <h1>Disclaimer en privacy statement Ruilmijnwoning.nl© - 2022</h1>

            <h3>Uitsluiting van aansprakelijkheid</h3>
            <p>
                Aan de informatie op deze website ruilmijnwoning.nl kunnen geen rechten worden ontleend. De beheerder is
                niet verantwoordelijk voor inhoud van de advertenties en de verstrekte informatie over de ruilwoningen.
                ServiceSites International BV aanvaardt geen enkele verantwoordelijkheid voor schade op welke manier
                dan ook ontstaan door gebruik, onvolledigheid of onjuistheid van de aangeboden informatie op deze
                website.
            </p>

            <h3>Beschikbaarheid</h3>
            <p>
                De informatie en procedures op deze website kunnen zonder voorafgaande waarschuwing of kennisgeving
                worden gewijzigd. We spannen ons in om deze website zo veel mogelijk beschikbaar te stellen, maar wij
                aanvaarden geen enkele aansprakelijkheid voor eventuele gevolgen van (tijdelijke) niet-beschikbaarheid.
            </p>

            <h3>Privacy</h3>
            <p>
                Als u zich inschrijft op ruilmijnwoning.nl, dan verstrekt u persoonlijke gegevens en documenten. Deze
                gegevens zijn noodzakelijk voor het matchen met een andere aanbieder van een ruilwoning en voor het
                beoordelen van de woningruil door de sociale verhuurder(s). Wij gaan zorgvuldig, veilig en vertrouwelijk
                met de verstrekte gegevens om. Verwerking van persoonsgegevens vindt plaats onder naleving van de
                voorwaarden zoals vermeld in de geldende en relevante wet- en regelgeving ten aanzien van
                Persoonsgegevens (thans de AVG).
            </p>
            <p>
                Uw persoonsgegevens worden alleen gebruikt voor doeleinden die hieronder genoemd worden. Uw
                persoonsgegevens worden niet langer bewaard dan nodig is voor deze doeleinden, en maximaal 1 jaar na uw
                uitschrijving verwijderd uit onze systemen.
            </p>
            <p>
                Als u documenten uploadt ten behoeve van een woningruil kunt u deze zelf te allen tijde beheren en
                verwijderen, behalve wanneer deze documenten onderdeel uitmaken van een ingestuurde aanvraag voor een
                woningruil. n: U bent zelf verantwoordelijk voor de inhoud van de documenten.
                Wij adviseren om uw burgerservicenummer (BSN) af te dekken en onzichtbaar te maken. Met het uploaden van
                documenten geeft u toestemming om deze ook te delen met de verhuurder(s).
                Bij uitschrijving worden alle door u geüploade documenten direct verwijderd uit
                ruilmijnwoning.nl.
            </p>
            <p>
                Met het opgeven van uw persoonlijke gegevens verleent u automatisch toestemming om deze persoonlijke
                gegevens te verwerken. Deze gegevens kunnen, ter ondersteuning van onze bedrijfsvoering, gedeeld worden
                met andere instellingen.
            </p>

            <h3>Doeleinden</h3>
            <p>
                Het primaire doel van de website ruilmijnwoning.nl is het faciliteren van het bijeenbrengen van vraag
                en aanbod van sociale huurwoningen ten behoeve van woningruil. Onder faciliteren wordt verstaan het
                gehele proces vanaf het inschrijven als geïnteresseerde tot aan het, gezamenlijk met een andere
                woningruiler, indienen van een verzoek tot woningruil bij de sociale verhuurder. Dit proces bevat
                elementen als het adverteren van de eigen huurwoning, het reageren op aangeboden ruilwoningen, het
                uitwisselen van gegevens met andere woningruilers en het indienen van een aanvraag bij de sociale
                verhuurders inclusief het aanleveren van benodigde documenten.
            </p>

            <h3>Hoe verwijder ik mijn gegevens?</h3>
            <p>
                U kunt uw gegevens verwijderen door uw account te verwijderen. Dit doet u door in te loggen en onder
                'mijn account' op 'account verwijderen' te drukken. Kunt u niet meer inloggen of wilt u zich beroepen
                op het recht om vergeten te worden? Stuur dan een e-mail naar helpdesk@ruilmijnwoning.nl vanaf het e-mail
                adres waar u zich mee ingeschreven heeft. We zullen uw gegevens dan binnen 2 weken verwijderen. Voor
                het verbreken van uw Facebook koppeling kunt u gebruik maken van de Facebook instelling. Ga hiervoor naar
                Facebook, kies voor 'instellingen', dan 'apps en website' en verwijder uw RuilMijnWoning koppeling.
            </p>
        </>
    )

import React from "react";
import {useFormState} from "react-final-form";
import Snackbar from "@material-ui/core/Snackbar";

export default () => {
    const [open, setOpen] = React.useState(false);
    const errorRef = React.useRef();
    const {submitError} = useFormState();

    React.useEffect(() => {
        if (errorRef.current !== submitError) {
            errorRef.current = submitError;

            submitError && setOpen(true);
        }

        const timeout = setTimeout(() => {
            setOpen(false);
        }, 10000);

        return () => clearTimeout(timeout);
    }, [submitError]);

    return <Snackbar open={open} message={submitError}/>;
}

import Box from "@material-ui/core/Box";
import React from "react";
import ForgotBox from "../component/ForgotBox";

export default () => {
    return (
        <Box pt={2}>
            <ForgotBox
                title="Wachtwoord vergeten?"
                description="Vul je e-mailadres in. We sturen je per e-mail een link om je wachtwoord opnieuw in te stellen."
            />
        </Box>
    );
};

import {Box} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {DateTime} from "luxon";
import React from "react";
import {Trans} from "react-i18next";
import FullAdvert from "types/FullAdvert";

type Props = {
  advert: FullAdvert
}

export default ( {advert: {pubdate, viewed = 0}}: Props ) => {
  const start = DateTime.fromSQL( pubdate );
  const days = Math.ceil( DateTime.local().diff( start ).as( "days" ) );

  if ( !start.isValid ) {
    return null;
  }

  const formatted = start.toFormat( "dd LLLL yyyy" );
  return (
      <Box pt={2}>
        <Typography variant="body1">
          <Trans values={{viewed, formatted, days}}>
            Aangeboden sinds {{formatted}}. {{viewed}}x bekeken in de
            afgelopen {{days}} dagen.
          </Trans>
        </Typography>
      </Box>
  );
};
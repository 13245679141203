import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import Fab from "@material-ui/core/Fab";
import Grid from "@material-ui/core/Grid";
import {Theme} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import FiltersModal from "component/action/Filters";
import AdvertList from "component/AdvertList";
import FiltersForm from "component/form/Filters";
import HomeBanner from "component/HomeBanner";
import Loader from "component/Loader";
import VisibilitySensor from "component/VisibilitySensor";
import React, {useCallback, useMemo} from "react";
import {Trans} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {setAdvertsFilter} from "redux/action";
import {LOAD_MORE_ADVERTS} from "redux/constant";
import {getAdverts, getAdvertsCount, isAdvertsLoading} from "redux/selector";

export default () => {
    const upMd = useMediaQuery(({breakpoints}: Theme) => breakpoints.up("md"));

    const count = useSelector(getAdvertsCount);
    const adverts = useSelector(getAdverts);
    const isLoading = useSelector(isAdvertsLoading);
    const dispatch = useDispatch();

    const title = useMemo(() => (
        <>
            <HomeBanner />
            <Box textAlign={{xs: "center", md: "left"}}>
                <Typography paragraph variant="h1">
                    <Trans
                        values={{count}}
                        children="Er zijn {{count}} woningen om te ruilen"
                    />
                </Typography>
            </Box>
        </>
    ), [count]);

    const loadMore = useMemo(() => {
        if (adverts.length >= count) {
            return null;
        }

        const onLoadMore = () => {
            if (!isLoading) {
                dispatch({type: LOAD_MORE_ADVERTS});
            }
        };

        return (
            <Box pt={4} display="flex" justifyContent="center">
                <VisibilitySensor onToggle={inView => inView && onLoadMore()} />
                <Fab color="inherit" variant="extended" size="small" onClick={onLoadMore} disabled={isLoading}>
                    {isLoading ? <CircularProgress size={12} /> : <Trans>Toon meer</Trans>}
                </Fab>
            </Box>
        );
    }, [isLoading, dispatch, count, adverts.length]);

    const onFiltersChange = useCallback(values => dispatch(setAdvertsFilter(values)), [dispatch]);

    if (isLoading && !adverts.length) {
        return <Loader />;
    }

    if (upMd) {
        return (
            <Box pt={2}>
                <Grid container spacing={4}>
                    <Grid item md={4}>
                        <FiltersForm onSubmit={onFiltersChange} />
                    </Grid>
                    <Grid item xs={12} md={8}>
                        {title}

                        <Box pt={2} pb={2}>
                            <AdvertList adverts={adverts} />

                            {loadMore}
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        );
    }

    return (
        <Box pt={2}>
            {title}

            <FiltersModal onSubmit={onFiltersChange} />

            <Box pt={2} pb={2}>
                <AdvertList adverts={adverts} />

                {loadMore}
            </Box>
        </Box>
    );
};
import { Box, Chip, Paper, Tooltip, Typography, Button, Modal, TextField, Grid, IconButton, Dialog, DialogTitle, DialogContent, DialogActions } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
import DeleteTwoToneIcon from '@material-ui/icons/DeleteTwoTone';
import { VisibilityTwoTone, CloseOutlined } from '@material-ui/icons';
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import CheckIcon from "component/icon/Check";
import CancelIcon from '@material-ui/icons/Cancel';
import { Endpoint } from "lib/api";
import { useApi } from "lib/useApi";
import React, { useState } from "react";
import { UploadQuestion } from "types/TradeResponse";
import Plus from "component/icon/Plus";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { DateTime } from "luxon";


interface IProps extends UploadQuestion {
  index: React.ReactNode;
  setChecked: (checked: boolean) => void;
  checked: boolean;
  documents: UploadQuestion[]
}

const useStyles = makeStyles(({ spacing }: Theme) => createStyles({
  root: {
    display: "grid",
    gridTemplateColumns: "max-content max-content",
    columnGap: spacing(),
    alignItems: "center",
    marginBottom: spacing(2),
    position: "relative",
  },
  customChip: {
    position: 'relative',
    borderRadius: "4px",
    width: "412px",
    paddingTop: "16px",
    paddingBottom: "16px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  customChipRequiredIndicator: {
    position: 'absolute',
    top: '2px',
    right: '4px',
    color: 'red'
  },
  tableHead: {
    backgroundColor: "#198efd",
    color: "white",
    paddingTop: "5px",
    paddingBottom: "5px"
  },
  documentModalButton: {
    color: "white",
    position: "relative"
  },
  documentText: {
    backgroundColor: 'white',
    padding: '12px',
    width: '100%',
    color: 'black',
    fontSize: '16px'
  },
  iframeStyle: {
    position: 'absolute',
    top: '0',
    bottom: '0',
    height: '100vh',
    width: 'inherit',
    border: 'none'
  }
}));

export default ({ index, answer, title, order, checked, setChecked, documents, description, required }: IProps) => {
  const classes = useStyles();
  const [files, setFiles] = useState(documents);
  const [uploadDoc, setUploadDoc] = useState<any>();
  const [uploadPreviewUrl, setUploadPreviewUrl] = useState<string>('');
  const [docNote, setDocNote] = useState<string>('');
  const [showFiles, setShowFiles] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [loadingUpload, setLoadingUpload] = useState<boolean>(false);
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false);
  const [label, setLabel] = useState<string>('Klik hier om een toelichting of een naam toe te voegen');
  const [deleteError, setDeleteError] = useState<string>('');
  const defaultDate = DateTime.fromISO(new Date().toISOString()).toFormat("yyyy-LL-dd h:mm:ss a")

  const handleDeleteDialogOpen = () => {
    setOpenDeleteDialog(true)
  }

  const handleDeleteDialogClose = () => {
    setOpenDeleteDialog(false);
    setDeleteError('');
  }

  const [, getDocument] = useApi<any>(Endpoint.getTradeDocument, {
    autoTrigger: false,
    initialData: null,
  });

  const [, deleteDocument] = useApi<any>(Endpoint.deleteTradeDocument, {
    autoTrigger: false,
    initialData: null,
  });

  const [uploadDocumentState, uploadDocDoument] = useApi<any>(Endpoint.uploadTradeDocument, {
    autoTrigger: false,
    initialData: null,
  });

  const formatDate = (date: string): any => {
    const formattedDate = DateTime.fromFormat(date, "yyyy-LL-dd h:mm:ss a").toLocaleString(DateTime.DATETIME_SHORT)
    if (formattedDate !== 'Invalid DateTime') {
      return formattedDate;
    }
    return '';
  }

  const onChange = async (files: FileList | null) => {
    setUploadDoc(files)
    if (files !== null) {
      if (files[0].type !== "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
        let src = URL.createObjectURL(files[0]);
        setUploadPreviewUrl(src)
      }
    }
  };

  const fetchDocument = async (path: string) => {
    if (answer.path) {
      const { link } = await getDocument({
        params: { id: path.replace("/document/", "") },
      });

      if (link) {
        const a = document.createElement("a");
        a.target = "_blank";
        a.href = link;

        a.click();
      }
    }
  }

  const deleteDoument = async (path: string, index: number) => {
    setLoadingDelete(true)
    await deleteDocument({
      params: { id: path.replace("/document/", "") }
    }).then(data => {
      setLoadingDelete(false);

      const _files = [...files];
      _files.splice(index, 1)
      setFiles([..._files])

      setOpenDeleteDialog(false);
    }).catch(error => {
      setLoadingDelete(false)
      if (error) {

        //setDeleteError(error?.error)
        setDeleteError('Je kunt dit document niet verwijderen omdat je in een actieve ruil zit.')
      }
    });
  }

  const appendDocument = (link: string, note: string) => {
    const newDoc = {
      answer: {
        document: "",
        answered: 1,
        path: link,
        regdate: defaultDate,
        rejected: 0,
        statustext: 'Nog niet verwerkt'
      },
      content: "",
      comment: note,
      description: "",
      example: "",
      id: "",
      order,
      required: true,
      title,
      type: "upload",
    } as UploadQuestion;
    const newFiles = [
      newDoc,
      ...files,
    ]
    console.log(newFiles)
    setFiles(files && files.length > 0 ? newFiles : [newDoc])
  }

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (uploadDoc) {
      const body = new FormData();

      body.append("payload", uploadDoc[0]);
      body.append("comment", docNote);
      body.append("document", answer.document);
      setLoadingUpload(true)

      const response = await uploadDocDoument({
        body,
      });
      setLoadingUpload(false)

      if (response.url) {
        appendDocument(response.url, docNote);
        setChecked(true);
      }

      setDocNote('')
      setUploadDoc(null);
      setOpen(false);
      setUploadPreviewUrl('')
    }

  }

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setUploadPreviewUrl('')
    setUploadDoc(null);
  };

  return (
    <>
      <label className={classes.root}>
        {!!uploadDocumentState.error && <Snackbar open message={uploadDocumentState.error.error} />}
        {uploadDocumentState.loading ? <CircularProgress size={24} /> : index}
        <Chip
          clickable={true}
          onClick={() => setShowFiles(true)}
          variant={"outlined"}
          label={
            <span>
              <span>{title}</span>
              {required ? <span className={classes.customChipRequiredIndicator}>*</span> : ''}
            </span>
          }
          color={checked ? "primary" : "default"}
          className={classes.customChip}
          deleteIcon={checked ? <CheckIcon fontSize="small" /> : <></>}
          icon={checked ? <></> : <InsertDriveFileIcon
            style={{ color: "#198efd" }}
            fontSize="large"
          />}
        />
      </label>
      {showFiles && <Box mt={1} ml={2} mb={2}>
        <TableContainer component={Paper}>
          <Box px={2} py={1}>
            <div style={{ position: 'relative' }}>
              <Typography variant="h2">{title}</Typography>
              <span style={{ position: 'absolute', right: '0', top: '0' }} onClick={() => setShowFiles(false)}>
                <CancelIcon fontSize="large" />
              </span>
            </div>
          </Box>
          <Box px={2} pb={2}>
            <Typography>{description}</Typography>
          </Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="left" className={classes.tableHead}>Status</TableCell>
                <TableCell align="left" className={classes.tableHead}>Toelichting</TableCell>
                <TableCell align="left" className={classes.tableHead}>Gewijzigd</TableCell>
                <TableCell align="center" className={classes.tableHead}>
                  <Button size="small" className={classes.documentModalButton} onClick={handleOpen}>
                    <Plus />
                  </Button>
                  <Modal open={open} onClose={handleClose}>
                    <Box display="flex" justifyContent="center" alignItems="center">
                      <Box width="75%" height="100%">
                        {
                          uploadDoc &&
                          <>
                            <p className={classes.documentText}>{uploadDoc[0].name}</p>

                            <iframe src={uploadPreviewUrl} className={classes.iframeStyle} title="document preview"></iframe>
                          </>
                        }
                      </Box>
                      <Box width="25%" height="100%">
                        <div style={{ backgroundColor: 'white', height: '100vh' }}>
                          <Box p={2} display="flex" flexDirection="row-reverse">
                            <IconButton onClick={handleClose} color="primary">
                              <CloseOutlined />
                            </IconButton>
                          </Box>

                          <Grid container spacing={3}>
                            <Grid item xs={12}>
                              <form onSubmit={handleSubmit}>
                                <Box p={2}>
                                  <Button variant="contained">
                                    <label htmlFor="documentInput">Document toevoegen</label>
                                    <input
                                      type="file"
                                      accept=".jpg, .jpeg, .png, .gif, .bmp, .pdf, .bmp"
                                      style={{ display: 'none' }}
                                      id="documentInput"
                                      onChange={({ currentTarget: { files } }: React.ChangeEvent<HTMLInputElement>) => onChange(files)} />
                                  </Button>
                                </Box>

                                <Box px={2}>
                                  <Typography>
                                    Je kunt alleen afbeeldingen en PDF documenten uploaden.
                                    Vul hieronder een duidelijke naam in van het document.
                                    Dat is vooral belangrijk als je meerdere documtenten van hetzelfde type uploadt (
                                    zoals salarisstroken of inkomensverklaringen van jou én je partner
                                    )
                                  </Typography>
                                </Box>

                                <Box p={2}>
                                  <TextField
                                    variant="outlined"
                                    multiline
                                    label={label}
                                    onFocus={() => setLabel('Toelichting')}
                                    onBlur={() => setLabel('Klik hier om een toelichting of een naam toe te voegen')}
                                    style={{ width: '100%' }}
                                    value={docNote}
                                    onChange={(evt) => setDocNote(evt.target.value)} />
                                </Box>

                                {uploadDoc && <Box p={2}>
                                  <Button type="submit" variant="contained" color="primary">
                                    {loadingUpload && <CircularProgress size={20} style={{ color: 'white' }} />}
                                    {!loadingUpload && 'Opslaan'}
                                  </Button>
                                </Box>
                                }
                              </form>

                            </Grid>

                            <Grid item xs={12}>
                              <Box p={2}>
                              </Box>
                            </Grid>
                          </Grid>

                        </div>
                      </Box>
                    </Box>
                  </Modal>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {files.length > 0 && files.map((document, index) => {
                if (document.answer.path !== '') {
                  return <TableRow key={`document${index}`}>
                    <TableCell align="left">
                      <Typography variant="caption"> {document.answer.statustext} </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Box display="flex" alignItems="center">

                        <Box>
                          <Tooltip title={
                            <Typography>{document.comment}</Typography>
                          } placement="top" arrow>
                            <Typography variant="caption" style={{ cursor: 'pointer' }}>
                              {document.comment.substring(0, 10)} {document.comment ? '...' : ''}
                            </Typography>
                          </Tooltip>
                        </Box>

                      </Box>
                    </TableCell>
                    <TableCell align="left">{formatDate(document.answer.regdate)}</TableCell>
                    <TableCell align="right">
                      <Box display="flex" justifyContent="center">
                        <Box px={1} onClick={() => fetchDocument(document.answer.path)}>
                          <Tooltip title="Bekijk document">
                            <VisibilityTwoTone fontSize="large" style={{ cursor: 'pointer' }} />
                          </Tooltip>
                        </Box>

                        <Box px={1}>
                          <Tooltip title="Verwijder document">
                            <DeleteTwoToneIcon fontSize="large" onClick={handleDeleteDialogOpen} style={{ cursor: 'pointer' }} />
                          </Tooltip>
                        </Box>

                        <Dialog open={openDeleteDialog} onClose={handleDeleteDialogClose} aria-labelledby="form-dialog-title">
                          <DialogTitle id="form-dialog-title">Bevestigen verwijderen</DialogTitle>
                          <DialogContent>
                            <Typography>
                              Dit document en de notitie worden volledig verwijderd, als u er zeker van bent, klikt u op verwijderen of klikt u anders op annuleren om af te breken.
                            </Typography>
                            <Typography style={{ color: 'red', paddingTop: '8px' }}>{deleteError}</Typography>
                          </DialogContent>
                          <DialogActions>
                            <Button onClick={handleDeleteDialogClose} color="primary">
                              Annuleren
                            </Button>
                            <Button onClick={() => deleteDoument(answer.path, index)} color="secondary">
                              {loadingDelete && <CircularProgress size={20} />}
                              {!loadingDelete && 'Verwijderen'}
                            </Button>
                          </DialogActions>
                        </Dialog>

                      </Box>
                    </TableCell>
                  </TableRow>
                }
              }
              )}
              {files.length === 1 && answer.answered === 0 &&
                <TableRow>
                  <TableCell colSpan={3}>
                    <Typography color="primary" variant="body2">Klik op de + om document te uploaden</Typography>
                  </TableCell>
                </TableRow>
              }
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      }
    </>
  );
};
const DECIMAL = 1.1.toLocaleString().substring(1, 2);
export const formatFloat = (value: string) => {
    return (value || '')
        ?.toString()
        .replace(/[^0-9,.]*/g, '')
        .replace(/[,.]+/g, DECIMAL)
        .replace(/([,.][0-9][0-9]?).*$/, '$1')
        .replace(/^0([0-9])/, '$1');
}

export const formatNumber = (value: string) => {
    return (value || '')
        ?.toString()
        .replace(/[^0-9]*/g, '');
}

export const formatMoney = (value: number) => {
    return value.toFixed(2);
}
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Fab from "@material-ui/core/Fab";
import Link from "@material-ui/core/Link";
import Snackbar from "@material-ui/core/Snackbar";
import Typography from "@material-ui/core/Typography";
import Modal from "component/Modal";
import {Endpoint} from "lib/api";
import {useApi} from "lib/useApi";
import React from "react";
import {Trans} from "react-i18next";
import {useHistory} from "react-router-dom";

export default ( {id}: { id: string } ) => {
  const {replace} = useHistory();

  const [{error}, updateExchange] = useApi<any>( Endpoint.trade, {
    autoTrigger: false,
    initialData: null,
  } );

  const onDelete = React.useCallback( async () => {
    await updateExchange( {
      method: "DELETE",
      data: {advert: id},
    } );

    replace( "/" );
  }, [updateExchange, replace, id] );

  return (
      <Box mt={5}>
        {error && <Snackbar open message={error.error} />}
        <Typography variant="body1" gutterBottom>
          <Trans>Wil je toch afzien van de woningruil?</Trans>
        </Typography>
        <Modal
            button={onOpen => (
                <Link variant="body1" color="secondary" onClick={onOpen}>
                  <b><Trans>Annuleer woningruil</Trans></b>
                </Link>
            )}
            children={onClose => (
                <>
                  <Typography variant="h1" align="center" paragraph>
                    <Trans>
                      Je staat op het punt om de woningruil te annuleren.
                    </Trans>
                  </Typography>
                  <Typography paragraph align="center">
                    <Trans>
                      Als je annuleert wordt je woningruil verwijderd. Je kunt
                      verder met het zoeken naar een nieuwe ruilwoning. De
                      andere persoon ontvangt een bericht.
                    </Trans>
                  </Typography>
                  <Typography align="center">
                    Weet je zeker dat je wilt annuleren?
                  </Typography>

                  <Box pt={2} textAlign="center">
                    <Fab
                        color="primary"
                        variant="extended"
                        size="medium"
                        onClick={onDelete}
                    >
                      <Trans>Ja</Trans>
                    </Fab>
                    <Button variant="text" onClick={onClose}>
                      <Trans>Nee</Trans>
                    </Button>
                  </Box>
                </>
            )}
        />
      </Box>
  );
};
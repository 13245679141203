import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Fab from "@material-ui/core/Fab";
import Typography from "@material-ui/core/Typography";
import CheckAvailabilityForm from "component/form/CheckAvailability";
import {FORM_ERROR} from "final-form";
import {Endpoint} from "lib/api";
import {useApi} from "lib/useApi";
import React, {useState} from "react";
import {Trans} from "react-i18next";
import AvailabilityForm from "types/AvailabilityForm";
import AvailabilityResponse from "types/AvailabilityResponse";
import Profile from "types/Profile";

type Props = {
    onSuccess: () => void;
    onError: () => void;
    profile: Profile;
}

const getStatusTitle = ({corporation: {id}}: AvailabilityForm, {allowed, corporation}: AvailabilityResponse) => {
    if (allowed) {
        if (!corporation || !corporation.id) {
            return "Waarschijnlijk mag je ruilen.";
        }

        return "Ja, ruilen is mogelijk!";
    }

    return "Helaas, je mag niet ruilen.";
};

const getStatusContent = ({corporation: {id}}: AvailabilityForm, {allowed, corporation}: AvailabilityResponse) => {
    let content = `
            Het spijt ons. <br/><br/>
            Jouw corporatie heeft bepaald dat jouw woning niet geruild mag worden.<br/><br/> 
            Daarom kun je ook geen account aanmaken. Het heeft ook geen nut, want je kunt niet ruilen.<br/>
            <br/>
            Voor vragen of meer informatie kun je contact opnemen met je corporatie.
    `;

    /* Leonard: We're not doing a specific message for changing the corp. */
    /*if (allowed && corporation.id && id && corporation.id === id) {*/
    if (allowed && corporation.id && id) {
        content = `
            Jouw verhuurder doet mee en jouw woning mag aangemeld worden voor woningruil.<br/>
            <br/>
            Je kunt nu je profiel compleet maken door je eigen gegevens en woonwensen in te vullen!<br/>
            <br/> 
            Als laatste moet je jouw woning adverteren en online zetten. Jouw verhuurder heeft al gegevens doorgegeven van jouw woning.<br/>
            Die hoef je niet meer in te vullen.<br/>
            <br/>
            Als je zelf de rest van de gegevens hebt ingevuld, kun je op zoek naar je nieuwe woning.<br/>
            <br/>
            Veel succes!
        `;
    }

    if (allowed && (!corporation || !corporation.id)) {
        content = `
            Je kunt een account aanmaken en jouw woning via RuilMijnWoning adverteren.<br/><br/>
            Waarschijnlijk mag je jouw woning ruilen met een andere huurwoning, maar wij kunnen dat niet controleren en we kunnen je niet helpen bij het in orde maken van jouw aanvraag. Want jouw corporatie neemt nog niet deel aan RuilMijnWoning of heeft jouw woning nog niet doorgegeven.<br/><br/>  
            Als je een geschikte ruilkandidaat hebt gevonden, vraag dan eerst bij jouw woningcorporatie na waaraan de aanvraag voor jouw corporatie moet voldoen en ook welke documenten je moet aanleveren. Dit om teleurstelling en verspilde energie aan het einde van het ruilproces te voorkomen.
        `;
    }

    return (
        <Typography variant="body1" paragraph>
            <Trans>{content}</Trans>
        </Typography>
    );
};

export default ({onSuccess, onError, profile}: Props) => {
    /*const [translate] = useTranslation();*/
    const [{data: response}, load] = useApi<AvailabilityResponse | null>(Endpoint.check, {
        initialData: null,
    });

    const [values, setValues] = useState<AvailabilityForm>(() => {
        const values = {
            // zipcode: "1011PG",
            // corporation: {
            //     name: "Woningbouwvereniging Beter Wonen",
            //     id: "015E2CE8-9F1D-42BD-AA03-2A5E2DFCBB37"
            // },
            // housenumber: "22"

            zipcode: "",
            corporation: {
                name: "",
                id: "",
            },
            housenumber: "",
        };

        if (profile.corporation) {
            values.corporation = {
                id: profile.corporation.id,
                name: profile.corporation.name,
            };
        }

        if (profile.address) {
            values.zipcode = profile.address.zipcode;
            values.housenumber = profile.address.number;
        }

        return values;
    });

    const onSubmit = async (values: AvailabilityForm) => {
        try {
            setValues(values);

            await load({
                query: new URLSearchParams({
                    ...values,
                    corporation: values.corporation.id,
                }),
            });
        } catch (e) {
            return {
                [FORM_ERROR]: 'Het adres wat u in heeft gevoerd wordt niet herkend. Controleer uw adres en verhuurder. Staat uw verhuurder niet in de lijst? Dan kunt U niet ruilen via RuilMijnWoning.',
            };
        }
    };

    if (response) {
        return (
            <>
                <Box p={2} textAlign="center">
                    <Typography variant="h1" paragraph>
                        <Trans>{getStatusTitle(values, response)}</Trans>
                    </Typography>
                    {getStatusContent(values, response)}
                </Box>
                <Box p={2} textAlign="center">
                    {response.allowed && (
                        <Fab color="primary"
                             variant="extended"
                             size="medium"
                             onClick={onSuccess}>
                            <Trans>Ik ga verder</Trans>
                        </Fab>
                    )}
                    {!response.allowed && (
                        <Button variant="text" onClick={onError}>
                            <Trans>Terug</Trans>
                        </Button>
                    )}
                </Box>
            </>

        );
    }

    return (
        <CheckAvailabilityForm
            initialValues={values}
            onSubmit={onSubmit}
        />
    );
};

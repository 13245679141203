import { Box } from "@material-ui/core";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { Trans } from "react-i18next";
import FullAdvert from "types/FullAdvert";

interface IProps {
    advert: FullAdvert
}

export default ({advert: {landlord}}: IProps) => (
    <Box pt={5}>
        <Typography variant="h2" paragraph>
            <Trans>Verhuurder</Trans>
        </Typography>
        <Typography variant="h3" gutterBottom>
            {landlord.name}
        </Typography>

        {landlord.website && (
            <Link href={landlord.website} variant="body1" target="_blank" rel="noopener noreferrer">
                <b>{landlord.website}</b>
            </Link>
        )}
        <br/>
        {landlord.contact && landlord.contact.telephone && (
            <Link href={`tel:${landlord.contact.telephone}`} variant="body1" target="_blank" rel="noopener noreferrer">
                <b>{landlord.contact.telephone}</b>
            </Link>
        )}

        <Typography>
            <Trans>Ma t/m vr. 8:00 – 17:00 uur</Trans>
        </Typography>
    </Box>
);
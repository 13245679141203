import React from "react";
import {Redirect} from "react-router-dom";
import Message from "types/Message";

type Props = {
    onClose: () => void;
    message: Message;
}

export default ({message: {sender: {advert}}}: Props) => (
    <Redirect to={`/view/${advert}`} />
);
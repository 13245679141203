import Box from "@material-ui/core/Box";
import OAuthLoginBox from "component/OAuthLoginBox";
import React from "react";

export default () => {
    return (
        <Box pt={2}>
            <OAuthLoginBox
                title="Inloggen"
                description="Je kunt inloggen met je Ruilmijnwoning.nl account of met het account van Google, Facebook, of je Woonmatch account. <br/> Let op: Gebruik voor het inloggen altijd hetzelfde account. Heb je een account aangemaakt met Google? Dan moet je met Google inloggen. Hetzelfde geldt voor Facebook, Woonmatch, of je eigen account."
            />
        </Box>
    );
};

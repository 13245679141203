import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import {makeStyles} from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import React, {ChangeEvent, useCallback} from "react";
import {useField} from "react-final-form";
import FilterField from "types/FilterField";

const useStyles = makeStyles({
    item: {
        marginLeft: 0,
        marginRight: -3,
        marginBottom: 3,
        justifyContent: "space-between"
    }
});

type Props = {
    name: string;
    available: FilterField[];
}

export default ({name, available}: Props) => {
    const classes = useStyles();
    const {input} = useField<string[], HTMLAnchorElement>(name, {
        multiple: true
    });

    const onToggle = useCallback(({currentTarget: {value, checked}}: ChangeEvent<HTMLInputElement>) => {
        input.onChange(
            !checked ?
                input.value.filter(v => v !== value) :
                [...input.value, value]
        );
    }, [input]);

    return (
        <FormGroup>
            {available.map(tag => {
                const isActive = input.value.indexOf(tag.id) > -1;

                return (
                    <FormControlLabel
                        key={tag.id}
                        className={classes.item}

                        label={tag.name}
                        labelPlacement="start"

                        control={(
                            <Switch
                                color="primary"
                                value={tag.id}
                                checked={isActive}
                                onChange={onToggle}
                            />
                        )}
                    />
                );
            })}
        </FormGroup>
    );
}
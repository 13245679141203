import {DEFAULT_LANG} from "data/config";
import i18n from "i18next";
import {Settings as LuxonSettings} from "luxon";
import {initReactI18next} from "react-i18next";
import {matchPath} from "react-router-dom";

let lang = DEFAULT_LANG;
const match = matchPath<{ lang: string }>( window.location.pathname, {
  path: "/:lang(en|nl)",
} );

if ( match ) {
  lang = match.params.lang;
}

i18n.on( "languageChanged", function( lng ) {
  LuxonSettings.defaultLocale = lng;
} );

i18n
    .use( initReactI18next )
    .init( {
      lng: lang,
      fallbackLng: DEFAULT_LANG,

      resources: {
        nl: {
          translation: {
            "Invalid Field Format": "Geef je postcode met 4 cijfers en 2 hoofdletters.",
            "Missing Fields": "Je hebt niet alle velden ingevuld. Vul de verplichte velden in.",
          },
        },
      },

      debug: false,

      saveMissing: true,
      interpolation: {
        escapeValue: false,
      },
    } );

export {i18n};
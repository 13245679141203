import {Typography} from "@material-ui/core";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import DashIcon from "component/icon/Dash";
import React from "react";
import {CorpQuestion} from "types/TradeResponse";

interface IProps extends CorpQuestion {
  index: React.ReactNode;
}

const useStyles = makeStyles( ( {spacing}: Theme ) => createStyles( {
  root: {
    display: "grid",
    gridTemplateColumns: "max-content max-content 1fr",
    columnGap: spacing(),
    alignItems: "center",
    marginBottom: spacing(2),
  },
  content: {
    gridColumn: 3,
  },
} ) );

export default ( {index, title, content}: IProps ) => {
  const classes = useStyles();

  return (
      <label className={classes.root}>
        {index}
        <DashIcon />
        <Typography variant="h4">{title}</Typography>
        <Typography variant="body2" className={classes.content}>
          {content}
        </Typography>
      </label>
  );
};
import {Endpoint, makeRequest, RequestParams} from "lib/api";
import throwError from "lib/throwError";
import {useCallback, useLayoutEffect, useRef, useState} from "react";

type ValidationError = {
  message: string;
  error?: string;
  data?: string[];
}

type State<R> = {
  loaded: boolean;
  loading: boolean;
  data: R;
  error: ValidationError;
  controller: AbortController;
  reset: () => void;
};

type Response<R> = [
  State<R>,
  ( params?: RequestParams ) => Promise<R>
];

export type Options<R> = RequestParams & {
  autoTrigger?: boolean,
  initialData: R
}

export const useApi = <R>( input: Endpoint, {autoTrigger = false, initialData, ...defParams}: Options<R> ): Response<R> => {
  const [data, setData] = useState<R>( initialData );
  const [loaded, setLoaded] = useState<boolean>( false );
  const [loading, setLoading] = useState<boolean>( false );
  const [error, setError] = useState<any>( null );
  const [controller, setController] = useState( new AbortController() );

  const isMounted = useRef( false );

  /* eslint-disable react-hooks/exhaustive-deps */
  const goRequest = useCallback( async ( params?: RequestParams ): Promise<R> => {
    if ( isMounted.current ) {
      setLoading( true );
      setError( null );
    }

    try {
      const args = Object.assign( {}, defParams, params, {
        signal: controller.signal,
      } );

      const resp = await makeRequest<R>( input, args );

      if ( isMounted.current ) {
        setData( resp );
      }

      return resp;
    } catch ( e ) {
      if ( isMounted.current && e.name !== "AbortError" ) {
        setError( e );
      }

      return throwError( e );
    } finally {
      if ( isMounted.current ) {
        setLoading( false );
        setLoaded( true );

        setController( new AbortController() );
      }
    }
  }, [input] );

  useLayoutEffect( () => {
    if ( autoTrigger ) {
      goRequest();
    }

    isMounted.current = true;
    return () => {
      isMounted.current = false;

      controller.abort();
    };
  }, [] );

  const reset = useCallback( () => {
    setData( initialData );
    setLoaded( false );
    setLoading( false );
    setError( null );
    controller.abort();
  }, [] );
  /* eslint-enable react-hooks/exhaustive-deps */

  return [
    {
      data,
      loading,
      loaded,
      error,
      controller,
      reset,
    },

    goRequest,
  ];
};
import InputLabel from "@material-ui/core/InputLabel";
import Tags from "component/form/field/Tags";
import Toggles from "component/form/field/Toggles";
import React from "react";
import {useField} from "react-final-form";
import {Trans} from "react-i18next";
import FilterExtra from "types/FilterExtra";
import {FilterExtraType} from "types/FilterExtraType";

type Props = {
    name: string;
    extras: FilterExtra[];
}

export default ({name, extras}: Props) => {
    const {input} = useField<string[]>(name, {
        multiple: true
    });

    return (
        <>
            {extras.map(extra => (
                <React.Fragment key={extra.id}>
                    <InputLabel><Trans>{extra.name}</Trans></InputLabel>

                    {extra.type === FilterExtraType.tag && (
                        <Tags
                            name={input.name}
                            available={extra.children}
                            showIcons
                            multiple
                        />
                    )}

                    {extra.type === FilterExtraType.toggle && (
                        <Toggles
                            name={input.name}
                            available={extra.children}
                        />
                    )}
                </React.Fragment>
            ))}
        </>
    );
};
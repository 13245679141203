import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import Fab from "@material-ui/core/Fab";
import Typography from "@material-ui/core/Typography";
import MessageAction from "component/action/Message";
import Loader from "component/Loader";
import VisibilitySensor from "component/VisibilitySensor";
import {PER_PAGE} from "data/config";
import {Endpoint} from "lib/api";
import {useApi} from "lib/useApi";
import React, {useEffect, useState} from "react";
import {Trans} from "react-i18next";
import MessagesState from "types/MessagesState";

type Pagination = {
    start: number;
    limit: number;
}

export default () => {
    const [{loading, data}, load] = useApi<MessagesState | null>(Endpoint.messages, {
        initialData: null
    });

    const [pagination, setPagination] = useState<Pagination>({
        start: 0,
        limit: PER_PAGE
    });

    useEffect(() => {
        const query = new URLSearchParams();
        query.append("start", String(pagination.start));
        query.append("limit", String(pagination.limit));

        load({
            query
        });
    }, [pagination, load]);

    if (!data || loading) {
        return <Loader />;
    }

    const {received, sent, total} = data;

    const onLoadMore = () => {
        if (received.length < total - sent.length) {
            setPagination({
                start: pagination.start,
                limit: pagination.limit + PER_PAGE
            });
        }
    };

    return (
        <>
            <Box textAlign="center" pt={2}>
                <Typography variant="h1" gutterBottom>
                    <Trans>Berichten</Trans>
                </Typography>
            </Box>

            {received.map(message => (
                <MessageAction
                    key={message.id}
                    message={message}
                />
            ))}

            {received.length < total - sent.length && (
                <Box pt={4} display="flex" justifyContent="center">
                    <VisibilitySensor onToggle={inView => inView && onLoadMore()} />
                    <Fab color="inherit" variant="extended" size="small" onClick={onLoadMore} disabled={loading}>
                        {loading ? <CircularProgress size={12} /> : <Trans>Toon meer</Trans>}
                    </Fab>
                </Box>
            )}
        </>
    );
};
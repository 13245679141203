import Select, {SelectProps} from "component/form/field/Select";
import React from "react";
import FilterField from "types/FilterField";

const options: FilterField[] = [
    {
        name: "Onbekend",
        id: "Unknown"
    },
    {
        name: "A",
        id: "A"
    },
    {
        name: "B",
        id: "B"
    },
    {
        name: "C",
        id: "C"
    },
    {
        name: "D",
        id: "D"
    },
    {
        name: "E",
        id: "E"
    },
    {
        name: "F",
        id: "F"
    },
    {
        name: "G",
        id: "G"
    },
    {
        name: "X",
        id: "X"
    }
];

type Props = Omit<SelectProps, "options"> & {
    readOnly?: boolean;
};

export default ({readOnly, ...props}: Props) => (
    <Select
        {...props}
        options={options}
        readOnly={readOnly}
    />
);
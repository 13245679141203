import Chip from "@material-ui/core/Chip";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import React, {MouseEvent, ReactNode} from "react";
import {useHistory} from "react-router-dom";
import {LikesType} from "types/LikesType";

const useStyles = makeStyles(({spacing, palette}: Theme) => createStyles({
    root: {
        position: "relative",
        "&:before": {
            content: "''",
            position: "absolute",
            width: "100%",
            top: "calc(50% - 1px)",
            left: 0,
            borderTop: `1px solid ${palette.divider}`
        }
    },
    wrapper: {
        position: "relative",
        background: palette.common.white,
        margin: "auto",
        display: "inline-block"
    },
    withMargin: {
        position: "relative",
        marginRight: spacing(),
        "&:after": {
            content: "''",
            position: "absolute",
            left: "calc(100% + 1px)",
            top: "calc(50% - 1px)",
            width: spacing(),
            borderTop: `1px solid ${palette.divider}`
        }
    }
}));

type Props = {
    loading: boolean;
    items: {label: ReactNode, type: LikesType}[],
    type: LikesType;
}

export default ({loading, items, type}: Props) => {
    const classes = useStyles();
    const {push} = useHistory();

    const goTo = ({currentTarget}: MouseEvent) => push(`/${currentTarget.id}`);
    return (
        <div className={classes.root}>
            <div className={classes.wrapper}>
                {items.map(item => (
                    <Chip
                        disabled={loading}
                        key={item.type}
                        id={item.type}
                        onClick={goTo}
                        label={item.label}
                        variant="outlined"
                        className={classes.withMargin}
                        clickable
                        color={type === item.type ? "primary" : "default"}
                    />
                ))}
            </div>
        </div>
    );
};
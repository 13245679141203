export const SET_ADVERTS = "@SET_ADVERTS";

export const LOAD_MORE_ADVERTS = "@LOAD_MORE_ADVERTS";

export const SET_ADVERTS_FILTER = "@SET_ADVERTS_FILTER";

export const SET_AUTH_STATE = "@SET_AUTH_STATE";

export const SET_PROFILE = "@SET_PROFILE";
export const SET_PROFILE_STATE = "@SET_PROFILE_STATE";

export const TOGGLE_FAVORITE_STATE = "@TOGGLE_FAVORITE_STATE";

export const SET_FILTER_DATA = "@SET_FILTER_DATA";
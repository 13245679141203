import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import Html from "component/Html";
import useToggle from "lib/useToggle";
import React from "react";
import {Trans} from "react-i18next";
import Message from "types/Message";

const useStyles = makeStyles(({spacing, palette}: Theme) => createStyles({
    root: {
        marginTop: spacing(2),
        border: `1px solid ${palette.divider}`
    },
    content: {},
    active: {
        borderColor: "#198efd4d",
        background: "#198efd1a"
    }
}));

type Props = {
    message: Message;
    onClick: () => void;
}

export default ({message, onClick}: Props) => {
    const {message: {contents, title, read, send_date}} = message;

    const [active, toggleActive] = useToggle(!read);
    const classes = useStyles();

    const onOpen = () => {
        toggleActive();
        onClick();
    };

    return (
        <Card className={clsx(classes.root, {[classes.active]: active})} onClick={onOpen}>
            <CardActionArea>
                <CardContent className={classes.content}>
                    <Typography variant="h3" color="textSecondary" gutterBottom>{title}</Typography>
                    <Typography variant="body2" color="textSecondary" gutterBottom>(Ontvangen: {send_date})</Typography>
                    <Html contents={contents} />
                    {   (message.message.type === "Like" || message.message.type === "LikeBack") &&
                        <Typography variant="h4" color="primary" gutterBottom>
                            <Trans>Ik wil ruilen</Trans>
                        </Typography>
                    }
                </CardContent>
            </CardActionArea>
        </Card>
    );
}
import Box from "@material-ui/core/Box";
import Fab from "@material-ui/core/Fab";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import AdvertLikeForm from "component/form/AdvertLike";
import ThumbUpActive from "component/icon/ThumbUpActive";
import ThumbUpInactive from "component/icon/ThumbUpInactive";
import Modal from "component/Modal";
import { Endpoint } from "lib/api";
import { useApi } from "lib/useApi";
import useToggle from "lib/useToggle";
import React from "react";
import { Trans } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getProfile } from "redux/selector";
import { LikesType } from "types/LikesType";

type Props = {
  id: string;
  isLiked: 0 | 1;
  isLikedMe: 0 | 1;

  className?: string;
  activeClassName?: string;
};

type FormValues = {
  message: string;
  advert: string;
};

export default ({
  id,
  isLiked,
  isLikedMe,
  className,
  activeClassName,
}: Props) => {
  const profile = useSelector(getProfile);
  const { push } = useHistory();
  const [state, toggle] = useToggle(Boolean(isLiked));
  const [{ loading }, sendRequest] = useApi<string>(Endpoint.like, {
    params: { type: LikesType.like },
    initialData: "",
  });

  if (state) {
    const onUnlike = async () => {
      const resp = await sendRequest({
        method: "DELETE",
        data: { advert: id },
      });

      if (resp === "ok") {
        toggle();
      }
    };

    return (
      <Fab
        disabled={loading}
        className={activeClassName} /*onClick={onUnlike}*/
      >
        <ThumbUpActive />
      </Fab>
    );
  }

  const initialValues: FormValues = {
    message:
      "Beste," +
      "\nIk ben geïnteresseerd in jouw woning en ben benieuwd wat je van mijn woning vind. Als je ook interesse hebt in mijn woning, klik dan op ‘ik heb interesse’. " +
      "\nMet vriendelijke groet," +
      "\n" +
      profile?.person.firstname,
    advert: id,
  };

  const onSubmit = async (values: FormValues) => {
    const resp = await sendRequest({
      method: "POST",
      data: values,
    });

    if (resp === "ok") {
      toggle();

      if (isLikedMe) {
        push(`/start-match/${values.advert}`);
      }
    }
  };

  return (
    <Modal
      button={(open) => (
        <Fab disabled={loading} onClick={open} className={className}>
          <ThumbUpInactive />
        </Fab>
      )}
      children={(close) => (
        <Box textAlign="center" pt={2}>
          <Typography variant="h1">
            <Trans>Deze woning liken</Trans>
          </Typography>

          <Box pt={3} pb={3}>
            <Typography variant="body1">
              <Trans>
                Er wordt een bericht verstuurd naar de huurder van deze woning.
              </Trans>
            </Typography>
          </Box>

          <AdvertLikeForm onSubmit={onSubmit} initialValues={initialValues} />

          <Link color="primary" onClick={close} href="#">
            <b>
              <Trans>Annuleer mijn like</Trans>
            </b>
          </Link>
        </Box>
      )}
    />
  );
};

import React from "react";

export default () =>
    (
        <>
            <h1>Handige tips?</h1>
            <p>
                In het onderstaande overzicht vind je allerlei handige tips voor het gebruik van ruilmijnwoning.nl. De
                handige tips worden continu verbeterd. Weet je het even niet, kijk dan bij handige tips om te lezen wat
                je kunt doen.
            </p>
            <h3>goed idee!</h3>
            <p>
                Heb je een goed idee? Of mis je een handige functie op ruilmijnwoning.nl? Stuur een mailtje naar&nbsp;
                <a href="mailto:helpdesk@ruilmijnwoning.nl">helpdesk@ruilmijnwoning.nl</a> met jouw idee. Het team van
                ruilmijnwoning.nl gaat er naar kijken.
            </p>
            <h3>favorieten</h3>
            <p>
                Zet een woning in je favorieten. Dan heb je jouw persoonlijke overzicht altijd snel bij de hand.
            </p>
            <h3>zoekprofiel</h3>
            <p>
                Maak je zoekprofiel zo compleet mogelijk. Het zoekprofiel helpt je bij het sorteren van advertenties.
                Ook de informatiebanner wordt hierdoor optimaal gebruikt.
            </p>
            <h3>filters</h3>
            <p>
                Als je op ruilmijnwoning.nl bezig bent wordt je ingestelde filter bewaard. Als je later terugkomt, is
                het filter weer leeg.
            </p>
            <h3>zoekprofiel bewaard</h3>
            <p>
                Jouw zoekprofiel wordt altijd bewaard.
            </p>
            <h3>Informatiebanner</h3>
            <p>
                De informatiebanner geeft jou een goed inzicht in de overeenkomst van jouw en andere advertentie. Bij
                jouw woonwensen zie je hoe de andere woning aansluit bij jouw woonwensen. Bij woonwensen van de aanbieder
                staat hoe jouw woning aansluit bij de woonwensen van de aanbieder van de advertentie waar jij naar kijkt.
            </p>
            <h3>kleuren informatiebanner</h3>
            <p>
                In de informatiebanner vind je verschillende kleuren huisjes. Donkergroen betekent dat alle woonwensen
                overeenkomen. Lichtgroen betekent dat 3 van de 4 woonwensen overeenkomen. Geel betekent 2 van de 4
                woonwensen komen overeen. En bij oranje komt slechts 1 van de 4 woonwensen overeen. Rood betekent geen
                overeenkomst met woonwensen.
            </p>
            <h3>foto&rsquo;s</h3>
            <p>
                Je wordt zo goed mogelijk geholpen om goede foto&rsquo;s te maken voor de advertentie. Doe hiervoor je
                best, zodat je mooie foto&rsquo;s maakt. Mooie foto&rsquo;s laten jouw advertentie opvallen.
            </p>
            <h3>persoonlijke spullen</h3>
            <p>
                Let op dat je persoonlijke spullen, zoals een foto van iemand, niet op de foto&rsquo;s voor je
                advertentie zet. Haal deze spullen weg, voordat je foto maakt.
            </p>
        </>
    )
import Box from "@material-ui/core/Box";
import React, { useEffect } from "react";
import FaqQuestion from "../component/FaqQuestion";
import SearchIcon from '@material-ui/icons/Search';
import Typography from '@material-ui/core/Typography';

import { useApi } from "lib/useApi";
import { makeStyles } from '@material-ui/core/styles';
import { Endpoint } from "../lib/api";
import Question from "../types/Question";
import Icon from '@material-ui/core/Icon';
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from '@material-ui/core/InputAdornment';

const useStyles = makeStyles((theme) => ({

    search: {
        borderRadius: '25PX !important'
    },


}));

export default () => {
    const [{ data }, load] = useApi<Question[]>(Endpoint.faq, {
        initialData: []
    });
    const classes = useStyles();


    const search = (event: any) => {
        if (event.target.value.length > 2 || event.target.value.length === 0) {
            const query = new URLSearchParams();
            query.append("keyword", String(event.target.value));
            load({
                query
            })
        }
        return false;
    }

    useEffect(() => {
        load({
            params: {}
        });
    }, [load]);

    return (
        <>
            <Box my={2}>
                <Typography align="center" variant="h2" color="inherit">
                    Stel hier uw vraag
                </Typography>
            </Box>
            <Box>
                <OutlinedInput
                    fullWidth
                    className={classes.search}
                    margin="dense"
                    placeholder="Ik heb een vraag over"
                    onChange={search}
                    endAdornment={
                        <InputAdornment position="end">
                            <Icon >
                                <SearchIcon />
                            </Icon>
                        </InputAdornment>
                    }
                    labelWidth={70}
                />
                <Box marginTop={5} >
                    {
                        data.map((item) =>
                            <FaqQuestion
                                key={item.id}
                                question={item.question}
                                answer={item.answer}
                                icon={item?.icon}
                                categories={item?.categories}
                            />
                        )
                    }
                </Box>

            </Box>
        </>
    );
};

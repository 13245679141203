import {Box} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";
import {Trans} from "react-i18next";
import FullAdvert from "types/FullAdvert";
import {Alert} from "@material-ui/lab";

type Props = {
    advert: FullAdvert
}

const useStyles = makeStyles({
    frame: {
        width: "100%",
        paddingBottom: "56.2%",
        position: "relative",

        "& iframe": {
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            border: "none"
        }

    }
});

export default ({advert: {pubdate, address: {lat, lon}}}: Props) => {
    const classes = useStyles();

    const query = new URLSearchParams();

    query.set("q", `${lat}, ${lon}`);
    query.set("z", "19");
    query.set("output", "embed");
    return (
        <Box pt={5}>
            <Typography variant="h2" paragraph>
                <Trans>Locatie</Trans>
            </Typography>

            {lat !== 0 && (
                <div className={classes.frame}>
                    <iframe
                        title={pubdate}
                        src={`https://maps.google.com/maps?${query.toString()}`}
                    />
                </div>
            )}
            {lat === 0 && (
                <Alert severity="warning">
                    <Trans>Er is helaas geen kaart beschikbaar voor deze woning.</Trans>
                </Alert>
            )}
        </Box>
    );
}
import AdvertDetails from "component/AdvertDetails";
import Loader from "component/Loader";
import { Endpoint } from "lib/api";
import { useApi } from "lib/useApi";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import FullAdvert from "types/FullAdvert";

type Params = {
  id: string;
};

export default () => {
  const { id } = useParams<Params>();

  const [{ loading, data }, load] = useApi<FullAdvert | null>(
    Endpoint.fullAdvert,
    {
      initialData: null,
    }
  );

  useEffect(() => {
    load({
      params: { id },
    });
  }, [id, load]);

  if (loading || !data) {
    return <Loader />;
  }

  console.log("data", data);

  if (data?.id) {
    return <AdvertDetails advert={data} id={id} />;
  } else {
    return (
      <h2 className="text-center text-lg mt-4">
        Oops! Sorry, de advertentie bestaat niet meer
      </h2>
    );
  }
};

import Grid from "@material-ui/core/Grid";
import AdvertCard from "component/AdvertCard";
import React from "react";
import Advert from "types/Advert";

type Props = {
    adverts: Advert[];
}

export default ({adverts}: Props) => (
    <Grid container spacing={4}>
        {adverts.map((advert, index) => (
            <Grid item xs={12} sm={6} key={`${advert.id}-${index}`}>
                <AdvertCard advert={advert} />
            </Grid>
        ))}
    </Grid>
);
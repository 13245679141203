import Box from "@material-ui/core/Box";
import React from "react";
import ChangeBox from "../component/ChangeBox";

export default () => {
    return (
        <Box pt={2}>
            <ChangeBox
                title="Wachtwoord aanpassen"
                description="Kies hieronder een nieuw wachtwoord."
            />
        </Box>
    );
};

import endpoints from "data/api-endpoints.json";
import {getAuthToken, isLoggedIn} from "lib/auth";
import throwError from "lib/throwError";
import {generatePath} from "react-router-dom";

export enum Endpoint {
  fullAdvert = "fullAdvert",
  advert = "advert",
  filter = "filter",
  corporation = "corporation",
  location = "location",
  check = "check",
  oAuthUrl = "oAuthUrl",
  oAuthCallback = "oAuthCallback",
  oAuthCallbackExternal = "oAuthCallbackExternal",
  authRefresh = "authRefresh",
  profile = "profile",
  profileState = "profileState",
  myAdvert = "myAdvert",
  like = "like",
  messages = "messages",
  uploadPhoto = "uploadPhoto",
  deletePhoto = "deletePhoto",
  exchange = "exchange",
  trade = "trade",
  updateTradeTask = "updateTradeTask",
  lockTradeBlock = "lockTradeBlock",
  uploadTradeDocument = "uploadTradeDocument",
  deleteTradeDocument = "deleteTradeDocument",
  getTradeDocument = "getTradeDocument",
  register = "register",
  forgotpw = "forgotpw",
  changepw = "changepw",
  login = "login",
  faq = "faq",
  support = "support",
}

export type RequestParams = RequestInit & {
  data?: object;
  query?: URLSearchParams;
  params?: { [ paramName: string ]: string | number | boolean | undefined };
  useAuth?: boolean;
}

export const makeRequest = async <R>( input: Endpoint, init: RequestParams = {} ): Promise<R> => {
  const {query = new URLSearchParams(), useAuth = true, data, params, ...request} = init;

  const {method, endpoint} = endpoints[ input ];

  const path = generatePath( endpoint, params );

  const headers: HeadersInit = {
    "Accept": "application/json",
  };

  if ( !(request.body instanceof FormData) ) {
    headers[ "Content-Type" ] = "application/json";
  }

  if ( useAuth && await isLoggedIn() ) {
    headers.Authorization = `Bearer ${getAuthToken()}`;
  }

  if ( !request.method ) {
    request.method = method;
  }
  request.headers = Object.assign( headers, request.headers );

  if ( data && !request.body ) {
    request.body = JSON.stringify( data );
  }

  request.credentials = 'include';

  const resp = await fetch( `${process.env.REACT_APP_API_BASE}?endpoint=${path}&${query.toString()}`, request );
  if ( resp.ok ) {
    return resp.json();
  }

  return throwError( await resp.json() );
};

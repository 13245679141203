import InputLabel from "@material-ui/core/InputLabel";
import Typography from "@material-ui/core/Typography";
import EnergyLabel from "component/form/field/EnergyLabel";
import FilterExtras from "component/form/field/FilterExtras";
import Input from "component/form/field/Input";
import MinMax from "component/form/field/MinMax";
import Select from "component/form/field/Select";
import Loader from "component/Loader";
import React from "react";
import {Trans} from "react-i18next";
import {useSelector} from "react-redux";
import {getFilterData} from "redux/selector";
import {formatFloat} from 'lib/format';
import {Alert} from "@material-ui/lab";

export default () => {

    const data = useSelector(getFilterData);

    if (!data) {
        return <Loader/>;
    }


    // I've spent several hours trying to get fromArchive down to this element. I couldn't. I did this instead.
    // If you can do it better, please do. - NC

    const {extras, types, rooms} = data;
    return (
        <>
            <Typography variant="h1" align="center" paragraph>
                <Trans>Woningkenmerken</Trans>
            </Typography>

            {
                window.fromArchive &&
                <Alert severity="info">
                  <Trans>
                    Een aantal gegevens van uw huurwoning zijn al ingevuld. Ze zijn aangeleverd door uw verhuurder en kunnen niet gewijzigd worden. Dit geldt bijvoorbeeld ook voor de huur.
                  </Trans>
                </Alert>

            }

            <InputLabel><Trans>Type woning</Trans></InputLabel>
            <Select
                name="type"
                options={types}
                placeholder="Selecteer type woning"
                readOnly={window.fromArchive}
            />

            <InputLabel><Trans>Woningoppervlakte</Trans></InputLabel>
            <Input
                name="surface"
                placeholder="in m2"
                fieldProps={{
                    format: formatFloat
                }}
                readOnly={window.fromArchive}
            />

            <InputLabel><Trans>Bouwjaar</Trans></InputLabel>
            <Input
                name="buildyear"
                readOnly={window.fromArchive}
            />

            <InputLabel><Trans>Energielabel</Trans></InputLabel>
            <EnergyLabel
                name="energylabel"
                placeholder="Selecteer energielabel"
                readOnly={window.fromArchive}
            />

            <InputLabel><Trans>Aantal kamers</Trans></InputLabel>
            <MinMax
                name="rooms"
                formatName={item => `${item}${item === rooms.max ? "+" : ""}`}
                min={rooms.min}
                max={rooms.max}
                readOnly={window.fromArchive}
            />

            <FilterExtras
                name="extras"
                extras={extras}
            />

            <InputLabel><Trans>Streefhuurprijs per maand</Trans></InputLabel>
            <Input
                name="rent.basiccosts"
                placeholder="Uw huur zonder servicekosten"
                fieldProps={{
                    format: formatFloat
                }}
                readOnly={window.fromArchive}
                helperText={window.fromArchive ? `De verhuurder bepaalt jaarlijks de streefhuur van uw woning. Het kan zijn dat u nu een lagere huur betaalt, maar de verhuurder heeft het recht om, bij een nieuw huurcontract, een hogere huur te vragen. Dat is ook bij \'gewone\' verhuizingen heel gebruikelijk. U kunt deze huur niet aanpassen` : `De verhuurder bepaalt jaarlijks de streefhuur van uw woning. Het kan zijn dat u nu een lagere huur betaalt, maar de verhuurder heeft het recht om, bij een nieuw huurcontract, een hogere huur te vragen. Dat is ook bij 'gewone' verhuizingen heel gebruikelijk. Vraag deze streefhuur op bij uw verhuurder om straks niet voor verrassingen te komen staan.`}
            />

            <InputLabel><Trans>Servicekosten</Trans></InputLabel>
            <Input
                name="rent.servicecosts"
                placeholder="Uw servicekosten"
                fieldProps={{
                    format: formatFloat
                }}
                readOnly={window.fromArchive}
            />
        </>
    );
};

import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import CameraTopLeft from "assets/camera-top-left.png";
import CameraTopRight from "assets/camera-top-right.png";
import CameraTop from "assets/camera-top.png";
import PlusIcon from "component/icon/Plus";
import {Endpoint} from "lib/api";
import {useApi} from "lib/useApi";
import React, {ChangeEvent, MouseEvent, useCallback, useState} from "react";
import {useField} from "react-final-form";
import MyAdvertPhoto from "types/MyAdvertPhoto";
import Delete from "component/icon/Delete";
import {useSelector} from "react-redux";
import {getProfileState} from "../../../redux/selector";
import Fab from "@material-ui/core/Fab";

const useStyles = makeStyles(({palette, spacing, shape}: Theme) => createStyles({
    root: {
        display: "grid",
        gridTemplateColumns: `repeat(auto-fit, ${spacing(17.5)}px)`,
        gridAutoRows: "auto",
        gridColumnGap: spacing(),
        gridRowGap: spacing(),
        justifyContent: "center",
        "& > *": {
            height: spacing(12),
            backgroundSize: "contain",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundClip: "content-box",
            border: `1px dashed ${palette.divider}`,
            color: palette.divider,
            borderRadius: shape.borderRadius,
            position: 'relative'
        },
        "&:not(:first-child)": {
            marginTop: spacing(2),
        },
    },
    remove: {
        position: 'absolute',
        right: 8,
        bottom: 8,
        border: 'none',
        width: 20,
        height: 20,
        fontSize: 10,
        minHeight: 20,
        lineHeight: 20
    },
    input: {
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: 12,
        "& input": {
            position: "absolute",
            width: "100%",
            height: "100%",
            opacity: 0,
            fontSize: 0,
            cursor: "pointer",
        },
        "&:before": {
            content: "''",
            position: "absolute",
            backgroundRepeat: "no-repeat",
            border: "0px solid #b0b0b0",
            backgroundSize: 20,
            bottom: spacing(2),
            left: spacing(2),
            right: spacing(2),
            top: spacing(2),
        },
        "&:nth-child(1):before": {
            borderWidth: "1.5px 1.5px 0 0",
            backgroundImage: `url(${CameraTopRight})`,
            backgroundPosition: "0 100%",
        },
        "&:nth-child(2):before": {
            borderWidth: "1.5px 0 0 0",
            backgroundImage: `url(${CameraTop})`,
            backgroundPosition: "center 100%",
            backgroundSize: 15,
        },
        "&:nth-child(3):before": {
            borderWidth: "1.5px 0 0 1.5px",
            backgroundImage: `url(${CameraTopLeft})`,
            backgroundPosition: "100% 100%",
        },
    },
}));

type Props = {
    name: string;
    part: string;
    advert: string;
}

export default ({name, part, advert}: Props) => {
    const state = useSelector(getProfileState);

    const [error, setError] = useState<string>("");
    const {input: {value, onChange}} = useField<MyAdvertPhoto[]>(name);
    const [, remove] = useApi(Endpoint.deletePhoto, {
        initialData: null,
    })
    const [{loading}, upload] = useApi<MyAdvertPhoto | null>(Endpoint.uploadPhoto, {
        initialData: null,
    });

    const classes = useStyles();

    const onRemove = useCallback(async ({currentTarget: {id}}: MouseEvent) => {
        await remove({params: {id}});
        onChange(value.filter((photo) => photo.id !== id));
    }, [onChange, value, remove]);

    const onAdd = useCallback(async ({currentTarget: {files}}: ChangeEvent<HTMLInputElement>) => {
        if (files && files[0]) {
            const body = new FormData();

            body.append("payload", files[0]);
            body.append("advert", advert);
            body.append("part", part);

            try {
                const photo = await upload({
                    body,
                });

                onChange([...value, photo]);
            } catch (e) {
                if (e.error) {
                    setError(e.error);
                }
            }
        }
    }, [value, onChange, upload, advert, part]);

    return (
        <FormControl fullWidth margin="dense" size="small">
            {!!error && (
                <Alert severity="error">
                    {error}
                </Alert>
            )}

            <div className={classes.root}>
                {value.map((photo) => (
                    <div key={photo.id} style={{backgroundImage: `url(${photo.url})`}}>
                        {!state?.inTrade && value.length > 1 && (
                            <Fab
                                id={photo.id}
                                size="small"
                                color="secondary"
                                className={classes.remove}
                                onClick={onRemove}
                            >
                                <Delete fontSize="inherit"/>
                            </Fab>
                        )}
                    </div>
                ))}
                <div className={classes.input}>
                    <input
                        type="file"
                        accept="image/*"
                        disabled={loading}
                        onChange={onAdd}
                    />

                    {
                        loading
                            ? <CircularProgress size={30}/>
                            : <PlusIcon fontSize="inherit"/>
                    }
                </div>
            </div>
        </FormControl>
    );
}

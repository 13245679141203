import Advert from "types/Advert";
import FilterForm from "types/FilterForm";
import ReduxState from "types/ReduxState";

export const isAdvertsLoading = (state: ReduxState): boolean => state.adverts.loading;
export const getAdverts = (state: ReduxState): Advert[] => state.adverts.list;
export const getAdvertsState = (state: ReduxState): ReduxState["adverts"] => state.adverts;
export const getAdvertsCount = (state: ReduxState): number => state.adverts.count;
export const getAdvertsFilter = (state: ReduxState): FilterForm | undefined => state.adverts.filters;

export const getLoginState = (state: ReduxState): ReduxState["loginState"] => state.loginState;

export const getFilterData = (state: ReduxState): ReduxState["filterData"] => state.filterData;

export const getProfile = (state: ReduxState): ReduxState["profile"] => state.profile;
export const getProfileState = (state: ReduxState): ReduxState["profileState"] => state.profileState;
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import LinearProgress from "@material-ui/core/LinearProgress";
import Snackbar from "@material-ui/core/Snackbar";
import Typography from "@material-ui/core/Typography";
import HomeIcon from "component/icon/Home";
import PhoneIcon from "component/icon/Phone";
import Loader from "component/Loader";
import SmallDesktopContainer from "component/SmallDesktopContainer";
import TradeBlock from "component/TradeBlock";
import TradeDeleteBlock from "component/TradeDeleteBlock";
import { Endpoint } from "lib/api";
import { useApi } from "lib/useApi";
import React from "react";
import { Trans } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import TradeResponse from "types/TradeResponse";
import { Question } from "types/TradeResponse";
import TradeTaskState from "types/TradeTaskState";
import FittingWarning from "../component/FittingWarning";
import HouseholdWarning from "../component/HouseholdWarning";
import AgeWarning from "../component/AgeWarning";
import { Alert } from "@material-ui/lab";

export default () => {
  const [{ data, loading, error }] = useApi<TradeResponse | null>(
    Endpoint.trade,
    {
      autoTrigger: true,
      initialData: null,
    }
  );

  const [showUploadHelpText, setShowUploadHelpText] =
    React.useState<boolean>(false);

  const { advert, blocks = [] } = data || {};

  const [checked, setChecked] = React.useState<TradeTaskState>();
  React.useEffect(() => {
    if (blocks.length && !checked) {
      const state: TradeTaskState = {};

      blocks.forEach((block) => {
        block.questions.forEach((question) => {
          state[question.id] = !!question.answer.answered;
        });
      });

      setChecked(state);
    }
  }, [blocks, checked]);

  const [first, second, third] = React.useMemo(
    () => blocks.sort((a, b) => a.order - b.order),
    [blocks]
  );

  const counter = React.useMemo(() => {
    const states = Object.values(checked || {});
    return {
      done: states.filter((state) => state).length,
      total: states.length,
    };
  }, [checked]);

  const questionBlockLength = (q: Question[]): number => {
    let temp: Array<string> = [];

    q.forEach((i: Question) => {
      if (temp.findIndex((j) => j === i.title) === -1) {
        temp.push(i.title);
      }
    });
    return temp.length;
  };

  return (
    <SmallDesktopContainer>
      {error && error.error !== "No active trade!" && (
        <Snackbar open message={error.error} />
      )}
      {loading && <Loader />}

      {error && error.error === "No active trade!" && (
        <Box textAlign="center">
          <Typography variant="h1" component={Box} pt={3} pb={3}>
            <Trans>Tijd voor een nieuwe kans!</Trans>
          </Typography>
          <Typography component={Box} pb={3}>
            <Trans>
              U heeft op dit moment geen actieve woningruil. Via de{" "}
              <a href="/">zoekpagina</a> kunt u op zoek naar een woning die aan
              uw eisen voldoet. Als u deze woning interresant vindt en dit met
              het knopje boven aan geeft, krijgt de huidige huurder van die
              woning direct een bericht. Mocht deze huurder uw woning ook
              interessant vinden, dan is het tijd om eens te gaan praten over de
              ruilkansen!
            </Trans>
          </Typography>
        </Box>
      )}

      {advert && (
        <Box textAlign="center">
          <Typography variant="h1" component={Box} pt={3} pb={3}>
            <Trans>Mijn woningruil</Trans>
          </Typography>
          {advert.corporation.payment.required && (
            <Box mb={1}>
              <Alert severity="info">
                <Trans>
                  <strong>Ter informatie:</strong> Deze verhuurder brengt
                  verwerkingskosten in rekening.
                  <br />
                  <br />
                  {advert.corporation.name} brengt &euro;{" "}
                  {advert.corporation.payment.amount || "0.00"}{" "}
                  verwerkingskosten in rekening bij de nieuwe huurder.
                  <br />
                  Dit bedrag betaal je pas via Ruilmijnwoning.nl als jouw ruil
                  door jouw eigen verhuurder én jouw nieuwe verhuurder is
                  goedgekeurd. Je krijgt hiervoor dan een e-mail met een
                  betaalverzoek.
                </Trans>
              </Alert>
            </Box>
          )}
          <Typography component={Box} pb={3}>
            <Trans>
              De ruil kan tot stand worden gebracht. Hier moeten wel nog even
              een aantal zaken voor worden geregeld. Namelijk elkaars woning
              bezoeken en afspraken maken. En daarna hieronder de vragen
              beantwoorden en documenten uploaden.
            </Trans>
          </Typography>
          {advert.isFitting === 0 && (
            <FittingWarning isFitting={advert.isFitting} />
          )}
          {advert.householdsize === 0 && (
            <HouseholdWarning householdsize={advert.householdsize} />
          )}
          {advert.age === 0 && <AgeWarning age={advert.age} />}
          <Box pb={2}>
            <Typography variant="h3" color="textSecondary">
              <Trans
                values={counter}
                children="{{done}}/{{total}} taken voltooid"
              />
            </Typography>
            <Typography color="textSecondary" paragraph>
              <Trans>De ruil kan bijna beginnen!</Trans>
            </Typography>
            <LinearProgress
              variant="determinate"
              color="primary"
              value={(100 / counter.total) * counter.done}
            />
          </Box>

          <Chip
            variant="outlined"
            icon={<HomeIcon style={{ color: "#198efd" }} fontSize="small" />}
            label={<Trans>Bekijk de woning</Trans>}
            component={RouterLink}
            to={`/view/${advert.id}`}
          />
        </Box>
      )}

      {first && (
        <TradeBlock
          {...first}
          checkedState={checked || {}}
          setCheckedState={setChecked}
          lockTitle={
            first.locked
              ? "Bedankt, je hebt dit al gedaan"
              : "Ik wil definitief ruilen"
          }
          startIndex={1}
          children={(questions, lockBtn) => (
            <>
              <Typography variant="h2" gutterBottom>
                Afspreken met de andere huurder
              </Typography>
              <Typography paragraph>
                Voorkom verwarring en maak afspraken met elkaar
              </Typography>

              <Chip
                variant="outlined"
                icon={
                  <PhoneIcon style={{ color: "#198efd" }} fontSize="small" />
                }
                label={advert?.person.phone}
                component="a"
                href={`tel:${advert?.person.phone}`}
              />

              <Box pt={2}>{questions}</Box>

              {lockBtn}

              {!first.doneForPartner && (
                <Box component={Typography} mt={1}>
                  De andere huurder heeft nog niet gereageerd
                </Box>
              )}
            </>
          )}
        />
      )}

      {second && (
        <TradeBlock
          {...second}
          checkedState={checked || {}}
          setCheckedState={setChecked}
          lockTitle={
            second.locked
              ? "Bedankt, je hebt dit al gedaan"
              : "Dien aanvraag in"
          }
          startIndex={questionBlockLength(first.questions) + 1}
          children={(questions, lockBtn) => (
            <>
              <Typography variant="h2">Dit moet je aanleveren</Typography>

              <Typography
                variant="body1"
                style={{ color: "gray" }}
                gutterBottom
              >
                Vereist document <span style={{ color: "red" }}>*</span>
              </Typography>

              <Box
                pb={1}
                onClick={() => setShowUploadHelpText(!showUploadHelpText)}
              >
                <Typography>
                  Om jouw aanvraag goed te kunnen beoordelen moet je documenten
                  meesturen. Klik hierna op “Meer informatie” en lees deze
                  toelichting helemaal door. Hoe beter je alle documenten
                  compleet geüpload hebt, hoe sneller de aanvraag door de
                  corporatie in behandeling genomen wordt.
                </Typography>
                <Typography style={{ color: "#198efd" }} gutterBottom>
                  {showUploadHelpText
                    ? "Minder informatie?"
                    : "Meer informatie?"}
                </Typography>

                {showUploadHelpText && (
                  <Typography>
                    De aanvraag wordt ingediend bij de corporatie waar je naar
                    toe gaat verhuizen. Om jouw aanvraag goed te kunnen
                    beoordelen moet je documenten meesturen. Hieronder zie je
                    welke documenten verplicht zijn om te uploaden. Daar staat
                    een * achter. Van sommige documenten moet je er meerdere
                    uploaden. Dat staat in de toelichting bij ieder document.
                    <br />
                    <br />
                    Als je jouw telefoon gebruikt om de aanvraag in te dienen
                    kun je ook een foto maken van het document. Via de PC kun je
                    een bestand van het type .pdf, .png of .jpg uploaden.
                    <br />
                    <br />
                    Pas als alle verplichte documenten zijn geüpload en
                    goedgekeurd wordt de aanvraag in behandeling genomen. Als je
                    klikt op een type document, opent er een scherm waar je
                    documenten:
                    <br />
                    - kunt toevoegen (klik op het icoon met de +)
                    <br />
                    - kunt verwijderen (klik op het icoon met de prullenbak) of
                    <br />
                    - kunt bekijken (klik op het icoon met het oogje).
                    <br />
                    Let op: alleen bij het uploaden kun je zelf ook een
                    opmerking of toelichting bij het document plaatsen. Wij
                    adviseren om uw burgerservicenummer (BSN) af te dekken en
                    onzichtbaar te maken in uw documenten. Neem even de tijd om
                    het uploaden van documenten in orde te maken. Het is een
                    heel belangrijkonderdeel om een kansrijke aanvraag in te
                    dienen!
                  </Typography>
                )}
              </Box>

              {questions}

              {lockBtn}

              {!first.doneForPartner && (
                <Box component={Typography} mt={1}>
                  De andere huurder heeft nog niet gereageerd
                </Box>
              )}
            </>
          )}
        />
      )}

      {third && (
        <TradeBlock
          {...third}
          checkedState={checked || {}}
          setCheckedState={setChecked}
          startIndex={
            questionBlockLength(first.questions) +
            questionBlockLength(second.questions) +
            1
          }
          children={(questions) => (
            <>
              <Typography variant="h2" gutterBottom>
                Dit regelt de corporatie
              </Typography>
              <Typography paragraph>
                Hier hoef jij niets voor te doen
              </Typography>

              {questions}
            </>
          )}
        />
      )}

      {advert && <TradeDeleteBlock id={advert.id} />}
    </SmallDesktopContainer>
  );
};

import CircularProgress from "@material-ui/core/CircularProgress";
import Fab from "@material-ui/core/Fab";
import Link from "@material-ui/core/Link";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import AdvertDetails from "component/AdvertDetails";
import Loader from "component/Loader";
import {Endpoint} from "lib/api";
import {useApi} from "lib/useApi";
import React from "react";
import {Trans} from "react-i18next";
import {useDispatch} from "react-redux";
import {Link as RouterLink, useHistory, useParams} from "react-router-dom";
import {setAuthState} from "redux/action";
import FullAdvert from "types/FullAdvert";

type Params = {
    id: string;
}

const useStyles = makeStyles(({spacing, palette}: Theme) => createStyles({
    submitBar: {
        borderTop: "1px solid",
        borderColor: palette.divider,
        padding: spacing(2),
        background: palette.common.white,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0
    }
}));

export default () => {
    const {push} = useHistory();
    const classes = useStyles();
    const {id} = useParams<Params>();
    const dispatch = useDispatch();

    const [{data}] = useApi<FullAdvert | null>(Endpoint.fullAdvert, {
        autoTrigger: true,
        initialData: null,
        params: {id}
    });

    const [{loading}, publishAdvert] = useApi<null>(Endpoint.myAdvert, {
        initialData: null
    });

    if (!data) {
        return <Loader />;
    }

    const onSubmit = async () => {
        await publishAdvert({
            method: "PUT",
            data: {
                advert: id
            }
        });

        dispatch(setAuthState(true));
        push("/");
    };

    return (
        <>
            <AdvertDetails
                isPreview
                advert={data}
                id={id}
            />

            <div className={classes.submitBar}>
                <Link component={RouterLink} to="/my-advert">
                    <b><Trans>Vorige stap</Trans></b>
                </Link>

                <Fab color="primary"
                     variant="extended"
                     size="medium"
                     type="button"
                     disabled={loading}
                     onClick={onSubmit}
                     style={{width: "auto"}}>
                    {!loading ? <Trans>Plaatsen</Trans> : (
                        <CircularProgress size={12} color="inherit" />
                    )}
                </Fab>
            </div>
        </>
    );
};
import React from "react";

export default () => (
    <>
        <h1>Woningruil regelen</h1>
        <p>
            Op het moment dat de aanbieder van een woning en jij allebei interesse in elkaars woning hebben, kun je
            kiezen om een ‘match’ tot stand te brengen. Hierbij hebben jullie beiden de echte intentie om te met elkaar
            te gaan ruilen van woning. Ben je hier zeker van? Druk dan op knop ‘Ik wil ruilen’.
        </p>
        <p>
            Je komt bij Mijn Woningruil. Bij Mijn Woningruil regel je alle belangrijke zaken om de woningruil te gaan
            regelen. Wat moet je regelen?
        </p>
        <ol type="1">
            <li>Maak een afspraak om elkaars woning te bekijken.</li>
            <li>Bekijk de woning van elkaar.</li>
            <li>Zijn jullie allebei nog enthousiast en hebben jullie afgesproken om de woning met elkaar te ruilen?
                Regel de juiste documenten voor de aanvraag (bijvoorbeeld de verhuurderverklaring, een uittreksel
                gemeentelijke basisregistratie en inkomensverklaring).
            </li>
        </ol>
        <p>
            Dien vervolgens de aanvraag bij ons in. Dit doe je via de knop ‘Dien mijn aanvraag’ in. De
            aanvraag wordt door ons automatisch ingediend bij de juiste corporatie. Hiervoor hoef je niets te doen.
        </p>
        <p>
            De corporatie beoordeelt jouw aanvraag en geeft wel of geen goedkeuring. Wanneer er geen goedkeuring wordt
            gegeven, ontvang je hiervan bericht en wordt de woningruil automatisch afgebroken. Wel goedkeuring? De
            corporatie regelt met jou een nieuw huurcontract, waarmee de woningruil een feit wordt.
        </p>
        <p>
            <i>Let op: Je kunt de match altijd afbreken als je verwacht dat een match voor woningruil toch niet tot
                stand komt. Bij een lopende match in Mijn Woningruil kun je op dat moment geen andere woningen meer
                Liken.</i>
        </p>
    </>
)

import TextField, {TextFieldProps} from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ChevronDownIcon from "component/icon/ChevronDown";
import {Endpoint} from "lib/api";
import {useApi} from "lib/useApi";
import React, {ChangeEvent} from "react";
import {useField} from "react-final-form";
import {Trans, useTranslation} from "react-i18next";
import Corporation from "types/Corporation";

type Props = {
  name: string;
}

const renderInput = ( params: TextFieldProps ) => (
    <TextField
        {...params}
        fullWidth
        margin="dense"
        variant="outlined"
        helperText={(
            <Trans>
                Vul hier de naam van jouw verhuurder in. Als je de naam begint te typen, kun je snel zoeken in de lijst.
            </Trans>
        )}
    />
);

const getOptionLabel = ( option: Corporation ) => option.name;

const getOptionSelected = ( option: Corporation, value: Corporation ) => option.id === value.id;

export default ( {name}: Props ) => {
  const {input: {multiple, value, ...input}} = useField<Corporation>( name );
  const [{loaded, loading, data}, load] = useApi<{ corporations: Corporation[] }>( Endpoint.corporation, {
    initialData: {corporations: []},
  } );

  const {t} = useTranslation();

  const onOpen = () => !loaded && !loading && load();

  const onChange = ( event: ChangeEvent<{}>, value: Corporation ) => input.onChange( value );

  return (
      <Autocomplete
          onOpen={onOpen}

          getOptionSelected={getOptionSelected}
          getOptionLabel={getOptionLabel}

          options={data.corporations}

          loading={loading}
          loadingText={t( "Loading…" )}
          noOptionsText={t( "No corporations" )}

          renderInput={renderInput}
          popupIcon={<ChevronDownIcon />}

          disableClearable

          size="small"

          {...input}
          value={value}
          multiple={false}
          onChange={onChange}
      />
  );
}

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import Typography from "@material-ui/core/Typography";
import Corporation from "component/form/field/Corporation";
import Input from "component/form/field/Input";
import Submit from "component/form/field/Submit";
import React from "react";
import {Form, FormProps, FormRenderProps} from "react-final-form";
import {Trans} from "react-i18next";
import AvailabilityForm from "types/AvailabilityForm";

export default (props: FormProps<AvailabilityForm>) => (
    <Form {...props}>
        {({handleSubmit}: FormRenderProps) => (
            <form onSubmit={handleSubmit}>
                <Box pt={2} pb={2}>
                    <Typography variant="h1" align="center" paragraph>
                        <Trans>Doe de check of jij mag ruilen van jouw
                            woningcorporatie.</Trans>
                    </Typography>
                    <Typography>
                        <Trans>Vul jouw postcode, huisnummer en verhuurder in. Op basis van je postcode
                            en huisnummer wordt gecontroleerd of jouw verhuurder deelneemt en of jouw woning geruild mag worden.</Trans>
                        <br/>
                        <Trans><strong>Let op: vul dit zorgvuldig in, je kunt deze gegevens later niet meer wijzigen.</strong></Trans>
                    </Typography>
                </Box>
                <Box pt={2} pb={2}>
                    <Grid container spacing={1}>
                        <Grid item xs>
                            <InputLabel><Trans>Postcode</Trans></InputLabel>
                            <Input name="zipcode" />
                        </Grid>
                        <Grid item xs>
                            <InputLabel><Trans>Huisnummer</Trans></InputLabel>
                            <Input name="housenumber" />
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel><Trans>Verhuurder</Trans></InputLabel>
                            <Corporation name="corporation" />
                        </Grid>
                    </Grid>
                </Box>

                <Submit title="Controleer jouw woning" />
            </form>
        )}
    </Form>
);

import Link from "@material-ui/core/Link";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CheckIcon from "component/icon/Check";
import MinusIcon from "component/icon/Minus";
import TradeLeftIcon from "component/icon/TradeLeft";
import TradeRightIcon from "component/icon/TradeRight";
import useToggle from "lib/useToggle";
import React, {forwardRef} from "react";
import {Trans} from "react-i18next";
import FullAdvert from "types/FullAdvert";
import {useSelector} from "react-redux";
import {getLoginState} from "redux/selector";
import {Link as RouterLink} from "react-router-dom";
import Fab from "@material-ui/core/Fab";
import Box from "@material-ui/core/Box";

type Props = {
    advert: FullAdvert
}

const useStyles = makeStyles(({spacing, breakpoints, palette, shape}: Theme) => createStyles({
    root: {
        padding: spacing(2),
        marginTop: spacing(5),
        marginLeft: spacing(-2),
        marginRight: spacing(-2),
        backgroundColor: palette.primary.light,

        [breakpoints.up("sm")]: {
            padding: spacing(3),
            marginLeft: spacing(-3),
            marginRight: spacing(-3)
        },

        [breakpoints.up("md")]: {
            marginLeft: spacing(0),
            marginRight: spacing(0)
        }
    },
    panel: {
        position: "relative",
        marginTop: spacing(2),
        padding: spacing(2),
        backgroundColor: palette.common.white,
        borderRadius: shape.borderRadius,
        border: `1px solid ${palette.divider}`
    },
    panelIcon: {
        position: "absolute",
        right: spacing(2),
        top: spacing(2.5)
    }
}));

export default forwardRef<HTMLDivElement, Props>(({advert: {address, match, properties, extras, buildyear, ownerPreferences}}, ref) => {
    const loggedIn = useSelector(getLoginState);
    const [active, toggleActive] = useToggle(false);
    const classes = useStyles();

    const content = React.useMemo(() => {
        const nodes: React.ReactNodeArray = [];

        if (!loggedIn) {

            nodes.push(
                <div key="panel-1" className={classes.panel}>
                    <TradeRightIcon className={classes.panelIcon}/>
                    <Typography variant="h3" paragraph>
                        <Trans>Woonwensen van de huurder</Trans>
                    </Typography>
                    <Typography variant="body2" paragraph>
                        <Trans>Huurprijs:</Trans>&nbsp;
                        <Trans
                            values={{max: ownerPreferences.rent_max, min: ownerPreferences.rent_min}}
                            children="€ {{min}} - {{max}}"
                        />
                    </Typography>
                    <Typography variant="body2" paragraph>
                        <Trans>Plaats:</Trans>&nbsp;
                        {ownerPreferences.locations}
                    </Typography>
                    <Typography variant="body2" paragraph>
                        <Trans>Type:</Trans>&nbsp;
                        {ownerPreferences.types}
                    </Typography>
                    <Typography variant="body2" paragraph>
                        <Trans>Minimaal aantal kamers:</Trans>&nbsp;
                        {ownerPreferences.rooms_min}
                    </Typography>
                    {/*<Typography variant="body2" paragraph>
                        <Trans>Bouwjaar:</Trans>&nbsp;
                        {buildyear}
                    </Typography>
                    <Typography variant="body2">
                        <Trans>Energielabel</Trans>&nbsp;
                        {properties.energylabel.toUpperCase()}
                    </Typography>*/}
                </div>
            );

            nodes.push(
                <div key="panel-2" className={classes.panel}>
                    <TradeLeftIcon className={classes.panelIcon}/>
                    <Typography variant="h3" gutterBottom>
                        <Trans>Jij hebt nog geen woonwensen</Trans>
                    </Typography>
                    <Typography variant="body2" paragraph>
                        <Trans>Je moet ingelogd zijn om te zien of deze woning aan jouw woonwensen voldoet</Trans>
                    </Typography>

                    <Fab color="primary" variant="extended" size="medium" component={RouterLink} to="/register">
                        <Trans>Maak een account aan</Trans>
                    </Fab>

                    <Box textAlign="center" mt={2}>
                        <Link component={RouterLink} to="/login">
                            <Trans>Inloggen</Trans>
                        </Link>
                    </Box>
                </div>
            );

            return nodes;
        }

        const {
            myDemands: {count: myCount, of: myOf, indepth: myIndepth},
            theirDemands: {count: theirCount, of: theirOf, indepth: theirIndepth}
        } = match;

        nodes.push(
            <Typography key="desc" variant="body1" gutterBottom>
                <Trans values={{myCount, myOf, theirCount, theirOf}}>
                    Er komen <b>{{myCount}} van jouw {{myOf}} woonwensen</b> overeen met deze woning.
                    Bij de huidige huurder sluiten <b>{{theirCount}} van de {{theirOf}} woonwensen</b> aan bij jouw
                    woning.
                </Trans>
            </Typography>
        );

        nodes.push(
            <Typography key="toggle" component={Link} href="" onClick={toggleActive}>
                <b><Trans>{active ? "Bekijk minder woonwensen" : "Bekijk aanvullende woonwensen"}</Trans></b>
            </Typography>
        );

        if (active) {
            nodes.push(
                <div key="panel-1" className={classes.panel}>
                    <TradeRightIcon className={classes.panelIcon} value={theirCount}/>
                    <Typography variant="h3" gutterBottom>
                        <Trans>Woonwensen van de huurder</Trans>
                    </Typography>
                    <Typography variant="body2" paragraph>
                        <Trans>Jouw woning voldoet op de volgende punten aan de woonwensen van de aanbieder.</Trans>
                    </Typography>
                    <Typography variant="h4" gutterBottom>
                        <Trans values={{theirCount, theirOf}}>{{theirCount}} van {{theirOf}} woonwensen</Trans>
                    </Typography>

                    {theirIndepth.map((item) => (
                        <Typography variant="body1" key={`${item.type}-${item.name}`}>
                            {item.checked ? (<CheckIcon color="primary"/>) : (<MinusIcon/>)}&nbsp;
                            {item.name}
                        </Typography>
                    ))}
                </div>
            );

            nodes.push(
                <div key="panel-2" className={classes.panel}>
                    <TradeLeftIcon className={classes.panelIcon} value={myCount}/>
                    <Typography variant="h3" gutterBottom>
                        <Trans>Jouw woonwensen</Trans>
                    </Typography>
                    <Typography variant="body2" paragraph>
                        <Trans>De geadverteerde woning voldoet op de volgende punten aan jouw ingevulde
                            woonwensen.</Trans>
                    </Typography>
                    <Typography variant="h4" gutterBottom>
                        <Trans values={{myCount, myOf}}>{{myCount}} van {{myOf}} woonwensen</Trans>
                    </Typography>

                    {myIndepth.map((item) => (
                        <Typography variant="body1" key={`${item.type}-${item.name}`}>
                            {item.checked ? (<CheckIcon color="primary"/>) : (<MinusIcon/>)}&nbsp;
                            {item.name}
                        </Typography>
                    ))}
                </div>
            );
        }

        return nodes;
    }, [loggedIn, active, match, toggleActive, classes, ownerPreferences.locations, ownerPreferences.rent_max,
        ownerPreferences.rent_min, ownerPreferences.rooms_min, ownerPreferences.types])

    return (
        <div className={classes.root} ref={ref}>
            <Typography variant="h2" paragraph>
                <Trans>Ruilkans</Trans>
            </Typography>

            {content}
        </div>
    );
});

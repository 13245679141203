import Box from "@material-ui/core/Box";
import InputLabel from "@material-ui/core/InputLabel";
import Typography from "@material-ui/core/Typography";
import Input from "component/form/field/Input";
import React from "react";
import {Trans} from "react-i18next";

export default () => (
    <>
        <Typography variant="h1" align="center">
            <Trans>Omschrijving</Trans>
        </Typography>
        <Box pt={2} />
        <InputLabel><Trans>Geef een leuke omschrijving van je woning</Trans></InputLabel>
        <Input
            name="description"
            rows={4}
            placeholder="Een omschrijving is verplicht. Vul minimaal 20 tekens in."
            multiline
        />
    </>
);